
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T from '../T';
import glob from '../glob';
import { FlatGrid } from 'react-native-super-grid';
import { PersonnelItem } from '../Screens/PersonnelsScreen';
import { HorRadio } from './HorRadio';
import { Datepicker, Input } from '@ui-kitten/components';
import MTouchable from './MTouchable';
import MIcons from './MIcons';
import tkAPICalls from '../APICalls/tkAPICalls';


const TITLE = 'Chọn nhân sự';
const PersonnelPicker = (props) => {
    const { state, dispatch } = useContext(store);

    const onClosePicker = props.onClosePicker;
    const onPicked = props.onPicked;
    const [tabIdx, setTabIdx] = useState(0);
    const [gid, setGID] = useState('');

    const onSelect = (e, p) => {
      onPicked(p);
      // onClosePicker();
    };


    useEffect(() => { T.l('personnels: ' + state.personnels?.length); }
    , []);

    const findByGID = () => {
      dispatch({ type: Actions.SET_BUSY, busy: true });
      tkAPICalls.personnelShopLinking(state.shopID, gid).then((rs) => {
        if (rs == null) {
          glob.toast('Không tìm thấy nhân sự');
        }
        else {
          onPicked(rs);
        }
      });
      dispatch({ type: Actions.SET_BUSY, busy: false });
    }

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        <MTopBar zx={props.zx + 1} pa={'PersonnelPicker'} title={'Chọn nhân sự'} 
          onGoBack={() => onClosePicker()} />
        
        {/* FORM */}
        <MView w_100 jc_cen  row >
          <MView pad={10} style={{ maxWidth: 777 }} grow={1} >


            <HorRadio maV={22}
              curIdx={tabIdx} setCurIdx={setTabIdx} >
              {[ { tit: 'Từ danh sách' }, { tit: 'Từ CMND' }, ]}
            </HorRadio>


            { tabIdx !== 0 ? null :
              <FlatGrid
              itemDimension={200}
              data={state.personnels ?? []}
              renderItem={({ item }) => <PersonnelItem data={item} customCornerIcon={() => null}
                                          onSelect={(e, p) => onSelect(e, p)} 
                                          // onEditItem={p => selectEdit(setShowEditor, hideMenu, p, setCurPerson)}
                                          /> }
              /> }

            { tabIdx !== 1 ? null :
              <MView row w_100 ai_fx_end >
                <MView w='49%'>
                  <Input label="Số CMND/CCCD"
                    value={gid} onChangeText={(str) => setGID(str)}
                    keyboardType='name-phone-pad' />
                </MView>
                <MView w='2%' />
                <MTouchable w='49%' padH={20} h={40} maBot={5} bg_accent borRad={4} row all_cen
                  text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'TÌM' }} 
                  onPress={() => findByGID()} >
                  <MIcons mat white name="search" size={19} />
                </MTouchable>
              </MView>
            }

          </MView>        
        </MView>
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e159', e); glob.toast('Mở không thành công');
      onClosePicker();
      return null;
    }
}
export default PersonnelPicker;

PersonnelPicker.propTypes = {
  onPicked: PropTypes.func,
  zx: PropTypes.number,
  onClosePicker: PropTypes.func
};