import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MView from '../MComponents/MView';
import MText from '../MComponents/MText';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import { FlatGrid } from 'react-native-super-grid';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MIcons from '../MComponents/MIcons';
import MTouchable from '../MComponents/MTouchable';
// import Menu, { MenuDivider, MenuItem } from 'react-native-material-menu';
import EmploymentPeriodsScreen_Editor from './EmploymentPeriodsScreen_Editor';
import AsyncStorage from '@react-native-async-storage/async-storage';
import T from '../T';
import { accent, PERSON_ICON } from '../consts/gst';
import glob from '../glob';
import { KEY_PASSING_POLICY } from './AttendancePoliciesScreen';
import { KEY_PASSING_PERSON } from './PersonnelsScreen';
import ImageLoader from '../MComponents/ImageLoader';
import { Dimensions } from 'react-native';
import { getHISTORY } from '../consts/HISTORY';
import { DURATION, STARTEND } from '../consts/POLICY_TYPES';


const getInitData = async (state, dispatch, setShowEditor, setCurPeriod) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  const periods = await tkAPICalls.getPeriods(state.shop, dispatch);  
  
  try {
      const editingStr = await T.loadObjectString(KEY_DEV_OPEN_PERIOD);
      const curPeriod = editingStr ? JSON.parse(editingStr) : null;
      // supposedly editing a period obj (only creating in this screen) 
      if (curPeriod) {
        setCurPeriod?.(curPeriod); setShowEditor?.(true);
      }
      else {
        const passPolicyStr = await T.loadObjectString(KEY_PASSING_POLICY);
        const curPolicy = passPolicyStr ? JSON.parse(passPolicyStr) : null;
        // supposedly using a policy obj to create a period
        if (curPolicy) {
          setCurPeriod({
            periods: [
              {
                policies: [ curPolicy ]
              }
            ]
          });
          setShowEditor?.(true);
        }
        else {
          const passPersonStr = await T.loadObjectString(KEY_PASSING_PERSON);
          const curPerson = passPersonStr ? JSON.parse(passPersonStr) : null;
          // supposedly using a person obj to create a period
          if (curPerson) {
            setCurPeriod({
              person: curPerson
            });
            setShowEditor?.(true);
          }
        }
      }
  }
  catch (e) {}
  dispatch({ type: Actions.SET_BUSY, busy: false });
}


const selectEdit = (setShowEditor, /* hideMenu, */ curPeriod, setCurPeriod) => {
  // hideMenu();
  setShowEditor(true); window.scrollTo(0, 0);
  setCurPeriod(curPeriod);
  if (curPeriod) {
    AsyncStorage.setItem(KEY_DEV_OPEN_PERIOD, JSON.stringify(curPeriod));
  }
  else {
    AsyncStorage.removeItem(KEY_DEV_OPEN_PERIOD);
  }
}

const selectSetEnd = (state, dispatch, /* hideMenu, */ curPeriod, setCurPeriod, setShowEditor) => {
  // hideMenu();
  const policieObjs = curPeriod?.periods?.find(p => !p.ended)?.policies;
  const policieStrs = policieObjs?.map(p => p.name)?.join(', ') ?? null;

  const prompt1OnOKHandler = () => {

    tkAPICalls.endPeriod(curPeriod).then( async ok => {
      if (ok) {
        setShowEditor?.(false);
        await getInitData(state, dispatch);

          const onOKPrompt2 = () => {
            setCurPeriod({
              person: curPeriod?.person
            });
            setShowEditor?.(true);
          }
          // prompt2
          glob.prompt(0, `${curPeriod?.person?.name ?? '...'}`,
          'có đi làm lại vào tương lai không?'
          , () => onOKPrompt2()
          , { mood: 1, tit: 'Có' }
          , [ { mood: -1, tit: 'Không', note: '(hoặc chưa có kế hoạch)' } ]
          );

      }
      else {
        glob.toast('Lưu không thành công');
      }
    });
  };

  // prompt1
  glob.prompt(-1, `Cho ${curPeriod?.person?.name} nghỉ?`, 
    `Ngừng${1 < policieObjs?.length ? ' các' : ''} ca làm` + (policieStrs ? `: ${policieStrs}` : '')
    , () => prompt1OnOKHandler()
    , null
    , [ { mood: 0, tit: 'không' } ]
    );
}


const closeEditor = (state, dispatch, saved, setCurPeriod, setShowEditor) => {
  setCurPeriod(null); setShowEditor(false); window.scrollTo(0, 0);
  AsyncStorage.multiRemove([ KEY_DEV_OPEN_PERIOD, KEY_PASSING_POLICY, KEY_PASSING_PERSON ]);
  if (saved) {
    getInitData(state, dispatch, setShowEditor, setCurPeriod);
  }
}

const KEY_DEV_OPEN_PERIOD = 'KEY_DEV_OPEN_PERIOD';
const TITLE = 'Biên chế cửa hàng';
export const EMPLOYMENT_PERIODS_SCREEN = 'periods';
const EmploymentPeriodsScreen = (props) => {
    document.title = TITLE;
    const { state, dispatch } = useContext(store);
    // const [doShowMenu, setDoShowMenu] = useState(null);
    // const [coord, setCoord] = useState(null);
    const [curPeriod, setCurPeriod] = useState(null);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => { getInitData(state, dispatch, setShowEditor, setCurPeriod); }
    , []);

    // let _menu;
    // const hideMenu = () => { _menu?.hide(); };
    const showMenu = (e, p) => { 
      // setDoShowMenu(true);
      // setCoord({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
      setCurPeriod(p);     
      selectEdit(setShowEditor, /* hideMenu, */ p, setCurPeriod) 
    };

    const _gridData = (state.periods ?? []);
    // const _gridData = [...(state.periods ?? [])];
    // [..._gridData].forEach(d => _gridData.push(d));
    // [..._gridData].forEach(d => _gridData.push(d));

    return (
      <MView fill_100 bg='#f5f7fa' >
        { showEditor ? null : <MTopBar zx={props.zx} pa={'EmploymentPeriodsScreen'} title={state.shop?.shortAddress + ''} backTo={getHISTORY().ME_AHA_OPERATION} />}

        <MView all_cen w_100 pad={22} >
          <MText tito c_999 txt='Phân công công việc' />
        </MView>

        <MTouchable w={170} h={40} ma={10} bg='#ff902b' borRad={4} row all_cen 
          text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Tạo biên chế mới' }} 
          onPress={() => selectEdit(setShowEditor, /* hideMenu, */ { isNewObj: true }, setCurPeriod)} >
          <MIcons ent white name="plus" size={19} />
        </MTouchable>

        {/* GRID */
          showEditor ? null :
          <FlatGrid
            itemDimension={420}
            data={_gridData}
            renderItem={({ item }) => <PeriodItem data={item} onSelect={(e, p) => { showMenu(e, p); }} /> } />
        }
        
        {/* MENU */}
        {/* <MView gone={!doShowMenu} absolute style={{ transform: [{ translateX: coord?.x }, { translateY: coord?.y }] }} >
          <Menu ref={ ref => { _menu = ref; _menu?.show(); }} onHidden={() => setDoShowMenu(false)}
                style={{ shadowColor: '#000', shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 13.84 }} >
            <MenuItem textStyle={{ fontWeight: 'bold' }} >{curPeriod?.person?.name}</MenuItem>
            <MenuDivider />
            <MenuItem disabled={curPeriod?.ended} onPress={() => selectSetEnd(state, dispatch, hideMenu, curPeriod, setCurPeriod, setShowEditor)} >
                {'Cho nghỉ'}</MenuItem>
            <MenuItem onPress={() => selectEdit(setShowEditor, hideMenu, curPeriod, setCurPeriod)} >
                {'Phân công lại công việc'}</MenuItem>
          </Menu>
        </MView> */}
        
        { /* EDITOR */
          !showEditor ? null :
          <EmploymentPeriodsScreen_Editor 
            zx={props.zx + 1}             
            curPeriod={curPeriod} 
            onCloseEditor={saved => closeEditor(state, dispatch, saved, setCurPeriod, setShowEditor)} 
            onSelectSetEnd={() => selectSetEnd(state, dispatch, curPeriod, setCurPeriod, setShowEditor)}
            />
        }

      </MView>
    );
}
export default EmploymentPeriodsScreen;
EmploymentPeriodsScreen.propTypes = {
  // zx: PropTypes.number,
};





const PeriodItem = (props) => {
  const pr = props;
  const data = pr.data;

  const _img = tkBioPhotoUrl(data?.person?.id);

  const _periods = (data?.periods ?? []);
  // const _periods = [...(data?.periods ?? [])];
  // [..._periods].forEach(p => { _periods.push(p); });
  // [..._periods].forEach(p => { _periods.push(p); });

  const narrow = Dimensions.get('window').width < 450;

  let _tit = `${data?.person?.name}`;
  _tit += data?.notYet === true ? ' ( tạm nghỉ )' : data?.ended === false ? '' : data?.ended === true ? ' ( nghỉ )' : '?';

  // T.l('pe.policies ' + _periods[0].policies?.length , _periods[0].policies )


  let dayHours = 0;  
  (_periods[0].policies ?? []).forEach(po => {
    if (po.pType === STARTEND) {
      dayHours += (po.leaveDelta ?? 0) - (po.arrivalDelta ?? 0) - (po.breakHours ?? 0);
      //T.l(` ${data?.person?.name} | ${po.pType} | ${dayHours} = ${(po.leaveDelta ?? 0)} - ${(po.arrivalDelta ?? 0)} - ${(po.breakHours ?? 0)}`);
    }
    else if (po.pType === DURATION) {
      dayHours += (po.durationHours ?? 0) - (po.breakHours ?? 0);
      //T.l(` ${data?.person?.name} |  ${po.pType} | ${dayHours} = ${(po.durationHours ?? 0)} - ${(po.breakHours ?? 0)}`);
    }
  });

  return (
    <MTouchable h_100 bg_white borRad={5} row={!narrow} pad={10} 
      onPress={(e) => { pr.onSelect?.(e, data) }} >

      <MView row>
        <MView wh={64} maEnd={10} >
          <ImageLoader source={_img} fallback={PERSON_ICON} style={{ width: 64, height: 64, borderRadius: 32 }} />
          { /* DOT */
          <MView wh={16} borRad={8} absolute as_fx_end all_cen style={{ borderWidth: 2, borderColor: 'white' }}
            bg={ data?.notYet === true ? '#7B68EE' : data?.ended === false ? accent : data?.ended === true ? 'red' : '#999' } >
            <MText gone={!__DEV__} cur fontScale={0.75} white txt={'' + (data?.person?.id ?? '')} />
          </MView> }
        </MView>
        <MText gone={!narrow} c_555 bold nd txt={_tit} />
      </MView>


      <MView grow={1} >
        <MText gone={narrow} c_555 bold nd txt={_tit} />

        { /* PERIODS */
          _periods.map((pe, k) => {
            const _depoRelease = new Date(pe?.depositRelease);
            return (
            <MView key={k} maV={7} padTop={7} style={{ borderTopWidth: 1, borderColor: '#eee' }} >
              <MView row >
                <MView>
                  <MText nd color={ pe.notYet ? '#7B68EE' : !pe.ended ? accent : '#999' } txt={`${pe.startDateStr} ${T.isNullOrEmpty(pe.role) ? '' : `(${pe.role})`}`} />
                  
                  <MView row ai_cen maTop={7} >
                    <MIcons mat name='attach-money' size={15} w={22} c_999 />
                    <MText gone={!(0 < pe.salary)} note c_999 txt={Number(pe.salary).toLocaleString() + ' /h'} />
                  </MView>

                  <MView row ai_cen maTop={9} >
                    <MIcons mat name='attach-money' size={15} w={22} c_999 />
                    <MText gone={!(0 < pe.salary)} note c_999 txt={Number(T.roundUpMoney_Mils(pe.salary * dayHours * 30)).toLocaleString() + ' / tháng'} />
                  </MView>
                  
                  <MView gone={!(0 < pe.deposit)} row ai_cen maTop={11} >
                    <MIcons ant name='Safety' size={14} w={22} maEnd={2} c_999 />
                    <MText note c_999 txt={Number(pe.deposit).toLocaleString() 
                      + (_depoRelease?.getFullYear() > 1 ? ` Trả: ${T.formatDDMMYY(_depoRelease)}` : '')} />
                  </MView>

                </MView>

                <MIcons gone={!pe.ended} ent name='chevron-right' size={15} c_999 maTop={2} />
                <MText gone={!pe.ended} nd c_999 txt={pe.endDateStr} />
                <MView grow={1} />
                <MView>
                { /* PERIOD'S POLICIES */
                  (pe.policies ?? [])?.map((po, kk) => 
                  <MView key={kk} ai_fx_end maBot={4} >
                      <MText tind c_999={pe.ended} fontScale={0.8} txt={`${po.name}`} maBot={3} />
                      <MView row jc_fx_end gone={!(po.weekDays?.includes(false))} >
                        { /* POLICY'S WEEK DAYS */
                          po.weekDays.map((wd, kkk) =>
                          <MView key={kkk} bg={wd ? accent : '#ccc'} pad={3} borRad={2} maStart={2} >
                            <MText note white txt={T.convertToWeekDay(kkk)} />
                          </MView>
                        )}
                      </MView>
                  </MView>
                )}
                </MView>
              </MView>
            </MView>
            );
          } ) }

      </MView>





      {/* <MView absolute w_100 row reverse padEnd={20} >
        <MTouchable onPress={() => pr.onEdit Item(data)} >
          <MIcons mat name='edit' c_999 size={16} />
        </MTouchable>
      </MView> */}
    </MTouchable>
  );
}



