import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { StyleSheet } from 'react-native';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import { PERSON_ICON } from '../consts/gst';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MIcons from '../MComponents/MIcons';
import MText from '../MComponents/MText';
import MTopBar from '../MComponents/MTopBar';
import MTouchable from '../MComponents/MTouchable';
import MView from '../MComponents/MView';
import RangePicker, { findTit, RANGE_MODE } from '../MComponents/RangePicker';
import { Actions, store } from '../store';
import T from '../T';
import ReportScreen_PersonView from './ReportScreen_PersonView';
import { getHISTORY } from '../consts/HISTORY';

export const ReportScreenItem_BL_Landscape = (props) => {
    const pr = props;

    const data = pr.data;
    const isTitle = data?.isTitle;
    const person = data?.person;
    const ov = data?.overview;
    const onSelect = pr.onSelect;
    const _img = tkBioPhotoUrl(person?.id);
    const _real_earning = (ov?._earning ?? 0) + (ov?._allowanceDaily ?? 0);
    
    const cellPr = { jc_cen: true, padStart: 11, padV: 11 };

    return (
      <MTouchable bg_white /* onPress={() => onSelect(data)} */
        onLongPress={() => { T.l('data', data); }} >
        <MView style={{ borderBottomWidth: 1, borderColor: '#eee' }}>
            <MView row>
                <MView w={48} { ...cellPr } >
                {   isTitle ? null :
                    <ImageLoader source={_img} fallback={PERSON_ICON} style={{ width: 32, height: 32, borderRadius: 16 }} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'TÊN'} /> :
                    <MText nd bold txt={person?.name} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Vai trò'} /> :
                    <MText nd bold txt={ov?.role} />
                }
                </MView>
                <MView w='5%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Có mặt'} /> :
                    <MText nd bold accent={ov?._attHoursExcess} txt={ov?._attHours?.toFixed(1) + ' h'} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Được phân công'} /> :
                    <MText nd bold txt={ov?._totHours?.toFixed(1) + ' h'} />
                }
                </MView>
                <MView w='5%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Ghi nhận'} /> :
                    <MText nd bold txt={ov?._regHours?.toFixed(1) + ' h'} />
                }
                </MView>
                <MView w='7%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Đi làm'} /> :
                    <MText nd bold txt={ov?._regDays + '/' + ov?._totDays + ' ngày'} /> 
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Thu nhập'} /> :
                    <MText nd bold txt={ Number(ov?._earning?.toFixed(0)).toLocaleString()  + 'đ'} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Phụ cấp'} /> :
                    <MText nd bold txt={ Number(ov?._allowanceDaily?.toFixed(0)).toLocaleString()  + 'đ'} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Thực lĩnh'} /> :
                    <MText nd bold txt={ Number(_real_earning.toFixed(0)).toLocaleString()  + 'đ'} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Tạm ứng'} /> :
                    <MText nd orange bold txt={ Number(ov?._allowanceMonthly?.toFixed(0)).toLocaleString()  + 'đ'} />
                }
                </MView>
                <MView w='10%' { ...cellPr } style={ isTitle ? st.cellConTit : st.cellCon} >
                {   isTitle ? <MText nd bold txt={'Còn lại'} /> :
                    <MText nd accent bold txt={ Number((_real_earning - (ov?._allowanceMonthly ?? 0)).toFixed(0)).toLocaleString()  + 'đ'} />
                }
                </MView>
            </MView>
        </MView>
      </MTouchable>
    );
}

const st = StyleSheet.create({
    cellCon: { borderStartWidth: 1, borderColor: '#eee' },
    cellConTit: { borderStartWidth: 1, borderTopWidth: 1, borderColor: '#eee' }
})