
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { store } from '../store';
import T from '../T';
import glob from '../glob';
import { FlatGrid } from 'react-native-super-grid';
import { PolicyItem } from '../Screens/AttendancePoliciesScreen';


const TITLE = 'Chọn ca làm việc';
const PolicyPicker = (props) => {
    const { state, dispatch } = useContext(store);

    const onClosePicker = props.onClosePicker;
    const onPicked = props.onPicked;

    const onSelect = (e, p) => {
      onPicked(p);
      // onClosePicker();
    };

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        <MTopBar zx={props.zx} pa={'PolicyPicker'} title={'Chọn ca làm việc'} 
          onGoBack={() => onClosePicker()} />
        
        {/* FORM */}
        <MView pad={10} >
          
          <FlatGrid
            itemDimension={200}
            data={state.policies ?? []}
            renderItem={({ item }) => <PolicyItem data={item} customCornerIcon={() => null}
                                        onSelect={(e, p) => onSelect(e, p)} 
                                        /> }
            />

        </MView>        
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e159', e); glob.toast('Mở không thành công');
      onClosePicker();
      return null;
    }
}
export default PolicyPicker;

PolicyPicker.propTypes = {
  onPicked: PropTypes.func,
  zx: PropTypes.number,
  onClosePicker: PropTypes.func
};