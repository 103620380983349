
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MView from '../MComponents/MView';
import MText from '../MComponents/MText';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import { FlatGrid } from 'react-native-super-grid';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MIcons from '../MComponents/MIcons';
import MTouchable from '../MComponents/MTouchable';
// import Menu, { MenuDivider, MenuItem } from 'react-native-material-menu';
import PersonnelsScreen_Editor from './PersonnelsScreen_Editor';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EMPLOYMENT_PERIODS_SCREEN } from './EmploymentPeriodsScreen';
import { openPage } from '../../App';
import ImageLoader from '../MComponents/ImageLoader';
import { PERSON_ICON } from '../consts/gst';
import { getHISTORY } from '../consts/HISTORY';
import T from '../T';


const getInitData = async (state, dispatch, setCurPerson, setShowEditor) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  const persons = await tkAPICalls.getPersonnels(state.shop, dispatch);
  try {
    const editingStr = await T.loadObjectString(KEY_DEV_OPEN_PERSON);
    const curPerson = editingStr ? JSON.parse(editingStr) : null;
    if (curPerson) {
      setCurPerson(curPerson);
      setShowEditor(true); window.scrollTo(0, 0);
    }
  }
  catch (e) {}
  dispatch({ type: Actions.SET_BUSY, busy: false });
}


const selectCreatePeriod = async (state, curPerson) => {
  await AsyncStorage.setItem(KEY_PASSING_PERSON, JSON.stringify(curPerson));
  openPage(EMPLOYMENT_PERIODS_SCREEN);
}


const selectEdit = (setShowEditor, /* hideMenu, */ curPerson, setCurPerson) => {
  // hideMenu();
  setShowEditor(true); window.scrollTo(0, 0);
  setCurPerson(curPerson);
  if (curPerson) {
    AsyncStorage.setItem(KEY_DEV_OPEN_PERSON, JSON.stringify(curPerson));
  }
  else {
    AsyncStorage.removeItem(KEY_DEV_OPEN_PERSON);
  }
}


const closeEditor = (state, dispatch, saved, setCurPerson, setShowEditor) => {
  setCurPerson(null);  setShowEditor(false); window.scrollTo(0, 0);
  AsyncStorage.removeItem(KEY_DEV_OPEN_PERSON);
  if (saved) {
    getInitData(state, dispatch, setCurPerson, setShowEditor);
  }
}

const KEY_DEV_OPEN_PERSON = 'KEY_DEV_OPEN_PERSON';
export const KEY_PASSING_PERSON = 'KEY_PASSING_PERSON';
const TITLE = 'Các nhân sự';
export const PERSONNELS_SCREEN = 'personnels';
const PersonnelsScreen = (props) => {
    document.title = TITLE;
    const { state, dispatch } = useContext(store);
    // const [doShowMenu, setDoShowMenu] = useState(null);
    // const [coord, setCoord] = useState(null);
    const [curPerson, setCurPerson] = useState(null);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => { getInitData(state, dispatch, setCurPerson, setShowEditor); }
    , []);

    // let _menu;
    // const hideMenu = () => { _menu?.hide(); };
    const showMenu = (e, p) => { 
      // setDoShowMenu(true);
      // setCoord({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
      setCurPerson(p);
      selectEdit(setShowEditor, /* hideMenu, */ p, setCurPerson)
    };

    return (
      <MView fill_100 bg='#f5f7fa' >
        { showEditor ? null : <MTopBar zx={props.zx} pa={'PersonnelsScreen'} title={state.shop?.shortAddress + ''} backTo={getHISTORY().ME_AHA_OPERATION} />}

        <MView all_cen w_100 pad={22} >
          <MText tito c_999 txt='Các nhân viên' />
        </MView>

        <MTouchable w={170} h={40} ma={10} bg='#ff902b' borRad={4} row all_cen 
          text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Tạo nhân viên mới' }} 
          onPress={() => selectEdit(setShowEditor, /* hideMenu, */ { address: '' }, setCurPerson)} >
          <MIcons ent white name="plus" size={19} />
        </MTouchable>

        {/* GRID */
          showEditor ? null :
          <FlatGrid
            itemDimension={240}
            data={state.personnels ?? []}
            renderItem={({ item }) => <PersonnelItem data={item} 
                                        onSelect={(e, p) => { showMenu(e, p); }} 
                                        onEditItem={p => selectEdit(setShowEditor, /* hideMenu, */ p, setCurPerson)}
                                        /> }
            />
        }
        
        {/* MENU */}
        {/* <MView gone={!doShowMenu} absolute style={{ transform: [{ translateX: coord?.x }, { translateY: coord?.y }] }} >
          <Menu ref={ ref => { _menu = ref; _menu?.show(); }} onHidden={() => setDoShowMenu(false)}
                style={{ shadowColor: '#000', shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 13.84 }} >
            <MenuItem textStyle={{ fontWeight: 'bold' }} >{curPerson?.name}</MenuItem>
            <MenuDivider />
            <MenuItem onPress={() => selectEdit(setShowEditor, hideMenu, curPerson, setCurPerson)}>
              {'Chỉnh sửa'}</MenuItem>
            <MenuItem onPress={() => selectCreatePeriod(state, curPerson)} >
              {'Phân công công việc'}</MenuItem>
          </Menu>
        </MView> */}
        
        { /* EDITOR */
          !showEditor ? null :
          <PersonnelsScreen_Editor  curPerson={curPerson} 
            zx={props.zx + 1} 
            onCloseEditor={saved => closeEditor(state, dispatch, saved, setCurPerson, setShowEditor)} 
            onSelectCreatePeriod={() => selectCreatePeriod(state, curPerson)} 
            />
        }

        {/* <Time_Picker value={null} onChange={a => { }} /> */}

      </MView>
    );
}
export default PersonnelsScreen;
PersonnelsScreen.propTypes = {
  zx: PropTypes.number,
};





export const PersonnelItem = (props) => {
  const pr = props;
  const data = pr.data;
  const locked = pr?.locked;

  const _img = 0 < data?.id ? tkBioPhotoUrl(data?.id) : null;

  return (
    <MTouchable bg_white={!locked} borRad={5} row pad={10} w={pr.width} style={locked ? { borderWidth: 1, borderColor: '#e4e9f2' } : null}
      disabled={locked}
      onPress={(e) => { pr.onSelect?.(e, data) }} >
      {/* <Image source={{ uri: _img }} style={{ width: 64, height: 64, borderRadius: 32, marginEnd: 10 }} /> */}

      { !_img ? null :
        <ImageLoader source={_img} fallback={PERSON_ICON}
          style={{ width: 64, height: 64, borderRadius: 32, marginEnd: 10 }} />
      }
      
      <MView>
        <MText c_555 bold nd txt={data?.name} />
        <MText nd teal txt={data?.phone} maV={7} />
        <MText nd c_555 txt={data?.address?.split(',')?.[0] ?? data?.address} />
      </MView>

      <MView gone={locked} absolute w_100 row reverse padEnd={20} >
        <MTouchable onPress={(e) => pr.onEditItem?.(data) ?? pr.onSelect?.(e, data) } >
          { !pr.customCornerIcon
            ? <MIcons mat name='edit' c_999 size={16} />
            : pr.customCornerIcon()
          }
        </MTouchable>
      </MView>

    </MTouchable>
  );
}

PersonnelItem.propTypes = {
  data: PropTypes.any,
  width: PropTypes.number,
  onSelect: PropTypes.func,
  onEditItem: PropTypes.func,
  customCornerIcon: PropTypes.func,
  locked: PropTypes.bool,
};



