
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { createElement, Image, StyleSheet } from 'react-native';
import glob from '../glob';
import T from '../T';
import MTouchable from './MTouchable';
import MView from './MView';

export default function Time_Picker(props) {
  const val = T.floatToTimeStr(props.value, true)
  return (
    <MView {...props} >
      <_Time_Picker print={props.print} value={val} onChange={a => {
        const _fl = stringToFloat(a); let skip = false;
        if (!T.isNullOrUndef(props.min) && _fl < props.min) { T.l(`skip ${_fl} < ${props.min}`); skip = true; glob.toast('Giá trị không hợp lệ'); }
        if (!T.isNullOrUndef(props.max) && _fl > props.max) { T.l(`skip ${_fl} > ${props.max}`); skip = true; glob.toast('Giá trị không hợp lệ'); }
        if (!skip) { props.onChange(_fl); }
      }} />
    </MView>
  );
}
Time_Picker.propTypes = {
  print: PropTypes.bool,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};


function _Time_Picker(props) {
  return (
    <input type='time' value={props.value} onInput={a => {
        // @ts-ignore
        props.onChange(a?.target?.value);
      }} 
      style={{ fontSize: 15, borderWidth: 0, height: 44, borderRadius: 5, paddingLeft: 11, paddingRight: 11 }} />
  );
}
_Time_Picker.propTypes = {
  print: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export const floatToString = (fl) => {
  var mins = fl * 60;
  var hh = Math.floor(mins / 60); var hhStr = '' + hh; if (hhStr.length === 1) { hhStr = '0' + hhStr; }
  var mm = mins - (hh * 60); var mmStr = '' + mm; if (mmStr.length === 1) { mmStr = '0' + mmStr; }
  return `${hhStr}:${mmStr}`;
}

const stringToFloat = (str) => {
  const spls = str.split(':');
  var hh = Number.parseInt(spls[0]);
  var mm = Number.parseInt(spls[1]);

  return hh + (mm / 60);
}