import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { accent } from '../consts/gst';
import MText from './MText';
import MTouchable from './MTouchable';
import MView from './MView';

export const HorRadio = (props) => {
  
    const pr = props;
    const w = pr.w ?? '100%';
    const h = pr.h ?? 55;
    const tint = pr.tint ?? '#2EBB9C';
    const children = pr.children; let i = -1;
    const curIdx = pr.curIdx ?? 0;
    const setCurIdx = pr.setCurIdx;
  
    return (
      <MView {...pr} w={w} row ai_cen borRad={h * 0.5} style={{ borderColor: tint, borderWidth: 1, overflow: 'hidden' }} >
        { children.map((p, k) => {
          i++;
          const idx = i;
          const selected = idx === curIdx;
          return (
          <MTouchable key={idx} grow={1} padV={11} all_cen bg={selected ? tint : null} 
            onPress={() => setCurIdx(idx)} >
            <MText tind txt={p.tit} color={selected ? 'white' : '#999' } />
          </MTouchable>
          ); })}
      </MView>
    );
  }
  HorRadio.propTypes = {
    ...MView.propTypes,
    curIdx: PropTypes.number,
    setCurIdx: PropTypes.func,
    w: PropTypes.any,
    h: PropTypes.any,
    tint: PropTypes.string,
  };