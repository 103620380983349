
export const getHISTORY = () => {
    return __DEV__ ? HISTORY_DEV : (window.location.hostname.includes('tk2')) ? HISTORY2 : HISTORY;
}

const HISTORY = {
    ME_AHA_OPERATION: 'https://me.ahacafe.vn/store/timekeeping',
    ME_AHA_REPORT: 'https://me.ahacafe.vn/store/report/timekeeping',
    ME_AHA_HOME: 'https://me.ahacafe.vn',
}
const HISTORY2 = {
    ME_AHA_OPERATION: 'https://me2.ahacafe.vn/store/timekeeping',
    ME_AHA_REPORT: 'https://me2.ahacafe.vn/store/report/timekeeping',
    ME_AHA_HOME: 'https://me2.ahacafe.vn',
}
const HISTORY_DEV = {
    ME_AHA_OPERATION: 'http://localhost:4200/store/timekeeping',
    ME_AHA_REPORT: 'http://localhost:4200/store/report/timekeeping',
    ME_AHA_HOME: 'http://localhost:4200',
}