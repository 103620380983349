
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T from '../T';
import { IndexPath, Input, Select, SelectItem } from '@ui-kitten/components';
import MIcons from '../MComponents/MIcons';
import tkAPICalls from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import { SEVEN_BOOLS } from '../consts/TypeChecking';
import Time_Picker from '../MComponents/Time_Picker';
import MText from '../MComponents/MText';
import { DURATION, POLICY_TYPES, STARTEND, POLICY_TYPES_to_IndexPath } from '../consts/POLICY_TYPES';

const parseProps = (props, dispatch, setName, setArrivalTime, setLeaveTime, setBreakH, setDurH, setOptionIdx, setWeekDays) => {
  if (props.curPolicy) {
    const _curPolicy = props.curPolicy;
    document.title = _curPolicy?.name ?? TITLE;
    setName(_curPolicy?.name); setArrivalTime(_curPolicy?.arrivalDelta); setLeaveTime(_curPolicy?.leaveDelta); 
    setBreakH(_curPolicy?.breakHours); setDurH(_curPolicy?.durationHours);
    setOptionIdx(POLICY_TYPES_to_IndexPath(_curPolicy.pType));
    setWeekDays(_curPolicy.weekDays);
  }
  else {
    document.title = TITLE;
  }
}

const savePolicy = (state, dispatch, onCloseEditor, curPolicy, name, arrivalTime, leaveTime, breakH, durH, optionIdx, weekDays) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  tkAPICalls.savePolicy( state.cred , {
        ...curPolicy,
        shop_id: state.shop.id,
        name: name,
        arrivalDelta: arrivalTime - state.shop.openTime, leaveDelta: leaveTime - state.shop.openTime,
        durationHours: durH, breakHours: breakH,
        weekDays: weekDays, pType: optionIdx.row
      }
  )
  .then(ok => {
    dispatch({ type: Actions.SET_BUSY, busy: false });    
    if (ok) {
      glob.toast('Đã lưu');
      onCloseEditor({ saved: true });
    }
    else {
      glob.toast('Lưu không thành công');
    }
  });
}


const TITLE = 'Tạo ca làm việc';
const AttendancePoliciesScreen_Editor = (props) => {
    const { state, dispatch } = useContext(store);
    
    const [name, setName] = useState('');

    const [arrivalTime, setArrivalTime] = useState(0);
    const [leaveTime, setLeaveTime] = useState(null);
    const [breakH, setBreakH] = useState(0);
    const [durH, setDurH] = useState(0);

    const options = [ 'Ca có giờ đến giờ về', 'Ca làm đủ số giờ' ];
    const [optionIdx, setOptionIdx] = useState(new IndexPath(0));
    const optionDisplay = options[optionIdx.row];
    
    const [weekDays, setWeekDays] = useState([ true, true, true, true, true, true, true ]);

    useEffect(() => { parseProps(props, dispatch, setName, setArrivalTime, setLeaveTime, setBreakH, setDurH, setOptionIdx, setWeekDays); }
    , []);

    const onCloseEditor = props.onCloseEditor;
    const onSelectCreatePeriod = props.onSelectCreatePeriod;
    const curPolicy = props.curPolicy;

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        <MTopBar zx={props.zx} pa={'AttendancePoliciesScreen_Editor'} title={0 < curPolicy?.id ? (curPolicy?.name ?? '') : 'Tạo ca làm việc'}
          onGoBack={() => onCloseEditor()} />
        
        {/* FORM */}
        <MView w_100 jc_cen row >
          <MView pad={10} 
              style={{ maxWidth: 777 }} grow={1} >
            
            <Input label="Tên" value={name} onChangeText={(str) => setName(str)} keyboardType='name-phone-pad' style={styles.input} />
            
            <Select value={optionDisplay} selectedIndex={optionIdx} style={styles.input}
              // @ts-ignore
              onSelect={index => setOptionIdx(index)} >
              { options.map((p, k) => <SelectItem key={k} title={p}/>) }
            </Select>
            

            { /* START END BREAK */
            <MView gone={POLICY_TYPES[optionIdx.row] !== STARTEND } row maBot={20} maTop={5} >
              <MView grow={1}>
                <MText bold tind fontScale={0.75} txt='Giờ đến' color='#8f9bb3' />
                <Time_Picker value={arrivalTime} onChange={a => { setArrivalTime(a); }} />
              </MView>
              <MView w={10} />
              <MView grow={1}>
                <MText bold tind fontScale={0.75} txt={'Giờ về'} color='#8f9bb3' />
                <Time_Picker print value={leaveTime} onChange={a => setLeaveTime(a)} />
              </MView>
            </MView>
            }
            { POLICY_TYPES[optionIdx.row] !== STARTEND ? null :
            <Input label="Giờ nghỉ" keyboardType='number-pad' style={styles.input} 
            // value={'' + (breakH ?? 0)} 
            value={breakH === 0 ? '' : T.numberToDigitGroupedString(breakH)} 
            // onChangeText={(str) => setBreakH(Number.parseFloat(str))} 
            onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setBreakH(Number.isNaN(_s) ? 0 : _s); }}
            />
            }


            { /* DURATION BREAK */
            POLICY_TYPES[optionIdx.row] !== DURATION ? null :
            <MView row>
              <MView grow={1} >
                <Input label="Số giờ làm" keyboardType='number-pad' style={styles.input} 
                // value={'' + (durH ?? 0)} 
                value={durH === 0 ? '' : T.numberToDigitGroupedString(durH)} 
                // onChangeText={(str) => setDurH(Number.parseFloat(str))}
                onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setDurH(Number.isNaN(_s) ? 0 : _s); }}
                />
              </MView>
              <MView w={10} />
              <MView grow={1} >
                <Input label="Giờ nghỉ" keyboardType='number-pad' style={styles.input} 
                // value={'' + (breakH ?? 0)} 
                value={breakH === 0 ? '' : T.numberToDigitGroupedString(breakH)} 
                // onChangeText={(str) => setBreakH(Number.parseFloat(str))} 
                onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setBreakH(Number.isNaN(_s) ? 0 : _s); }}
                />
              </MView>
            </MView>
            }
            
            <MView row maV={8} >
              { weekDays.map((wd, k) => 
                <MTouchable key={k} grow={1} all_cen padV={8} borRad={5} 
                    maStart={k === 0 ? 0 : 5}
                    bg={ wd ? '#37bc9b' : '#ccc' } 
                    onPress={() => setWeekDays(T.toogleWeekDaysHelper(weekDays, k))}>
                  <MText nd white txt={T.convertToWeekDay(k)} />
                </MTouchable>) }
            </MView>
            
            {/* BUTTONS */}
            { !(0 < curPolicy?.id) ? null :
              <MTouchable padH={20} h={40} maV={11} bg='#2586D0' borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'PHÂN CÔNG CÔNG VIỆC' }} 
                onPress={() => onSelectCreatePeriod()} >
                <MIcons matCom white name="table-clock" size={19} />
              </MTouchable>
            }
            <MView row maTop={10} >
              <MTouchable w='49%' padH={20} h={40} bg='#ff902b' borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'QUAY LẠI' }} 
                onPress={() => onCloseEditor()} >
                <MIcons feather white name="x" size={19} />
              </MTouchable>
              <MView w='2%' />
              <MTouchable w='49%' padH={20} h={40} bg_accent borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'LƯU' }} 
                onPress={() => savePolicy(state, dispatch, onCloseEditor, curPolicy, name, arrivalTime, leaveTime, breakH, durH, optionIdx, weekDays)} >
                <MIcons mat white name="done" size={19} />
              </MTouchable>
            </MView>

          </MView>
        </MView>
        

        
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e165', e); glob.toast('Mở không thành công');
      onCloseEditor();
      return null;
    }
}
export default AttendancePoliciesScreen_Editor;

AttendancePoliciesScreen_Editor.propTypes = {
  onCloseEditor: PropTypes.func,
  zx: PropTypes.number,
  onSelectCreatePeriod: PropTypes.func,
  curPolicy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    shop_id: PropTypes.number,
    arrivalDelta: PropTypes.number,
    leaveDelta: PropTypes.number,
    breakHours: PropTypes.number,
    weekDays: PropTypes.arrayOf(SEVEN_BOOLS),
  }),
};

/*
 active: true
arrivalDelta: 1 leaveDelta: 9
breakHours: 3
durationHours: 0
id: 8
name: "testPType4"
pType: "STARTEND"
shop_id: 994
weekDays: (7) [true, true, false, true, false, true, true]
*/

const styles = StyleSheet.create({
  img: { width: 128, height: 128, borderRadius: 64, marginEnd: 20 },
  input: {
    marginBottom: 10,
  },
});
