
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T, { localeDateService } from '../T';
import { Datepicker, Input } from '@ui-kitten/components';
import MIcons from '../MComponents/MIcons';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import PersonnelPicker from '../MComponents/PersonnelPicker';
import { accent, PERSON_ICON } from '../consts/gst';
import MText from '../MComponents/MText';
import { PersonnelItem } from './PersonnelsScreen';
import { HorRadio } from '../MComponents/HorRadio';


const parseProps = (props, dispatch, setPerson, setDate, setTypeIdx, setReason, setNotes) => {
  if (props.curLeave) {
    const _curLeave = props.curLeave;
    document.title = _curLeave?.name ?? TITLE;
    const person = props.curLeave?.personnel;
    setPerson(person);
    setDate(tkAPICalls.de_condense_date(props.curLeave?.dateKey));
    setTypeIdx(props.curLeave?.leaveType);
    setReason(props.curLeave?.reason);
    setNotes(props.curLeave?.notes);
  }
  else {
    document.title = TITLE;
  }
}

const saveLeave = (state, dispatch, onCloseEditor, curLeave, person, date, leaveType, reason, notes) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  tkAPICalls.saveLeave({ ...(curLeave ?? {}) , 
    personnel_id: person.id, shop_id: state.shop.id,
    dateKey: tkAPICalls.condense_date(date),
    leaveType, reason, notes }
  )
  .then(ok => {
    dispatch({ type: Actions.SET_BUSY, busy: false });    
    if (ok) {
      glob.toast('Đã lưu');
      onCloseEditor({ saved: true });
    }
    else {
      glob.toast('Lưu không thành công');
    }
  });
}


const TITLE = 'Tạo xin phép';
const LeavesScreen_Editor = (props) => {
    const { state, dispatch } = useContext(store);
    
    const [showPickPerson, setShowPickPerson] = useState(false);
    const [person, setPerson] = useState(null);
    const pickedPerson = (p) => {
      setPerson(p); setShowPickPerson(false); window.scrollTo(0, 0);
    }
    const [date, setDate] = useState(new Date());

    const [typeIdx, setTypeIdx] = useState(0);
    const [reason, setReason] = useState('');
    const [notes, setNotes] = useState('');

    useEffect(() => { parseProps(props, dispatch, setPerson, setDate, setTypeIdx, setReason, setNotes); }
    , []);

    const onCloseEditor = props.onCloseEditor;
    const onSelectCreatePeriod = props.onSelectCreatePeriod;
    const curLeave = props.curLeave;
    const _img = tkBioPhotoUrl(curLeave?.id);

    /***/ const locked = false;

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        { showPickPerson ? null : 
        <MTopBar zx={props.zx} pa={'LeavesScreen_Editor'} title={0 < curLeave?.id ? 'Thay đổi' : 'Tạo xin phép'} 
          onGoBack={() => onCloseEditor()} />}
        
        {/* FORM */}
        <MView w_100 jc_cen row >
          <MView pad={10} 
              style={{ maxWidth: 777 }} grow={1} >
            
            { /* PERSON */
              <MView pad={0}>
                <MText bold tind fontScale={0.75} txt={locked ? 'Nhân sự' : 'Chọn nhân sự'} color='#8f9bb3' maV={4} />
                <PersonnelItem data={person} width={222} locked={locked}
                  onSelect={(e, p) => { setShowPickPerson(true); window.scrollTo(0, 0); }} 
                  customCornerIcon={() => <MIcons ent name='chevron-down' c_999 size={16} /> }             
                  />
              </MView>
            }


            <MText bold tind fontScale={0.75} txt='Xin phép cho ngày' color='#8f9bb3' maTop={22} maBot={5} />
            {/* START DATE */
            <MView bg_white={!locked} borRad={5} h={44} style={locked ? { borderWidth: 1, borderColor: '#e4e9f2' } : null} >
              <MView absolute fill_100 jc_cen padStart={10} >
                <MText nd txt={T.formatDDMMYYYY(date)} maEnd={glob.ww * 0.02} />
              </MView>
              <Datepicker disabled={locked}
                  dateService={localeDateService}
                  date={date} style={{ opacity: 0.0, flexGrow: 1 }}
                  onSelect={_date => { if (_date) { setDate(_date); } }} />
            </MView> }

            <HorRadio maV={22}
              curIdx={typeIdx} setCurIdx={setTypeIdx} >
              {[
                { tit: 'Xin đến muộn' },
                { tit: 'Xin nghỉ' },
              ]}
            </HorRadio>

            <Input label="Lý do" value={reason} onChangeText={(str) => setReason(str)} keyboardType='name-phone-pad' style={styles.input} />
            <Input label="Ghi chú" value={notes} onChangeText={(str) => setNotes(str)} keyboardType='name-phone-pad' style={styles.input} />

            {/* BUTTONS */}
            <MView row maTop={10} >
              <MTouchable w='49%' padH={20} h={40} bg='#ff902b' borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'QUAY LẠI' }} 
                onPress={() => onCloseEditor()} >
                <MIcons feather white name="x" size={19} />
              </MTouchable>
              <MView w='2%' />
              <MTouchable w='49%' padH={20} h={40} bg_accent borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'LƯU' }} 
                onPress={() => saveLeave(state, dispatch, onCloseEditor, curLeave, person, date, typeIdx, reason, notes)} >
                <MIcons mat white name="done" size={19} />
              </MTouchable>
            </MView>          

          </MView>
        </MView>

        { !showPickPerson ? null :
          <PersonnelPicker 
            zx={props.zx + 1}  
            onPicked={p => pickedPerson(p)}
            onClosePicker={() => { setShowPickPerson(false); window.scrollTo(0, 0); }} />
        }        
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e110', e); glob.toast('Mở không thành công');
      onCloseEditor();
      return null;
    }
}
export default LeavesScreen_Editor;
LeavesScreen_Editor.propTypes = {
  onCloseEditor: PropTypes.func,
  onSelectCreatePeriod: PropTypes.func,
  zx: PropTypes.number,  
  curLeave: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gid: PropTypes.string,
    address: PropTypes.string,
    origin: PropTypes.string,
    phone: PropTypes.string,
  }),
};



const styles = StyleSheet.create({
  img: { width: 128, height: 128, borderRadius: 64, marginEnd: 20 }
  , input: {
    marginBottom: 10
  }
})


