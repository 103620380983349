
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, ViewPropTypes } from 'react-native';
import PropTypes from 'prop-types';
import MView, { findAlignItems, findAlignSelf, findColor, findColorBG, findJustifyContent, findShadow, setMargin, setPadding } from './MView';
import MText from './MText';
import T from '../T';
import { SafeAreaView } from 'react-native-safe-area-context';
import glob from '../glob';

export const MTOAST_PENDING = 'pendingToast';

const MToast = (props) => {

  const [shw, setShw] = useState(false);
  const [txt, setTxt] = useState('');

  const loopWorkload = async () => {
    //console.log('loopWorkload', new Date());
    if (0 < glob._toastQueue.length) {
      let txtGet = glob._toastQueue[0]; glob._deQueueToast(txtGet);
      setTxt(txtGet); setShw(true);
      await T.delay(2000);
      setShw(false);
    }
    else {
      await T.delay(500);
    }
  }

  const [doLoopTime, setDoLoopTime] = useState(0);
  useEffect(() => {     
    const loop = async () => { await loopWorkload(); setDoLoopTime(+new Date()); };
    loop(); }
  , [doLoopTime]);

  const _render = () => {
    if (!shw) { return null; }
    let ww = glob.ww;
    return (
      <MView absolute w_100 ai_cen clickThrough style={{ marginTop: '3%' }} >
        <MView bg='#000000aa' borRad={11} all_cen style={{ padding: 10, minHeight: 44, minWidth: 220, maxWidth: '80%' }} >
          <MText nd fontScale={1.5} white txt={txt} ta_cen />
        </MView>
      </MView>
    );
  };

  return _render();
}
export default MToast;