import T from '../T';

const isBool = (currentValue) => currentValue === true || currentValue === false;
export const SEVEN_BOOLS = function(props, propName, componentName) {
  if (
    !Array.isArray(props) ||
    props.length !== 7 ||
    !props.every(isBool)
  ) {
    return new Error(`${propName} needs to be an array of 7 booleans`);
  }
  return null;
};

export const DEFAULT_WEEKDAYS = [ true, true, true, true, true, true, true ];



const isInt = (currentValue) => Number.isInteger(currentValue);
export const INT_ARRAY = function(props, propName, componentName) {
  if (
    !Array.isArray(props) ||
    !props.every(isInt)
  ) {
    return new Error(`${propName} needs to be an array of int`);
  }
  return null;
};