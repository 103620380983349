
import React from 'react';
import { Platform, View, ViewPropTypes, Text, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import MView, { findAlignItems, findAlignSelf, findColor, findColorBG, findJustifyContent, findShadow, propTypesColors, propTypesShared, setMargin, setPadding } from './MView';
import glob from '../glob';
import T from '../T';
// import glob from '../../code/glob';



const _font_get = {
  note: { fontSize: 12, fontFamily: 'Roboto-Regular' , transform: [{ translateY: 12 * -0.02           * 0                            }] },
  nd: { fontSize: 14, fontFamily: 'Roboto-Regular' , transform: [{ translateY: 14 * -0.02             * 0                            }] },
  ndto: { fontSize: 18, fontFamily: 'Roboto-Regular' , transform: [{ translateY: 18 * -0.04           * 0                            }] },
  tito: { fontSize: 24, fontFamily: 'Roboto-Bold' , transform: [{ translateY: 24 * -0.03              * 0                            }] },
  tind: { fontSize: 18, fontFamily: 'Roboto-Bold' , transform: [{ translateY: 18 * -0.04              * 0                            }] },
  tindreg: { fontSize: 18, fontFamily: 'Roboto-Regular' , transform: [{ translateY: 18 * -0.04        * 0                            }] },
  ti: { fontSize: 14, fontFamily: 'Roboto-Bold' , transform: [{ translateY: 14 * -0.02                * 0                            }] },
  
  // curbig: { fontSize: 24, fontFamily: 'Monofonto-Regular' , transform: [{ translateY: 24 * 0.015      * 0                            }] },  
  // cur: { fontSize: 14, fontFamily: 'Monofonto-Regular' , transform: [{ translateY: 14 * 0.09          * 0                            }] },
  
  
  curbig: { fontSize: 24, fontFamily: 'monospace' , transform: [{ translateY: 24 * 0.015      * 0                            }] },  
  cur: { fontSize: 14, fontFamily: 'monospace' , transform: [{ translateY: 14 * 0.09          * 0                            }] },
  
  toptit: { fontSize: 28, fontFamily: 'SourceSansPro-Regular' , transform: [{ translateY: 28 * 0.09   * 0                             }] }
};

const MText = (props) => {
    const pr = props;
    if (pr.gone) return null;

    // const _font_get = font_get.curBinVer;

    const cc = findColor(pr); const bg = findColorBG(pr); 
    const tf = findTransform(_font_get, pr); 
    const ta = findTextAlign(pr); const as = findAlignSelf(pr); const el = findEllipsizeMode(pr); const lb = findLineBreakMode(pr);
    let ff = Platform.OS === 'ios' ? 'Courier' : 'monospace';

    let fs = 6;
         if (pr.note) { ff = _font_get.note.fontFamily; fs = _font_get.note.fontSize; }
    else if (pr.nd) { ff = _font_get.nd.fontFamily; fs = _font_get.nd.fontSize; }
    else if (pr.ndto) { ff = _font_get.ndto.fontFamily; fs = _font_get.ndto.fontSize; }
    else if (pr.tito) { ff = _font_get.tito.fontFamily; fs = _font_get.tito.fontSize; }
    else if (pr.tind) { ff = _font_get.tind.fontFamily; fs = _font_get.tind.fontSize; }
    else if (pr.tindreg) { ff = _font_get.tindreg.fontFamily; fs = _font_get.tindreg.fontSize; }
    else if (pr.ti) { ff = _font_get.ti.fontFamily; fs = _font_get.ti.fontSize; }
    else if (pr.toptit) { ff = _font_get.toptit.fontFamily; fs = _font_get.toptit.fontSize; }

    if (pr.curbig) { ff = _font_get.curbig.fontFamily; fs = _font_get.curbig.fontSize; }
    if (pr.cur) { ff = _font_get.cur.fontFamily; fs = _font_get.cur.fontSize; }

    if (pr.currency) {
      ff = undefined;
    }

    let scale = glob.ww * 0.0005;
    /****/ scale = 1;
    const fxFontSize = fs * scale;
    const fxTransform = [ ...(pr.transform ?? []), ...(tf ?? []) ];

    const _renderTxt = () => (
      <Text { ...pr } ellipsizeMode={el} lineBreakMode={lb}
        style={
            [   (pr.style ?? {})
              , (pr.txtStyle ?? {})
              , { 
                  fontFamily: ff
                , fontSize: Math.floor(fxFontSize * (pr.fontScale ?? 1)), color: cc, backgroundColor: bg
                , fontWeight: pr.bold ? 'bold' : undefined
                , transform: fxTransform , textAlign: ta
                , alignSelf: as
                
                , width: pr.fill_100 || pr.w_100 ? '100%' : pr.w
                , height: pr.fill_100 || pr.h_100 ? '100%' : pr.h
                , opacity: pr.opacity ?? pr.style?.opacity
                , flexGrow: pr.grow ?? pr.style?.flexGrow, flex: pr.flex ?? pr.style?.flex
                , position: pr.absolute ? 'absolute' : pr.style?.position
                , alignItems: 'center'
                }
              , setMargin(pr)
              , setPadding(pr)
            ]}
        >
        { !pr.reverse_children ? pr.children : null}
        {pr.txt}
        { pr.reverse_children ? pr.children : null}
      </Text>
    );

    if (pr.vert_cen === true) {

      // const jc = findJustifyContent(pr);
      const ai = findAlignItems(pr);
      const as = findAlignSelf(pr);
      const shadow = findShadow(pr);
      return (
        <View { ...pr } 
            style={[ (pr.style ?? {})
                        
              , {
                    color: cc, backgroundColor: bg
                  , flexDirection: pr.row ? ('row' + (pr.reverse ? '-reverse' : '')) : pr.col ? ('column' + (pr.reverse ? '-reverse' : '')) : undefined
                  , justifyContent: 'center'
                  , alignItems: ai, alignSelf: as
                  , paddingHorizontal: pr.padX, borderRadius: pr.borRad
                  //, marginTop: pr.maTop, marginStart: pr.maStart, marginEnd: pr.maEnd, marginBottom: pr.maBot, marginVertical: pr.maV, marginHorizontal: pr.maH
                  //, paddingTop: pr.padTop, paddingStart: pr.padStart, paddingEnd: pr.padEnd, paddingBottom: pr.padBot, paddingVertical: pr.padV
                  , width: pr.fill_100 || pr.w_100 ? '100%' : pr.wh ? pr.wh : pr.w
                  , height: pr.fill_100 || pr.h_100 ? '100%' : pr.wh ? pr.wh : pr.h
                  , opacity: pr.opacity ?? pr.style?.opacity
                  , flexGrow: pr.grow ?? pr.style?.flexGrow, flex: pr.flex ?? pr.style?.flex
                  , position: pr.absolute ? 'absolute' : pr.style?.position
                  , zIndex: pr.zIndex ?? pr.style?.zIndex
                }
              , setMargin(pr)
              , setPadding(pr)
              , shadow
          ]}
          >
            <Text
              style={
                  [   (pr.style ?? {})
                    , (pr.txtStyle ?? {})
                    , { fontFamily: ff, fontSize: Math.floor(fxFontSize * (pr.fontScale ?? 1)), color: cc, backgroundColor: bg
                      , textAlign: ta
                      , fontWeight: pr.bold ? 'bold' : undefined
                      }
                  ]} >
                  { !pr.reverse_children ? pr.children : null}
                  {pr.txt}
                  { pr.reverse_children ? pr.children : null}
            </Text>
        </View>
      );
    }
    return _renderTxt();
}
export default MText;

MText.propTypes = {
  ...propTypesColors

  , txt: PropTypes.string
  , color: PropTypes.string

  , ta_auto: PropTypes.bool ,  ta_left: PropTypes.bool ,  ta_right: PropTypes.bool ,  ta_cen: PropTypes.bool ,  ta_justify: PropTypes.bool

  , el_head: PropTypes.bool , el_middle: PropTypes.bool , el_tail: PropTypes.bool
  , lb_head: PropTypes.bool , lb_middle: PropTypes.bool , lb_tail: PropTypes.bool

  , numberOfLines: PropTypes.number
  , fontScale: PropTypes.number

  , vert_cen: PropTypes.bool
  
  , reverse_children: PropTypes.bool

  , /*f7*/ note: PropTypes.bool
  , /*f6*/ nd: PropTypes.bool
  , /*f5*/ ndto: PropTypes.bool
  , /*f3*/ tito: PropTypes.bool
  , /*f2*/ tind: PropTypes.bool
  , /*f2*/ tindreg: PropTypes.bool
  , /*f1*/ ti: PropTypes.bool
  , /*toptit*/ toptit: PropTypes.bool
  , bold: PropTypes.bool
  ,        curbig: PropTypes.bool
  ,        cur: PropTypes.bool

  , ...propTypesShared
}























const findTransform = (_font_get, pr) => {
  if (!_font_get) { return null; }
  let tf = [];
       if (pr.note) { tf = _font_get.note.transform; }
  else if (pr.nd) { tf = _font_get.nd.transform; }
  else if (pr.ndto) { tf = _font_get.ndto.transform; }
  else if (pr.tito) { tf = _font_get.tito.transform; }
  else if (pr.tind) { tf = _font_get.tind.transform; }
  else if (pr.tindreg) { tf = _font_get.tindreg.transform; }
  else if (pr.ti) { tf = _font_get.ti.transform; }
  else if (pr.curbig) { tf = _font_get.curbig.transform; }
  else if (pr.cur) { tf = _font_get.cur.transform; }
  return tf;
}


const findTextAlign = (pr) => {
  const jc = undefined;
       if (pr.ta_auto) return 'auto';
  else if (pr.ta_left) return 'left';
  else if (pr.ta_right) return 'right';
  else if (pr.ta_cen) return 'center';
  else if (pr.ta_justify) return 'justify';
  return jc;
}

const findLineBreakMode = (pr) => {
  const rs = undefined;
       if (pr.lb_head) return 'head';
  else if (pr.lb_middle) return 'middle';
  else if (pr.lb_tail) return 'tail';
  return rs;
}

const findEllipsizeMode = (pr) => {
  const rs = undefined;
       if (pr.el_head) return 'head';
  else if (pr.el_middle) return 'middle';
  else if (pr.el_tail) return 'tail';
  return rs;
}