import React from 'react';
import { TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import MIcons from './MIcons';
import MView from './MView';
import MText from './MText';
import T from '../T';

const MTopBar = (props) => {
  const pr = props;
  let title = pr.title;
  const backTo = pr.backTo;
  const onGoBack = pr.onGoBack;

  let _zidx = 9990;

  let rootH = 55;
  // if (0 < pr.zx) {
  //   rootH = rootH * pr.zx;
  // }

  _zidx += props.zx ?? 0;
  // T.l('top bar of ' + props.pa, props.zx, _zidx)
  // if (__DEV__) {
  //   title += `|${pr.zx}|${rootH}`
  // }

  const _render = () => {
    return (
      <div style={{ height: rootH, zIndex: _zidx }} >
        <MView w_100 h={55} bg="#37bc9b" style={{ position: 'fixed', zIndex: _zidx, opacity: __DEV__ ? 0.9 : 1 }} >
          <TouchableOpacity onPress={() => { 
              if (onGoBack) {
                onGoBack(); 
              } 
              else if (backTo) {
                // window.open(backTo, '_self');
                if (__DEV__) {
                  setTimeout(() => window.history.back(), 2000);
                }
                else {
                  window.history.back();
                }
              }
              else { 
                T.l('?????');
                // window.history.back(); 
              } 
            }} >
            <MView wh={55} all_cen>
              <MIcons ion white name="ios-chevron-back" />
            </MView>
            <MText vert_cen absolute toptit white w_100 h={55} ta_cen txt={title} />
          </TouchableOpacity>
        </MView>
        { rootH <= 54 ? null :
          <MView h={55} />
        }
      </div>
    );
  };

  return pr.gone ? null : _render();
};
export default MTopBar;

MTopBar.propTypes = {
  title: PropTypes.string,
  backTo: PropTypes.string,
  onGoBack: PropTypes.any,
  pa: PropTypes.any,
  zx: PropTypes.number,
};
