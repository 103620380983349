import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeDateService } from "@ui-kitten/components";
import moment from "moment";
import { KEY_OPENING_PERSON_REP } from "./Screens/ReportScreen";

export default class T {

    static isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    static roundUpMoney_Mils(m) {
        if (!(m > 0)) { return 0; }
        const factor = 100000;
        let rs = m / factor;
        rs = Math.ceil(rs)
        return rs * factor;
    }

    static getTomorow() {
        const tomorow = new Date(); tomorow.setDate(tomorow.getDate() + 1);
        return tomorow;
    }
    static getYesterday() {
        const yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    }

    static l = (tag, ...params) => {
        if (__DEV__) {
            console.log(tag, ...params);
        }
    }



    static delay(time) {
        return new Promise((resolve) => {
            setTimeout(() => { resolve(); }, time)
        })
    }

    /** @param   {any} val String input @returns {boolean}  */
    static isNullOrUndef = (val) => {
        if (val == null || val == undefined) {
            return true;
        }
        return false;
    }
    // _________________________________________________ String is null or empty
    /** String is null or empty
     * @param   {string} _str String input @returns {boolean}  */
    static isNullOrEmpty(_str) {
        if (_str == null || _str == undefined) {
            return true;
        }
        if (!(0 < _str?.length)) {
            return true;
        }

        let str = ('' + _str).toLowerCase();
        if (str === 'null' || str === 'undefined') {
            return true;
        }

        return false;
    }

    static genShadow(level) {
        return { shadowColor: "#000", shadowOffset: { width: 0, height: level * 0.4 }, shadowRadius: level * 0.7, shadowOpacity: 0.34, elevation: level };
    }
    static fireEvent(arg0, arg1) {
    }

    /** 
     * @param {number} i 
     * @returns {String} 
     * */
    static convertToWeekDay = (i) => {
        switch (i) {
            case 0: return 'T2';
            case 1: return 'T3';
            case 2: return 'T4';
            case 3: return 'T5';
            case 4: return 'T6';
            case 5: return 'T7';
            case 6: return 'CN';        
            default: return '?';
        }
    }
    static toogleWeekDaysHelper = (weekDays, idx) => {
        let _wds = [ ...weekDays ];
        if (idx < 0 || idx >= _wds?.length) { return _wds; }
        _wds[idx] = !_wds[idx];
        return _wds;
    }

    /** 
     * @param {String} format 
     * @param {Date=} date
     * @example 
     *      formatTime1('HH:mm:ss')
     *      formatTime2('HH:mm:ss:SSS', sometime)
     *      formatTime3('DD/MM - HH:mm')
     * @returns {String} 
     * */
    static formatTime(format, date) {
        let rs = undefined;
        if (!date) {
            rs = moment().format(format);
        }
        else {
            rs = moment(date).format(format);
        }
        return (rs === 'Invalid date') ? '' : rs;
    }
    static formatTime1(date) { return T.formatTime('HH:mm:ss', date); }
    static formatTime2(date) { return T.formatTime('HH:mm:ss:SSS', date); }
    static formatTime3(date) { return T.formatTime('DD/MM - HH:mm', date); }
    static formatDDMMYY(date) { return T.formatTime('DD/MM/YY', date); }
    static formatDDMMYYYY(date) { return T.formatTime('DD/MM/YYYY', date); }
    static toDateKey(date) { return Number.parseInt(T.formatTime('YYYYMMDD', date)); }
    static formatHHmm(date) { return T.formatTime('HH:mm', date); }

    static upState = (host, newValObj) => {
        return new Promise(resolve => {
            if (host == null) { resolve(); }
            if (host.state == null) { host.state = {}; }
            host.setState({ ...host.state, ...(newValObj ?? {}) }, () => { resolve(); });
        });
    }




    // _________________________________________________ sort_by
    /**
     * @param {*} field 
     * @param {*} reverse 
     * @param {*} primer 
     * @description * VD: favItems = favItems.sort(T.sort_by('orderCount', true, (p) => p));
     */
    static sort_by(field, reverse, primer) {
        //const sort_by = (field, reverse, primer) => {
        const key = primer ?
        function(x) {
            return primer(x[field])
        } :
        function(x) {
            return x[field]
        };  
        reverse = !reverse ? 1 : -1;  
        return function(a, b) {
            // @ts-ignore
            return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
        }
    }


    static floatToTimeStr = (fl, pad) => {
        const hh = Math.floor(fl);
        const mm = ((fl - hh) * 60);
        if (!pad) {
            return `${hh.toFixed(0)}:${mm.toFixed(0)}`
        }

        let _hh = hh.toFixed(0); if (_hh.length == 1) { _hh = '0' + _hh; }
        let _mm = mm.toFixed(0); if (_mm.length == 1) { _mm = '0' + _mm; }
        return `${_hh}:${_mm}`

    }

    static loadObjectString = async (key) => {

        if (!key.includes('PASSING')) {
            if (__DEV__ && key.includes(KEY_OPENING_PERSON_REP)) {
                // proceed
            }
            else {
                T.l('skip loadObjectString a', key);
                return '';
            }
        }

        T.l('try loadObjectString', key);
        const rs = await AsyncStorage.getItem(key);
        return rs;
    }

    static numberToDigitGroupedString = (num) => {
        let ns = '' + (num ?? '');
        if (T.isNullOrEmpty(ns)) { return ''; }
        
        const spl = ns.split('.');
        ns = spl[0];

        let rs = ''; let count = 0;
        for (let i = ns.length - 1; i >= 0; i --) {
            const char = ns[i];
            rs = char + rs;
            count++;
            if (count >= 3 && i > 0) {
                count = 0;
                rs = ',' + rs;
            }
        }

        return rs + (spl.length >= 2 ? ('.' + spl[1]) : '');
    }

    // _________________________________________________ Replace All
    // VD : (string) res = T.replaceAll(res, ' ', '_', true);
    static replaceAll(source, find, replacement, toLower) {

        if (T.isNullOrEmpty(source)) {
            return source;
        }
        
        var re = new RegExp(find, 'g'); 
        var result = source.replace(re, replacement);

        if (toLower == true) {
            result = result?.toLowerCase();
        }

        return result;
    }

    static ensureNotNullArray = (arr) => {
        if (arr != null && arr != undefined) {
            return arr;
        }
        return [];
    }
}

const i18n = {
    dayNames: {
      short: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
      long: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
    },
    monthNames: {
      short: ['Th Một', 'Th Hai', 'Th Ba', 'Th Tư', 'Th Năm', 'Th Sáu', 'Th Bảy', 'Th Tám', 'Th Chín', 'Th Mười', 'Th M Một', 'Th M Hai'],
      long: [
        'Tháng Một', 'Tháng Hai', 'Tháng Ba', 'Tháng Tư', 'Tháng Năm', 'Tháng Sáu', 'Tháng Bảy', 'Tháng Tám', 'Tháng Chín', 'Tháng Mười', 'Tháng Mười Một', 'Tháng Mười Hai'
      ],
    },
  };
// @ts-ignore
export const localeDateService = new NativeDateService('vn', { i18n, startDayOfWeek: 1 });