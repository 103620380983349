
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MView from '../MComponents/MView';
import MText from '../MComponents/MText';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import { FlatGrid } from 'react-native-super-grid';
import tkAPICalls from '../APICalls/tkAPICalls';
import MIcons from '../MComponents/MIcons';
import MTouchable from '../MComponents/MTouchable';
// import Menu, { MenuDivider, MenuItem } from 'react-native-material-menu';
import AttendancePoliciesScreen_Editor from './AttendancePoliciesScreen_Editor';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { floatToString } from '../MComponents/Time_Picker';
import { DEFAULT_WEEKDAYS, SEVEN_BOOLS } from '../consts/TypeChecking';
import T from '../T';
import { DURATION, STARTEND } from '../consts/POLICY_TYPES';
import { accent } from '../consts/gst';
import { openPage } from '../../App';
import { EMPLOYMENT_PERIODS_SCREEN } from './EmploymentPeriodsScreen';
import { getHISTORY } from '../consts/HISTORY';


const getInitData = async (state, dispatch, setCurPolicy, setShowEditor) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  const policies = await tkAPICalls.getPolicies(state.shop, dispatch);
  try {
    const editingStr = await T.loadObjectString(KEY_DEV_OPEN_POLICY);
    const curPolicy = editingStr ? JSON.parse(editingStr) : null;
    if (curPolicy) {
      setCurPolicy(curPolicy);
      setShowEditor(true); window.scrollTo(0, 0);
    }
  }
  catch (e) {}
  dispatch({ type: Actions.SET_BUSY, busy: false });
}


const selectCreatePeriod = async (state, curPolicy) => {
  await AsyncStorage.setItem(KEY_PASSING_POLICY, JSON.stringify(curPolicy));
  openPage(EMPLOYMENT_PERIODS_SCREEN);
}


const selectEdit = (setShowEditor, /* hideMenu, */ curPolicy, setCurPolicy) => {
  // hideMenu();
  setShowEditor(true); window.scrollTo(0, 0);
  setCurPolicy(curPolicy);
  if (curPolicy) {
    AsyncStorage.setItem(KEY_DEV_OPEN_POLICY, JSON.stringify(curPolicy));
  }
  else {
    AsyncStorage.removeItem(KEY_DEV_OPEN_POLICY);
  }
}


const closeEditor = (state, dispatch, saved, setCurPolicy, setShowEditor, shopID) => {
  setCurPolicy(null);  setShowEditor(false); window.scrollTo(0, 0);
  AsyncStorage.removeItem(KEY_DEV_OPEN_POLICY);
  if (saved) {
    getInitData(state, dispatch, setCurPolicy, setShowEditor);
  }
}

const KEY_DEV_OPEN_POLICY = 'KEY_DEV_OPEN_POLICY';
export const KEY_PASSING_POLICY = 'KEY_PASSING_POLICY';
const TITLE = 'Các ca làm việc';
export const ATTENDANCE_POLICIES_SCREEN = 'policies';
const AttendancePoliciesScreen = (props) => {
    document.title = TITLE;
    const { state, dispatch } = useContext(store);
    // const [doShowMenu, setDoShowMenu] = useState(null);
    // const [coord, setCoord] = useState(null);
    const [curPolicy, setCurPolicy] = useState(null);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => { getInitData(state, dispatch, setCurPolicy, setShowEditor); }
    , []);

    // let _menu;
    // const hideMenu = () => { _menu?.hide(); };
    const showMenu = (e, p) => { 
      // setDoShowMenu(true);
      // setCoord({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
      setCurPolicy(p);    
      selectEdit(setShowEditor, /* hideMenu, */ p, setCurPolicy)  
    };

    return (
      <MView fill_100 bg='#f5f7fa' >
        { showEditor ? null : <MTopBar zx={props.zx} pa={'AttendancePoliciesScreen'} title={state.shop?.shortAddress + ''} backTo={getHISTORY().ME_AHA_OPERATION} /> }

        <MView all_cen w_100 pad={22} >
          <MText tito c_999 txt='Các ca làm việc' />
        </MView>

        <MTouchable w={170} h={40} ma={10} bg='#ff902b' borRad={4} row all_cen 
          text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Tạo ca làm việc mới' }} 
          onPress={() => selectEdit(setShowEditor, /* hideMenu, */ { weekDays: DEFAULT_WEEKDAYS }, setCurPolicy)} >
          <MIcons ent white name="plus" size={19} />
        </MTouchable>

        {/* GRID */
          showEditor ? null :
          <FlatGrid
            itemDimension={250}
            data={state.policies ?? []}
            renderItem={({ item }) => <PolicyItem data={item} shop={state.shop}
                                        onSelect={(e, p) => { showMenu(e, p); }} 
                                        onEditItem={p => selectEdit(setShowEditor, /* hideMenu, */ p, setCurPolicy)}
                                        /> }
            />
        }
        
        {/* MENU */}
        {/* <MView gone={!doShowMenu} absolute style={{ transform: [{ translateX: coord?.x }, { translateY: coord?.y }] }} >
          <Menu ref={ ref => { _menu = ref; _menu?.show(); }} onHidden={() => setDoShowMenu(false)}
                style={{ shadowColor: '#000', shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.25, shadowRadius: 13.84 }} >
            <MenuItem textStyle={{ fontWeight: 'bold' }} >{curPolicy?.name}</MenuItem>
            <MenuDivider />
            <MenuItem onPress={() => selectEdit(setShowEditor, hideMenu, curPolicy, setCurPolicy)} >
                {'Chỉnh sửa'}</MenuItem>
            <MenuItem onPress={() => selectCreatePeriod(state, curPolicy)} >
                {'Phân công công việc'}</MenuItem>
          </Menu>
        </MView> */}
        
        { /* EDITOR */
          !showEditor ? null :
          <AttendancePoliciesScreen_Editor zx={props.zx + 1} curPolicy={curPolicy} 
            onCloseEditor={saved => closeEditor(state, dispatch, saved, setCurPolicy, setShowEditor, state.shopID)} 
            onSelectCreatePeriod={() => selectCreatePeriod(state, curPolicy)} 
          />
        }

        {/* <Time_Picker value={null} onChange={a => { }} /> */}

      </MView>
    );
}
export default AttendancePoliciesScreen;
AttendancePoliciesScreen.propTypes = {
  zx: PropTypes.number,
};






export const PolicyItem = (props) => {
  const pr = props;
  const d = pr.data;
  const locked = pr.locked;

  if (d.isAddNew) {
    return (
      <MTouchable borRad={5} all_cen h={108} bg='#ffffff33' //style={{ borderWidth: 1, borderColor: 'white' }}
        onPress={(e) => { pr.onSelect?.(e, d) }} >
        <MIcons ant name='pluscircleo' color={accent} maBot={11} />
        <MText nd accent txt='Chọn thêm ca làm việc' />
      </MTouchable>
    );
  }

  return (
    <MTouchable disabled={locked} bg_white={!locked} borRad={5} row pad={10} 
      style={locked ? { borderWidth: 1, borderColor: '#e4e9f2' } : null}
      onPress={(e) => { pr.onSelect?.(e, d) }} >
      <MView w_100 >
        <MText c_555 bold nd txt={d?.name} />
        
        <MView gone={d.pType !== STARTEND} row w_100 ai_cen maV={7} >
          <MText gone={!d?.arrivalDelta} nd teal txt={floatToString(d?.arrivalDelta)} maV={7} />
          <MIcons ent name='chevron-right' size={15} c_999 maTop={2} />
          <MText gone={!d?.arrivalDelta} nd teal txt={floatToString(d?.leaveDelta)} maV={7} />
          <MView grow={1} />
          <MText gone={!(0 < d.breakHours)} note c_999 txt={'Nghỉ ' + d.breakHours + ' giờ'} />
        </MView>

        <MView gone={d.pType !== DURATION} row w_100 jc_spc_betw ai_cen maV={7} >
          <MText nd teal txt={(d.durationHours ?? '?') + ' giờ'} maV={7} />
          <MView grow={1} />
          <MText gone={!(0 < d.breakHours)} note c_999 txt={'Nghỉ ' + d.breakHours + ' giờ'} />
        </MView>

        <MView row>
          { d.weekDays.map((wd, k) => 
            <MView key={k} grow={1} all_cen padV={6} borRad={5} 
                maStart={k === 0 ? 0 : '1%'}
                bg={ wd ? '#37bc9b' : '#ccc' } >
              <MText nd white txt={T.convertToWeekDay(k)} />
            </MView>) }
        </MView>

      </MView>

      <MView gone={locked} absolute w_100 row reverse padEnd={20} >
        <MTouchable onPress={() => pr.onEditItem(d)} >
          { !pr.customCornerIcon
            ? <MIcons mat name='edit' c_999 size={16} />
            : pr.customCornerIcon()
          }
        </MTouchable>
      </MView>
    </MTouchable>
  );
}
PolicyItem.propTypes = {
  onSelect: PropTypes.func,
  onEditItem: PropTypes.func,
  customCornerIcon: PropTypes.func,
  locked: PropTypes.bool,
  shop: PropTypes.shape({
    id: PropTypes.number,
    shortAddress: PropTypes.string
  }),
  data: PropTypes.shape({
     id: PropTypes.number,
    name: PropTypes.string,
     shop_id: PropTypes.number,
    arrivalDelta: PropTypes.number,
    leaveDelta: PropTypes.number,
    breakHours: PropTypes.number,
    durationHours: PropTypes.number,
    weekDays: PropTypes.arrayOf(SEVEN_BOOLS),
    pType: PropTypes.oneOf([ STARTEND, DURATION ])
  }),
};



