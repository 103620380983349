import T from "../T";
import glob from "../glob";
import { Actions } from "../store";
import mfetch from "../mfetch";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DURATION, POLICY_TYPES, POLICY_TYPES_to_int, STARTEND } from "../consts/POLICY_TYPES";
import { RANGE_MODE } from "../MComponents/RangePicker";

export const TKURL = () => {
    if (__DEV__) {
        // return 'http://192.168.1.4:9998';
        // return 'http://192.168.8.20:9998';
        return 'https://timekeeping.jvjsc.com';
    }
    else {
        return 'https://timekeeping.jvjsc.com';
    }
}

export const tkBioPhotoUrl = (id) => TKURL() + '/personnel/biophoto/' + id + '.jpg';

export default class tkAPICalls {


    static editClosing = (org) => {
        return new Promise((resolve) => {
            tkAPICalls._editClosingCall(org).then(rs => {
                resolve(rs);
            });
        });
    }
    static _editClosingCall = (org) => {
        return new Promise((resolve) => {
            let _url = `/closing/create_edit?username=${glob.userName}&cred=${glob.userCred}`;
            _url = TKURL() + _url;
            mfetch.do(_url
            , { method: 'POST', body: JSON.stringify(org), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => {
                res.json().then(j => {
                    resolve(res.ok);
                });
            })
            .catch(e => {
                glob.toastGeneric();
                resolve(false);
            })
        });
    }





    static getReport(dispatch, shop, openTime, personnel_id, range, date, policies, personnels) {

        
        return new Promise( async (resolve) => {

            if (!(0 < personnels?.length)) {
                personnels = await tkAPICalls.getPersonnels(shop, dispatch);
            }
            if (!(0 < policies?.length)) {
                policies = await tkAPICalls.getPolicies(shop, dispatch);
            }
    
            let orgRange = range;
            switch (range) {
                // TODO
                case RANGE_MODE.YESTERDAY:
                    var d = new Date();
                    d.setDate(d.getDate() - 1);
                    range = null;
                    date = d.toISOString();
                    break;
                case RANGE_MODE._7DAYS:
                    date = null;
                    range = '7days';
                    break;
                case RANGE_MODE.SINGLEDAY:
                    range = null;
                    date = date.toISOString();
                    break;
                case RANGE_MODE.MONTH_THIS:
                    var d = new Date();
                    var firstDay = new Date(d.getFullYear(), d.getMonth() - 0, 1);
                    var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
                    range = `${T.formatDDMMYYYY(firstDay)} ${T.formatDDMMYYYY(lastDay)}`
                    break;
                case RANGE_MODE.MONTH_PREV1:
                    var d = new Date();
                    var firstDay = new Date(d.getFullYear(), d.getMonth() - 1, 1);
                    var lastDay = new Date(d.getFullYear(), d.getMonth(), 0);
                    range = `${T.formatDDMMYYYY(firstDay)} ${T.formatDDMMYYYY(lastDay)}`
                    break;    
                case RANGE_MODE.MONTH_PREV2:
                    var d = new Date();
                    var firstDay = new Date(d.getFullYear(), d.getMonth() - 2, 1);
                    var lastDay = new Date(d.getFullYear(), d.getMonth() - 1, 0);
                    range = `${T.formatDDMMYYYY(firstDay)} ${T.formatDDMMYYYY(lastDay)}`
                    break;    
                default: range = null; break;
            }


            tkAPICalls.getReportCall(shop, personnel_id, range, date, orgRange).then(j => {
                if (!(0 < j?.data?.length)) { resolve([]); }
                else {
                    tkAPICalls.getLeavesCall(shop, personnel_id, range, date, orgRange).then(leaves => {
                        let rs = this._post_process_report(j, policies, personnels, leaves, openTime, orgRange);
                        // T.l('rs', rs)
                        resolve(rs);
                    })
                }
            });
            
        });
    }
    static _post_process_report = (res, policies, personnels, leaves, openTime, orgRange) => {
        let rs = [];

        const isMonthlyRep = orgRange == RANGE_MODE.MONTH_PREV1 || orgRange == RANGE_MODE.MONTH_PREV2;

        // _LEAVES DIC
        let _leaves = {};
        leaves.forEach(l => { 
            l.leaveKey = `${l.shop_id}${l.personnel_id}${l.dateKey}`;
            _leaves[l.leaveKey] = l; 
        });
        
        let data = res?.data ?? [];
        data = data.sort(T.sort_by('dateKey', /***/true, (p) => p));
        // LOOP THROUGH REPORTS
        data.forEach(re => {

            if (!re.adjustedClosingDatas) {
                re.adjustedClosingDatas = [];
                for (let i = 0; i < re.dataCount; i++) { re.adjustedClosingDatas.push(''); }
            }

            let leaveKey = `${re.shop_id}${re.personnel_id}${re.dateKey}`;
            re.leaves = _leaves[leaveKey];

            re.org = { ...re, closingDatas: [...re.closingDatas], policyDatas: [...re.policyDatas], adjustedClosingDatas: [...re.adjustedClosingDatas] };

            re.date = tkAPICalls.de_condense_date('' + re.dateKey);

            let serverNow = new Date(res.now);
            let Difference_In_Time = serverNow.getTime() - re.date.getTime();
            re.daysAgo = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
            re.editable = !(re.daysAgo >= 2);

            re.dow = re.date.getDay() - 1; if (re.dow < 0) { re.dow = 6; }

            for (let i = 0; i < re.closingDatas?.length; i++) {

                let _cd = JSON.parse(re.closingDatas[i]);
                let _pd = tkAPICalls.de_condense_policy(re.policyDatas[i]);
                let _cda = tkAPICalls.de_condense_adjusted_closing(re.adjustedClosingDatas[i]);

                let findPD = (policies ?? [])?.find(p => p.id == _pd?.id);
                _pd.name = findPD?.name;
                _pd._totHours = findPD?._totHours;

                if (_pd?.weekDays?.[re.dow] === false) {
                    _cd.notWorking = true;
                }
                _cd._attHours = _cd.left - _cd.arrived - _pd.breakHours;
                _cd._regHours = _cd._attHours;
                if (_cd._regHours > _pd._totHours) { _cd._regHours = _pd._totHours; }
                _cd._regHoursOrg = _cd._regHours;
                if (!!_cda) {
                    _cd._regHours = _cda.left - _cda.arrived;
                }

                _cd._attHoursLacking = (_cd._attHours - _pd._totHours) < -0.25;
                _cd._regHoursLacking = (_cd._regHours - _pd._totHours) < -0.25;

                _cd._attHoursExcess = _cd._attHours > _pd._totHours;

                re.closingDatas[i] = _cd;
                re.policyDatas[i] = _pd;
                re.adjustedClosingDatas[i] = _cda;
            }

            let find = rs.find(p => p.personnel_id == re.personnel_id);
            if (!find) { rs.push({ personnel_id: re.personnel_id, person: personnels.find(p => p.id === re.personnel_id), reports: [ re ] }); }
            else { find.reports.push(re); }
        });

        const todayKey = T.toDateKey(new Date());
        // LOOP THROUGH RESULT TO CREATE OVERVIEW
        rs.forEach(person => {

            let untimelies = 0; let absences = 0; let leaves = 0;
            let _regHours = 0; let _attHours = 0; let _totHours = 0;
            let _earning = 0; let _allowanceDaily = 0; let _allowanceMonthly = 0; let _role = '';
            let _regDays = 0; let _totDays = 0;

            (person?.reports ?? []).forEach(rep => {

                const day = rep.date.getDate();

                let hasUntimely = false; let hasAbsence = false;
                if (rep.dataCount > 0 && rep?.closingDatas?.length != rep.dataCount && rep?.policyDatas?.length != rep.dataCount) {
                    rep.isError = `${rep?.closingDatas?.length} != ${rep?.policyDatas?.length} != ${rep.dataCount}`;
                }
                else {
                    let repRegHours = 0; let repTotHours = 0; let repAttHours = 0;
                    if (0 < rep.dataCount) {
                        for (let i = 0; i < rep.dataCount; i++) {
                            
                            const pd = rep.policyDatas[i];
                            pd.startTime = T.floatToTimeStr(openTime + pd.arrivalDelta, true);
                            pd.endTime = T.floatToTimeStr(openTime + pd.leaveDelta, true);
                            const cd = rep.closingDatas[i];
                            cd._arrivedTime = T.floatToTimeStr(cd.arrived, true);
                            cd._leftTime = T.floatToTimeStr(cd.left, true);
                            
                            // if (cd.isAbsence) {
                            //     T.l('cd.isAbsence', rep.time, person)
                            // }

                            if (cd.notWorking !== true) {
    
                                repTotHours += pd.pType == DURATION ? pd.durationHours : pd.leaveDelta - pd.arrivalDelta;
                                repTotHours -= pd.breakHours;
                                repRegHours += cd._regHours;
                                repAttHours += cd._attHours;
    
                                if (pd.pType === STARTEND && (cd.startLate > 0 || cd.leaveEarly > 0)) { 
                                    hasUntimely = true; cd.hasUntimely = true; 
                                }
                                // if (pd.pType === DURATION && cd._regHours < pd._totHours) { 
                                if (pd.pType === DURATION && cd._regHoursLacking) { 
                                    hasUntimely = true; cd.hasUntimely = true; 
                                }
                                if (!(cd.recCount >= 2) && rep.dateKey === todayKey) {
                                    // skip if today AND rec count < 2
                                }
                                else if (cd.isAbsence === true || !(cd.recCount >= 2)) { 
                                    hasAbsence = true; cd.hasAbsence = true; 
                                }
                                else { _regDays += rep.dataCount === 1 ? 1 : (1 / rep.dataCount); }
                            }
                        }
                    }
                    else {
                        const isToday = rep.dateKey === todayKey;
                        hasAbsence = !isToday;
                        if (isToday && rep.closingDatas?.[0]) { rep.closingDatas[0].isAbsence = false; }
                    }
                    _regHours += repRegHours;
                    _attHours += repAttHours;
                    _totHours += repTotHours;
                    _earning += repRegHours * rep.salary;
                    if (!hasAbsence) {
                        if (isMonthlyRep) {
                            if (day < 20) {
                                _allowanceMonthly += repRegHours * rep.salary;
                            }
                        }
                    }
                }
                _totDays++;
                untimelies += hasUntimely ? 1 : 0;
                absences += hasAbsence ? 1 : 0;
                rep.hasAbsence = hasAbsence;
                leaves += !!rep.leaves ? 1 : 0;
                if (!rep.hasAbsence) {
                    _allowanceDaily += rep.allowance;
                    if (isMonthlyRep) {
                        if (day < 20) {
                            _allowanceMonthly += rep.allowance;
                        }
                    }
                }
                _role = rep.role ?? '';
            });

            person.overview = {
                untimelies, absences, leaves, _regHours, _totHours, _earning, _allowanceDaily, _regDays, _totDays, _attHours
                , attHoursExcess: _attHours > _totHours
                , _allowanceMonthly: _allowanceMonthly
                , role: _role
            }
        });

        return rs;
    }
    static de_condense_adjusted_closing = (str) => {
        if (T.isNullOrEmpty(str)) return null;
        const s = str.split('|');
        if (s?.length != 4) return null;
        return {
            arrived: Number.parseFloat(s[0]),
            left: Number.parseFloat(s[1]),
            updater: s[2],
            updatedAt: new Date(Number.parseInt(s[3]))
        }
    }
    static de_condense_policy = (str) => {
        if (T.isNullOrEmpty(str)) return null;
        const s = str.split('|');
        if (s?.length != 5 && s?.length != 6) return null;
        if (s[0] == '' + POLICY_TYPES_to_int(STARTEND)) {
            if (s?.length != 6) return null;
            return {
                str: str,
                pType: STARTEND,
                id:  Number.parseInt(s[1]), breakHours:  Number.parseFloat(s[2]),
                arrivalDelta:  Number.parseFloat(s[3]), leaveDelta: Number.parseFloat(s[4]),
                weekDays: tkAPICalls.de_condense_WeekDays(s[5])
            };
        }
        else if (s[0] == '' + POLICY_TYPES_to_int(DURATION)) {
            if (s?.length != 5) return null;
            return {
                str: str,
                pType: DURATION,
                id: Number.parseInt(s[1]), breakHours: Number.parseFloat(s[2]),
                durationHours: Number.parseFloat(s[3]), weekDays: tkAPICalls.de_condense_WeekDays(s[4])
            };
        }
        else {
            return null;
        }
    }
    static de_condense_WeekDays = (wds) => {
        let rs = [ true, true, true, true, true, true, true ];
        if (T.isNullOrEmpty(wds)) return rs;
        rs = [ false, false, false, false, false, false, false ];

        for (let i = 0; i < wds.length; i++) {
            const c = wds.charAt(i);
            if (c == "2"[0]) rs[0] = true;
            if (c == "3"[0]) rs[1] = true;
            if (c == "4"[0]) rs[2] = true;
            if (c == "5"[0]) rs[3] = true;
            if (c == "6"[0]) rs[4] = true;
            if (c == "7"[0]) rs[5] = true;
            if (c == "C"[0]) rs[6] = true;
        }
        
        return rs;
    }
    // VD: 20210502 -> 2021-05-02
    static de_condense_date = (str) => {
        str = '' + (str ?? '');
        if (str?.length != 8) { return null; }
        const _str = `${str.substr(0, 4)}-${str.substr(4, 2)}-${str.substr(6, 2)}`;
        // T.l('de_condense_date', str, _str)
        return new Date(_str);
    }
    // VD: 2021-05-02 -> 20210502
    static condense_date = (date) => {
        return T.toDateKey(date);
    }
/*
dataCount: 1     policyDatas: ["0|5|3|0|16|"]     closingDatas: ["{"arrived":10.033334,"left":23.033333,"startLate":…arly":0.0,"isIncomplete":false,"isAbsence":false}"]
dateKey: 20210327     salary: 10000     shopOpenTime: 7
id: 0 personnel_id: 5 shop_id: 3
*/
/*
{
  "id": 0, "personnel_id": 5, "shop_id": 3, "dateKey": 20210327,
  "salary": 10000, "shopOpenTime": 7,
  "dataCount": 1,
  "policyDatas": [ {
      "id": 5, "breakHours": 3,
      "arrivalDelta": 0, "leaveDelta": 16,
      "weekDays": [ true, false, true, false,true, false, true ] } ],
  "closingDatas": [
    {
      "arrived": 10.033334, "left": 23.033333,
      "startLate": 3.048611111111111, "leaveEarly": 0,
      "isIncomplete": false, "isAbsence": false
    }
  ]
}
*/
    static getReportCall = (shop, personnel_id, range, date, orgRange) => {
        return new Promise((resolve) => {
            let surfix = personnel_id > 0 ? `&personnel_id=${personnel_id}` : '';
            surfix += range ? `&range=${range}` : date ? `&date=${date}` : '';
            let _url = `/report/get?shop_id=${shop?.id}` + surfix;
            T.l('_url', orgRange, _url);
            _url = TKURL() + _url;
            mfetch.do(_url)
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }









    
    // SHOPS ======================================================================================================================================================
    static _last_getShopsCall = { at: 0, data: null, }
    static getShops = (dispatch) => {
        return new Promise((resolve) => {
            let _last = tkAPICalls._last_getShopsCall;
            if (_last.at > 0 && ((+new Date() - _last.at) < 300000) && _last.data != null) {
                // use shops in mem
                resolve(_last.data);
            }
            else {
                AsyncStorage.getItem('shops', (er, str) => {

                    // try use shops in AsyncStorage
                    let usedAsyncStorage = false;
                    try {
                        let _shops = JSON.parse(str);
                        if (0 < _shops?.length) {
                            _shops = tkAPICalls._post_process_shops_cached(_shops)
                            dispatch({ type: Actions.SET_SHOPS, shops: _shops });
                            tkAPICalls._last_getShopsCall = { at: +new Date(), data: _shops };
                            resolve(_shops);
                            usedAsyncStorage = true;
                        }
                    } catch(e) { }

                    if (!usedAsyncStorage) {
                        // get fresh shops
                        tkAPICalls.getShopsCall().then(j => {
                            if (j == null) {
                                T.l('getShopsCall fail'); glob.toastGeneric(1);
                            }
                            else {
                                let _shops = tkAPICalls._post_process_shops_API(j)
                                dispatch({ type: Actions.SET_SHOPS, shops: _shops });
                                tkAPICalls._last_getShopsCall = { at: +new Date(), data: _shops };
                                AsyncStorage.setItem('shops', JSON.stringify(_shops));
                                resolve(_shops);
                            }
                        });
                    }
                });
            }            
        });
    }
    static _post_process_shops_cached = (shops) => {
        if (!shops) { return []; }
        // shops?.forEach(shop => { try { } catch(e) { T.l('e56', e) } });
        return shops;
    }
    static _post_process_shops_API = (shops) => {
        if (!shops) { return []; }
        shops?.forEach(shop => {
            try {
                shop.shortAddress = shop.address?.split(', ')?.[0] ?? '';
            }
            catch(e) { T.l('e69', e) }
        });
        return shops;
    }
    static getShopsCall = () => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + '/shop/all')
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }




    
    
    // PERSONNELS ======================================================================================================================================================
    static last_getPersonnels = null;
    static getPersonnels = (shop, dispatch) => {
        return new Promise((resolve) => {
            // DEV
            if (__DEV__ && 0 < tkAPICalls.last_getPersonnels?.length) {
                dispatch({ type: Actions.SET_PERSONNELS, personnels: tkAPICalls.last_getPersonnels });
                resolve(tkAPICalls.last_getPersonnels);
            }
            else {
                // get fresh personnels
                tkAPICalls.getPersonnelsCall(shop).then(j => {
                    if (j == null) {
                        T.l('getPersonnels fail'); glob.toastGeneric(2);
                    }
                    else {
                        dispatch({ type: Actions.SET_PERSONNELS, personnels: j });
                        tkAPICalls.last_getPersonnels = j;
                        resolve(j);
                    }
                });
            }
        });
    }
    static getPersonnelsCall = (shop) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/personnel/get?shop_id=` + shop?.id)
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }
    

    static savePersonnel = (shopID, p) => {
        if (0 < p?.id) {
            return tkAPICalls.updatePersonnelCall(shopID, p);
        }
        else {
            return tkAPICalls.createPersonnelCall(shopID, p);
        }
    }
    static updatePersonnelCall = (shopID, p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/personnel/update`
            , { method: 'PUT', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }
    static createPersonnelCall = (shopID, p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/personnel/create?shop_id=` + shopID
            , { method: 'POST', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }

    static personnelShopLinking = (shopID, gid) => {
        return tkAPICalls.personnelShopLinkingCall(shopID, gid);
    };
    static personnelShopLinkingCall = (shopID, gid) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/personnel/shop_linking?shop_id=${shopID}&gid=${gid}`
            , { method: 'PUT' }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(j);
            })
            .catch(e => resolve(null))
        });
    }


    

    

    // POLICIES ======================================================================================================================================================
    static last_getPolicies = null;
    static getPolicies = (shop, dispatch) => {
        return new Promise((resolve) => {
            // DEV
            if (__DEV__ && 0 < tkAPICalls.last_getPolicies?.length) {
                dispatch({ type: Actions.SET_POLICIES, policies: tkAPICalls.last_getPolicies });
                resolve(tkAPICalls.last_getPolicies);
            }
            else {
                // get fresh policies
                tkAPICalls.getPoliciesCall(shop).then(j => {
                    if (!j) {
                        T.l('getPoliciesCall fail'); glob.toastGeneric(3);
                    }
                    else {
                        let _policies = tkAPICalls._post_process_policies(shop, j);
                        dispatch({ type: Actions.SET_POLICIES, policies: _policies });
                        resolve(j);
                    }
                });
            }
        });
    }
    static _post_process_policies = (shop, policies) => {
        policies?.forEach(p => {
            p.pType = POLICY_TYPES[p.pType];
            p.arrivalDelta += shop.openTime;
            p.leaveDelta += shop.openTime;
            p._totHours = p.pType === STARTEND 
                ? (p.leaveDelta - p.arrivalDelta - p.breakHours)
                : (p.durationHours - p.breakHours);
        });
        policies = policies.sort(T.sort_by('name', false, (p) => p));
        return policies;
    }
    static getPoliciesCall = (shop) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/policy/get?shop_id=` + shop?.id)
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }


    static savePolicy = (cred, p) => {
        if (!p.arrivalDelta) { p.arrivalDelta = 0; }
        if (!p.leaveDelta) { p.leaveDelta = 0; }
        if (0 < p?.id) {
            return tkAPICalls.updatePolicyCall(p);
        }
        else {
            return tkAPICalls.createPolicyCall(cred, p);
        }
    }
    static updatePolicyCall = (p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/policy/update`
            , { method: 'PUT', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }
    static createPolicyCall = (cred, p) => {
        T.l('createPolicyCall', p);
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/policy/create?CredentialID=` + cred
            , { method: 'POST', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }


    

    

    // PERIODS ======================================================================================================================================================
    static last_getPeriods = null;
    static getPeriods = (shop, dispatch) => {
        return new Promise((resolve) => {
            // DEV
            if (__DEV__ && 0 < tkAPICalls.last_getPeriods?.length) {
                dispatch({ type: Actions.SET_PERIODS, periods: tkAPICalls.last_getPeriods });
                resolve(tkAPICalls.last_getPeriods);
            }
            else {
                // get fresh periods
                tkAPICalls.getPersonnels(shop, dispatch).then(personnels => {
                    tkAPICalls.getPolicies(shop, dispatch).then(policies => {
                        tkAPICalls.getPeriodsCall(shop).then(j => {
                            if (j == null) {
                                T.l('getPeriodsCall fail'); glob.toastGeneric(4);
                            }
                            else {
                                let _periods = tkAPICalls._post_process_periods(personnels, policies, shop, j);
                                dispatch({ type: Actions.SET_PERIODS, periods: _periods });
                                resolve(j);
                            }
                        });
                    });
                });
            }
        });
    }
    static _post_process_periods = (personnels, policies, shop, periods) => {
        if (!(0 < periods?.length)) { return []; }
// personnel_id: 1
// attendance_policies: null
// salary: 10000
// id: 1 // shop_id: 994 // endDate: "2021-03-07T00:00:00+07:00" // startDate: "2021-03-01T00:00:00+07:00"
        let grouped_periods = [];
        
        periods?.forEach(pe => {

            const _startDate = new Date(pe.startDate); pe.sort = _startDate.getTime(); pe.startDateStr = T.formatDDMMYYYY(_startDate);
            const _endDate = new Date(pe.endDate); pe.ended = _endDate.getFullYear() > 1; pe.endDateStr = pe.ended ? T.formatDDMMYYYY(_endDate) : null;

            pe.notYet = _startDate > new Date();
            
            pe.policies = policies.filter(p => pe.attendance_policies?.includes(p.id));

            let find_grouped = grouped_periods?.find(p => p.personnel_id == pe.personnel_id);
            if (!find_grouped) {
                let new_grouped = {
                    personnel_id: pe.personnel_id,
                    person: personnels.find(p => p.id == pe.personnel_id),
                    periods: [ pe ],
                    ended: pe.ended,
                    notYet: pe.notYet
                }
                grouped_periods.push(new_grouped);
            }
            else {
                find_grouped.periods.push(pe);
                if (find_grouped.ended && !pe.ended) {
                    find_grouped.ended = false;
                }
                if (!find_grouped.notYet && pe.notYet) {
                    find_grouped.notYet = true;
                }
            }
        });

        grouped_periods.forEach(gp => {
            gp.periods = gp.periods.sort(T.sort_by('sort', true, (p) => p));
            gp.sort = gp.person?.name;
        });
        grouped_periods = grouped_periods.sort(T.sort_by('sort', false, (p) => p));
        return grouped_periods;
    }
    static getPeriodsCall = (shop) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/employment_period/get?shop_id=` + shop?.id)
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }


    static savePeriod = (p) => {
        return tkAPICalls.createPeriodCall(p);
    }
    static createPeriodCall = (p) => {
        p.startDate = (p.startDate ?? new Date()).toISOString();
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/employment_period/create`
            , { method: 'POST', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res).then(res => {
                resolve(res.ok);
            })
            .catch(e => resolve(false))
        });
    }

    static endPeriod = (gps) => {
        let p = gps.periods?.find(gp => !gp.ended);
        return tkAPICalls.endPeriodCall(p);
    }
    static endPeriodCall = (p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/employment_period/end?id=${p?.id}&current_local_time=${new Date().toISOString()}`
            // , { method: 'PUT', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            , { method: 'PUT', body: JSON.stringify('nothing'), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => {
                resolve(res.ok);
            })
            .catch(e => resolve(false))
        });
    }


    
    static releaseDeposit = (id) => {
        return tkAPICalls.releaseDepositCall(id);
    }
    static releaseDepositCall = (id) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/employment_period/release_deposit?id=${id}&current_local_time=${new Date().toISOString()}`
            , { method: 'PUT', body: JSON.stringify('nothing'), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => {
                resolve(res.ok);
            })
            .catch(e => resolve(false))
        });
    }



    static xyzCall = (shop_id, personnel_id, month, day) => {
        if (month < 0 || day < 0) { return; }
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/attendance_record/get?shop_id=${shop_id}&personnel_id=${personnel_id}&month=${month}&day=${day}`)
            .then(res => {
                res.json().then(js => {
                    js.forEach(j => {
                        j.time = new Date(j.time)
                    });
                    T.l('xyzCall', js);
                    resolve(res.ok);
                })
            })
            .catch(e => resolve(false))
        });
    }

    
    







    // LEAVES ======================================================================================================================================================
    static last_getLeaves = null;
    static getLeaves = (shop, dispatch) => {
        return new Promise((resolve) => {
            // DEV
            if (false && __DEV__ && 0 < tkAPICalls.last_getLeaves?.length) {
                dispatch({ type: Actions.SET_LEAVES, leaves: tkAPICalls.last_getLeaves });
                resolve(tkAPICalls.last_getLeaves);
            }
            else {
                // get fresh leaves
                tkAPICalls.getPersonnels(shop, dispatch).then(personnels => {
                    tkAPICalls.getLeavesCall(shop).then(j => {
                        let rs = tkAPICalls._post_process_leaves(j, personnels);
                        dispatch({ type: Actions.SET_LEAVES, leaves: rs ?? [] });
                        tkAPICalls.last_getLeaves = rs ?? [];
                        resolve(rs ?? []);
                    });
                });
            }
        });
    }
    static _post_process_leaves = (ls, personnels) => {
        /* _personnels dic */ const _personnels = {}; personnels.forEach(p => { _personnels[p.id] = p; });

        ls.forEach(l => {
            const personnel = _personnels[l.personnel_id];
            l.personnel = personnel;
            l.date = tkAPICalls.de_condense_date(l.dateKey);
        });
        return ls;
    }
    static getLeavesCall = (shop, personnel_id, range, date, orgRange) => {
        return new Promise((resolve) => {
            let surfix = personnel_id > 0 ? `&personnel_id=${personnel_id}` : '';
            surfix += range ? `&range=${range}` : date ? `&date=${date}` : '';
            let _url = `/leaves/get?shop_id=${shop?.id}` + surfix;
            T.l('_url', orgRange, _url);
            _url = TKURL() + _url;
            mfetch.do(_url)
            .then(res => res.json()).then(j => {
                resolve(j);
            })
            .catch(e => resolve({}))
        });
    }
    

    static saveLeave = (p) => {
        if (0 < p?.id) {
            return tkAPICalls.updateLeaveCall(p);
        }
        else {
            return tkAPICalls.createLeaveCall(p);
        }
    }
    static updateLeaveCall = (p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/leaves/update`
            , { method: 'PUT', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }
    static createLeaveCall = (p) => {
        return new Promise((resolve) => {
            mfetch.do(TKURL() + `/leaves/create?`
            , { method: 'POST', body: JSON.stringify(p), headers: { 'Content-type': 'application/json; charset=UTF-8' } }
            )
            .then(res => res.ok ? res.json() : null).then(j => {
                resolve(!!j);
            })
            .catch(e => resolve(false))
        });
    }
}


