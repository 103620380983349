import { IndexPath } from '@ui-kitten/components';

export const STARTEND = 'STARTEND';
export const DURATION = 'DURATION';

export const POLICY_TYPES = {
    0: STARTEND,
    1: DURATION,
}

export const POLICY_TYPES_to_int = (str) => {
    switch (str) {
        case STARTEND: return 0;
        case DURATION: return 1;
        default: return 0;    
    }
}

export const POLICY_TYPES_to_IndexPath = (str) => {
    return new IndexPath(POLICY_TYPES_to_int(str));
}