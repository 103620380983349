import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import { PERSON_ICON } from '../consts/gst';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MIcons from '../MComponents/MIcons';
import MText from '../MComponents/MText';
import MTopBar from '../MComponents/MTopBar';
import MTouchable from '../MComponents/MTouchable';
import MView from '../MComponents/MView';
import RangePicker, { findTit, RANGE_MODE } from '../MComponents/RangePicker';
import { Actions, store } from '../store';
import T from '../T';
import ReportScreen_PersonView from './ReportScreen_PersonView';
import { getHISTORY } from '../consts/HISTORY';

export const ReportScreenItem_BL_Portrait = (props) => {
    const pr = props;
    const data = pr.data;
    const person = data?.person;
    const ov = data?.overview;
    const onSelect = pr.onSelect;

    const _img = tkBioPhotoUrl(person?.id);

    const _real_earning = (ov?._earning ?? 0) + (ov?._allowanceDaily ?? 0);
    
    return (
      <MTouchable bg_white padV={11} /* onPress={() => onSelect(data)} */
        onLongPress={() => {
            T.l('data', data);
        }} 
      >
        <MView padV={11} style={{ borderBottomWidth: 1, borderColor: '#eee' }}>
            <MView row>
                <ImageLoader source={_img} fallback={PERSON_ICON} style={{ width: 48, height: 48, borderRadius: 24, marginEnd: 10 }} />
                <MView grow={1} >


                    <MText nd bold txt={person?.name} />

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Có mặt: '} c_555 reverse_children >
                            <MText nd bold accent={ov._attHoursExcess} txt={ov?._attHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Được phân công: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._totHours?.toFixed(1) + ' h'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Ghi nhận: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._regHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Đi làm: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._regDays + '/' + ov?._totDays + ' ngày'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Thu nhập: '} c_555 reverse_children >
                            <MText nd bold txt={ Number(ov?._earning?.toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Phụ cấp: '} c_555 reverse_children >
                            <MText nd bold txt={ Number(ov?._allowanceDaily?.toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                    </MView>


                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Thực lĩnh: '} c_555 reverse_children >
                            <MText nd bold txt={ Number(_real_earning.toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                        <MView grow={1} />
                    </MView>


                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Tạm ứng: '} c_555 reverse_children >
                            <MText nd orange bold txt={ Number(ov?._allowanceMonthly?.toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Còn lại: '} c_555 reverse_children >
                            <MText nd accent bold txt={ Number((_real_earning - (ov?._allowanceMonthly ?? 0)).toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                    </MView>


                </MView>
            </MView>
        </MView>
      </MTouchable>
    );
}