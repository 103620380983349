
import React from 'react';
import { TouchableOpacity, TouchableWithoutFeedback, View, ViewPropTypes } from 'react-native';
import PropTypes from 'prop-types';
import MView, { findAlignItems, findAlignSelf, findColor, findColorBG, findJustifyContent, findShadow, setMargin, setPadding } from './MView';
import MText from './MText';
import T from '../T';
import ahaAPICalls from '../APICalls/ahaAPICalls';



const MTouchable = (props) => {

    const pr = props;
    if (pr.gone) return null;

  

    const _render = () => {
        const cc = findColor(pr);
        const bg = findColorBG(pr);
        const jc = findJustifyContent(pr);
        const ai = findAlignItems(pr);
        const as = findAlignSelf(pr);
        const shadow = findShadow({ shadow: pr.disabled ? 0 : pr.shadow });
    
        return (
          <TouchableWithoutFeedback
            onPress={(e) => {
              ahaAPICalls.checkCred();
              if (!pr.disabled) { pr.onPress?.(e); 
              //e.nativeEvent.pageX 
            } }}
            onLongPress={(e) => { pr.onLongPress?.(); }}
          >
            <MView { ...pr }
              style={[ (pr.style ?? {  })
                      
                    , { 
                          color: cc, backgroundColor: bg
                        , flexDirection: pr.row ? 'row' : pr.col ? 'column' : undefined
                        , justifyContent: jc
                        , alignItems: ai, alignSelf: as
                        , paddingHorizontal: pr.padX, borderRadius: pr.borRad
                        // , marginTop: pr.maTop, marginStart: pr.maStart, marginEnd: pr.maEnd, marginBottom: pr.maBot, marginVertical: pr.maV, marginHorizontal: pr.maH
                        // , paddingTop: pr.padTop, paddingStart: pr.padStart, paddingEnd: pr.padEnd, paddingBottom: pr.padBot, paddingVertical: pr.padV
                        , width: pr.fill_100 || pr.w_100 ? '100%' : pr.wh ? pr.wh : pr.w
                        , height: pr.fill_100 || pr.h_100 ? '100%' : pr.wh ? pr.wh : pr.h
                        , opacity: pr.opacity ?? pr.style?.opacity
                        , flexGrow: pr.grow ?? pr.style?.flexGrow, flex: pr.flex ?? pr.style?.flex
                        , position: pr.absolute ? 'absolute' : pr.style?.position
                      }
                    , { cursor: 'pointer' }
                    , setMargin(pr)
                    , setPadding(pr)
                    , shadow
                    
                ]}
              >
      
              { 
                !pr.textRevertOrder || (typeof pr.text?.txt !== 'string' || T.isNullOrEmpty(pr.text?.txt) )
                  ? null
                  : <MText { ...pr.text } />
              }
              {
                pr.children
              }
              { 
                pr.textRevertOrder || (typeof pr.text?.txt !== 'string' || T.isNullOrEmpty(pr.text?.txt) )
                  ? null
                  : <MText { ...pr.text } />
              }
      
            </MView>
            
          </TouchableWithoutFeedback>
        );
      };


      if (pr.shadowBot) {
        return (
        <MView row >
          {_render()}
          {/** *//* <MShadowBot zIndex={pr.zIndex ?? pr.style?.zIndex} /> */}
        </MView>
        );
      }
  
      return _render();
}
export default MTouchable;

MTouchable.propTypes = {
    ...MView.propTypes 
    , disabled: PropTypes.bool
    , text: PropTypes.any
    , textRevertOrder: PropTypes.bool
    , onPress: PropTypes.func
    , onLongPress: PropTypes.func
}


