
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T, { localeDateService } from '../T';
import MIcons from '../MComponents/MIcons';
import tkAPICalls from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import MText from '../MComponents/MText';
import { INT_ARRAY } from '../consts/TypeChecking';
import { PersonnelItem } from './PersonnelsScreen';
import PersonnelPicker from '../MComponents/PersonnelPicker';
import { FlatGrid } from 'react-native-super-grid';
import { PolicyItem } from './AttendancePoliciesScreen';
import PolicyPicker from '../MComponents/PolicyPicker';
import { Datepicker, Input } from '@ui-kitten/components';

    
const ADD_NEW_POL = { isAddNew: true }

const parseProps = (props, dispatch, setPerson, setPolicies, setSalary, setDayAllowance, setRole
    , setDeposit, setDepoRelease, setStartDate, setOrgStartDate, setLocked, setEnded) => {
  if (props.curPeriod) {

    const editingPeriod = props.curPeriod?.periods?.[0];
    const person = props.curPeriod?.person;
    const personnel_id = props.curPeriod?.personnel_id;
    
    setPerson(person);
    setPolicies([ ...(editingPeriod?.policies ?? []), ADD_NEW_POL ]);
    setSalary(editingPeriod?.salary);
    setDayAllowance(editingPeriod?.dayAllowance);
    setRole(editingPeriod?.role);
    setDeposit(editingPeriod?.deposit);
    setDepoRelease(new Date(editingPeriod?.depositRelease));
    
    const _orgStartDate = editingPeriod?.startDate ? new Date(editingPeriod?.startDate) : T.getTomorow();
    setStartDate(_orgStartDate);
    setOrgStartDate(_orgStartDate);

    setLocked(0 < personnel_id);
    setEnded(editingPeriod?.ended === true)
    document.title = person?.name ?? TITLE;
  }
  else {
    document.title = TITLE;
  }
}


const savePeriod = (state, dispatch, onCloseEditor, person, policies, salary, dayAllowance, role, deposit, startDate) => {

  if (!person) { glob.toast('Chưa chọn nhân sự'); return; }
  if (!(1 < policies?.length)) { glob.toast('Chưa chọn ca làm việc'); return; }
  // if (!(0 < salary)) { glob.toast('Chưa đặt mức lương'); return; }

  dispatch({ type: Actions.SET_BUSY, busy: true });
  tkAPICalls.savePeriod({
        personnel_id: person.id,
        shop_id: state.shop.id,
        attendance_policies: policies.filter(p => !p.isAddNew).map(p => p.id),
        salary: salary, dayAllowance, role, deposit: deposit, startDate: startDate
      }
  )
  .then(ok => {
    dispatch({ type: Actions.SET_BUSY, busy: false });    
    if (ok) {
      glob.toast('Đã lưu');
      onCloseEditor({ saved: true });
    }
    else {
      glob.toast('Lưu không thành công');
    }
  });
}


const releaseDeposit = (props, dispatch, person, onCloseEditor) => {

  dispatch({ type: Actions.SET_BUSY, busy: true });

  const editingPeriod = props.curPeriod?.periods?.[0];
  if (!editingPeriod) { T.l('!editingPeriod'); return; } // T.l('editingPeriod', {editingPeriod, person})
  glob.prompt(-1, 'Trả tiền đặt cọc',
    `Trả ${editingPeriod?.deposit} cho ${person?.name ?? '...'}?`
    , () => { 
      tkAPICalls.releaseDeposit(editingPeriod?.id).then(ok => {
        dispatch({ type: Actions.SET_BUSY, busy: false });  
        if (ok) {
          glob.toast('Đã lưu');
          onCloseEditor({ saved: true });
        }
        else {
          glob.toast('Lưu không thành công');
        }
      });
    }
    , null
    , [ { mood: 0, tit: 'không' } ]
    , () => dispatch({ type: Actions.SET_BUSY, busy: false })
    );
}


const TITLE = 'Tạo biên chế';
const EmploymentPeriodsScreen_Editor = (props) => {
    const { state, dispatch } = useContext(store);
    
    const [locked, setLocked] = useState(true);
    const [ended, setEnded] = useState(false);

    const [salary, setSalary] = useState(0);
    const [role, setRole] = useState('');
    const [dayAllowance, setDayAllowance] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [depoRelease, setDepoRelease] = useState(null);

    const [person, setPerson] = useState(null);
    const [showPickPerson, setShowPickPerson] = useState(false);

    const [policies, setPolicies] = useState([ ADD_NEW_POL ]);
    const [showPickPolicy, setShowPickPolicy] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [orgStartDate, setOrgStartDate] = useState(new Date());

    useEffect(() => { parseProps(props, dispatch, setPerson, setPolicies, setSalary, setDayAllowance, setRole
        , setDeposit, setDepoRelease, setStartDate, setOrgStartDate
        , setLocked, setEnded); }
    , []);

    const onCloseEditor = props.onCloseEditor;
    const onSelectSetEnd = props.onSelectSetEnd;
    const curPeriod = props.curPeriod;

    const pickedPerson = (p) => {
      setPerson(p); setShowPickPerson(false); window.scrollTo(0, 0);
    }
    const pickedPolicy = (po) => {
        var _policies = [ ...(policies.filter(p => !p.isAddNew)), po, ADD_NEW_POL ];
        setPolicies(_policies); setShowPickPolicy(false); window.scrollTo(0, 0);
    }
    const removePo = (po) => {
      var _policies = [ ...(policies.filter(p => p.id !== po.id)) ];
      setPolicies(_policies);
    }

    const reAssign = (startReAssign) => {
      setStartDate( startReAssign ? T.getTomorow() : orgStartDate);
      setLocked(!startReAssign);
    }

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">

        { showPickPolicy || showPickPerson ? null : <MTopBar zx={props.zx} pa={'EmploymentPeriodsScreen_Editor'} title={(0 < curPeriod?.personnel_id ? (curPeriod?.person?.name ?? '') : 'Tạo biên chế') }
          onGoBack={() => onCloseEditor()} />}

        {/* FORM */}
        <MView w_100 jc_cen row >
          <MView padH={22} h={showPickPolicy || showPickPerson ? 1 : undefined} 
            style={{ maxWidth: 777 }} grow={1}
            >
            
            { /* PERSON */
            <MView pad={0}>
              <MText bold tind fontScale={0.75} txt={locked ? 'Nhân sự' : 'Chọn nhân sự'} color='#8f9bb3' maV={4} />
              <PersonnelItem data={person} width={222} locked={locked}
                onSelect={(e, p) => { setShowPickPerson(true); window.scrollTo(0, 0); }} 
                customCornerIcon={() => <MIcons ent name='chevron-down' c_999 size={16} /> }             
                />
            </MView>
            }
            

            <MText bold tind fontScale={0.75} txt='Ca làm việc' color='#8f9bb3' maTop={9} maBot={-2} />
            { /* POLICIES */
              <FlatGrid 
                style={{  marginHorizontal: -11 }}
                data={!locked ? policies : (policies ?? []).filter(p => !p.isAddNew )  }
                itemDimension={180}
                renderItem={({ item }) => <PolicyItem data={item} shop={state.shop} locked={locked}
                                            onSelect={(e, po) => { if (po.isAddNew) { setShowPickPolicy(true); window.scrollTo(0, 0); } }} 
                                            onEditItem={po => removePo(po)}
                                            customCornerIcon={() => <MIcons feather name='x' c_999 size={16} />}
                                            /> }
                />
            }


            <MView row w_100>
              <MView w='49%'>
                <MText bold tind fontScale={0.75} txt='Lương / giờ' color='#8f9bb3' maTop={9} maBot={5} />
                {/* SALARY */
                <MView bg={locked ? '#f7f9fc' : 'white'} h={44} borRad={5} >
                  <Input keyboardType='number-pad' disabled={locked}
                    value={salary === 0 ? '' : T.numberToDigitGroupedString(salary)} 
                    onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setSalary(Number.isNaN(_s) ? 0 : _s); }} 
                    textStyle={ locked ? { color: 'black' } : null}
                    style={
                      locked ? null
                      : [styles.input, { borderColor: '#00000000', backgroundColor: '#00000000' }]
                    } />
                </MView> }
              </MView>
              <MView w='2%'/>
              <MView w='49%'>
                <MText bold tind fontScale={0.75} txt='Phụ cấp ăn / ngày' color='#8f9bb3' maTop={9} maBot={5} />
                {/* DAY ALLOWANCE */
                <MView bg={locked ? '#f7f9fc' : 'white'} h={44} borRad={5} >
                  <Input keyboardType='number-pad' disabled={locked}
                    value={dayAllowance === 0 ? '' : T.numberToDigitGroupedString(dayAllowance)} 
                    onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setDayAllowance(Number.isNaN(_s) ? 0 : _s); }} 
                    textStyle={ locked ? { color: 'black' } : null}
                    style={
                      locked ? null
                      : [styles.input, { borderColor: '#00000000', backgroundColor: '#00000000' }]
                    } />
                </MView> }
              </MView>
            </MView>


            <MView h={11} />

            <MView row w_100>
              <MView w='49%'>
                <MText bold tind fontScale={0.75} txt='Ngày bắt đầu' color='#8f9bb3' maTop={9} maBot={5} />
                {/* START DATE */
                <MView bg_white={!locked} borRad={5} h={44} style={locked ? { borderWidth: 1, borderColor: '#e4e9f2' } : null} >
                  <MView absolute fill_100 jc_cen padStart={10} >
                    <MText nd txt={T.formatDDMMYYYY(startDate)} maEnd={glob.ww * 0.02} />
                  </MView>
                  <Datepicker disabled={locked}
                      dateService={localeDateService}
                      date={startDate} style={{ opacity: 0.0, flexGrow: 1 }}
                      onSelect={_date => { if (_date) { setStartDate(_date); } }} />
                </MView> }
              </MView>
              <MView w='2%'/>
              <MView w='49%'>
                <MText bold tind fontScale={0.75} txt='Vai trò' color='#8f9bb3' maTop={9} maBot={5} />
                {/* ROLE */
                <MView bg={locked ? '#f7f9fc' : 'white'} h={44} borRad={5} >                  
                  {/* <Input value={role} onChangeText={(str) => setRole(str)}  style={styles.input} /> */}
                  <Input disabled={locked} textContentType='username' autoCompleteType='username' keyboardType='name-phone-pad'
                    value={role} onChangeText={(str) => setRole(str)}
                    textStyle={ locked ? { color: 'black' } : null}
                    style={
                      locked ? null
                      : [styles.input, { borderColor: '#00000000', backgroundColor: '#00000000' }]
                    } />
                </MView> }
              </MView>
            </MView>


            <MView h={11} />

            <MView row w_100>
              <MView w='49%'>
                <MText bold tind fontScale={0.75} txt='Đặt cọc' color='#8f9bb3' maTop={9} maBot={5} />
                {/* DEPOSIT */
                <MView bg={locked ? '#f7f9fc' : 'white'} h={44} borRad={5} >
                  <Input keyboardType='number-pad' disabled={locked}
                    value={deposit === 0 ? '' : T.numberToDigitGroupedString(deposit)} 
                    onChangeText={(str) => { const _s = Number.parseFloat(T.replaceAll(str, ',', '')); setDeposit(Number.isNaN(_s) ? 0 : _s); }}
                    textStyle={ locked ? { color: 'black' } : null}
                    style={
                      locked ? null
                      : [styles.input, { borderColor: '#00000000', backgroundColor: '#00000000' }]
                    } />
                </MView> }
              </MView>
              <MView w='2%'/>
              <MView w='49%' >
                <MText bold tind fontScale={0.75} txt={'Ngày trả đặt cọc'} color='#8f9bb3' maTop={9} maBot={5} />
                {/* DEPOSIT RELEASE */
                <MView bg_white={false} borRad={5} h={44} style={{ borderWidth: 1, borderColor: '#e4e9f2' }} >
                  <MView absolute fill_100 jc_cen padStart={10} >
                    <MText gone={!locked || !(depoRelease?.getFullYear() > 1)} nd txt={T.formatDDMMYYYY(depoRelease)} maEnd={glob.ww * 0.02} />
                  </MView>
                </MView> }                     
              </MView>
            </MView>
            

            {/* BUTTONS */}
            { !(0 < curPeriod?.personnel_id) ? null :
              <MView>
                {/* Cho nghỉ */
                <MTouchable gone={!locked || ended} padH={20} h={40} maTop={22} bg='#D02589' borRad={4} row all_cen
                  text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Cho nghỉ' }} 
                  onPress={() => onSelectSetEnd()} >
                  <MIcons octi white name="stop" size={19} />
                </MTouchable> }
                {/* Phân công lại công việc */
                <MTouchable gone={!locked} padH={20} h={40} maTop={22} bg='#2586D0' borRad={4} row all_cen
                  text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Phân công lại công việc' }} 
                  onPress={() => reAssign(true)} >
                  <MIcons matCom white name="table-clock" size={19} />
                </MTouchable> }
                {/* Trả tiền đặt cọc */
                <MTouchable gone={!locked || deposit <= 0 || depoRelease?.getFullYear() > 1 } padH={20} h={40} maTop={22} bg='#2586D0' borRad={4} row all_cen
                  text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Trả tiền đặt cọc' }} 
                  onPress={() => releaseDeposit(props, dispatch, person, onCloseEditor)} >
                  <MIcons ent white name="reply" size={19} />
                </MTouchable> }
              </MView>
            }
            <MView row maTop={33} >
              <MTouchable w='49%' padH={20} h={40} bg='#ff902b' borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'QUAY LẠI' }} 
                onPress={() => { if (!locked) { reAssign(false); } else { onCloseEditor(); } }} >
                <MIcons feather white name="x" size={19} />
              </MTouchable>
              <MView w='2%' />
              <MTouchable w='49%' padH={20} h={40} bg_accent borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'LƯU' }} 
                onPress={() => savePeriod(state, dispatch, onCloseEditor, person, policies, salary, dayAllowance, role, deposit, startDate)} >
                <MIcons mat white name="done" size={19} />
              </MTouchable>
            </MView>

          </MView>
        </MView>

        
        
        { !showPickPerson ? null :
          <PersonnelPicker 
            zx={props.zx + 1}  
            onPicked={p => pickedPerson(p)}
            onClosePicker={() => { setShowPickPerson(false); window.scrollTo(0, 0); }} />
        }

        { !showPickPolicy ? null :
          <PolicyPicker 
            zx={props.zx + 1} 
            onPicked={p => pickedPolicy(p)}
            onClosePicker={() => { setShowPickPolicy(false); window.scrollTo(0, 0); }} />
        }
        
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e159', e); glob.toast('Mở không thành công');
      onCloseEditor();
      return null;
    }
}
export default EmploymentPeriodsScreen_Editor;

EmploymentPeriodsScreen_Editor.propTypes = {
  onCloseEditor: PropTypes.func,
  onSelectSetEnd: PropTypes.func,
  zx: PropTypes.number,
  curPeriod: PropTypes.shape({
    id: PropTypes.number,
    personnel_id: PropTypes.number,
    person: PropTypes.shape({
      name: PropTypes.string
    }),
    shop_id: PropTypes.number,
    attendance_policies: PropTypes.arrayOf(INT_ARRAY),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    salary: PropTypes.number,
    deposit: PropTypes.number,
  }),
};

/*
id: 1,
personnel_id: 1,
shop_id: 994,
attendance_policies: null, is array 
startDate: "2021-03-01T00:00:00+07:00",
endDate: "2021-03-07T00:00:00+07:00",
salary: 10000
deposit: 100000
*/

const styles = StyleSheet.create({
  img: { width: 128, height: 128, borderRadius: 64, marginEnd: 20 },
  input: {
    marginBottom: 10,
  },
});
