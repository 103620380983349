import { StyleSheet } from 'react-native';



console.log(" ------------------------------------------------------------------------------------------ " + "gst");


export default class gst {
    
    static accent = '#00a053';
    static accent_dark = '#07783d';
    static accent2 = '#F1C40F';
    static appbg = '#F7F5FA';
    static appbg2 = '#6F6F6F';
    static NEGATIVE_COLOR = "#D20047";
    static POSITIVE_COLOR = '#109f54';
    static NEUTRAL_COLOR = "#777";

}

export const accent = gst.accent;
export const accent_dark = gst.accent_dark;
export const accent2 = gst.accent2;
export const appbg = gst.appbg;
export const appbg2 = gst.appbg2;
export const NEGATIVE_COLOR = gst.NEGATIVE_COLOR;
export const POSITIVE_COLOR = gst.POSITIVE_COLOR;
export const NEUTRAL_COLOR = gst.NEUTRAL_COLOR;

export const PERSON_ICON = 'https://app.ahacafe.vn/person_icon.png';