
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import { Actions, store } from '../store';
import T from '../T';
import { Button, Input } from '@ui-kitten/components';
import MIcons from '../MComponents/MIcons';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import { accent, PERSON_ICON } from '../consts/gst';
import MText from '../MComponents/MText';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DURATION, STARTEND } from '../consts/POLICY_TYPES';
import Time_Picker from '../MComponents/Time_Picker';
import MTopBar from '../MComponents/MTopBar';

const parseProps = (props, dispatch, onSetArrivedLeft, setOrgArrived, setOrgLeft, close) => {
  T.l('ReportScreen_PersonView_Editor props.basedData', props.basedData)
  const _o = props.basedData?.data?.closingDatas?.[props.basedData.idx];
  if (!_o) { props.close(); return; }
  onSetArrivedLeft(_o.arrived, _o.left);
  setOrgArrived(_o.arrived); setOrgLeft(_o.left);
}

const ReportScreen_PersonView_Editor = (props) => {
    const { state, dispatch } = useContext(store);

    const [arrived, setArrived] = useState(0);
    const [left, setLeft] = useState(0);

    const [orgArrived, setOrgArrived] = useState(0);
    const [orgLeft, setOrgLeft] = useState(0);

    const [totHour, setTotHour] = useState(0);
  
    const onSetArrivedLeft = (_a, _l) => {
      setArrived(_a); setLeft(_l); setTotHour(_l - _a);
    }
    // const onSetTotHours = (t) => {
    //   if ((arrived + t) > 24) { return; }
    //   setTotHour(t); setLeft(arrived + t);
    // }

    useEffect(() => { parseProps(props, dispatch, onSetArrivedLeft, setOrgArrived, setOrgLeft, props.close); }
    , []);

    const onCloseEditor = props.onCloseEditor;
    const _basedData = props.basedData;
    const curPerson = props.curPerson;
    const _img = curPerson ? tkBioPhotoUrl(curPerson?.id) : null;

    const _render = () => (
      <MView fill_100 bg_white >
        <MTopBar zx={props.zx} pa={'ReportScreen_PersonView_Editor'} title={'Thay đổi chốt công'} 
          onGoBack={() => props.close()} />        
        
  
        <MView fill_100 bg_white padH={22} >
  
  
          <MView row maTop={22} ai_cen >
            <ImageLoader gone={!_img} source={_img} fallback={PERSON_ICON} style={{ width: 36, height: 36, borderRadius: 18, marginEnd: 10 }} />
            <MView>
              <MText nd color='#8f9bb3' txt={curPerson?.name} />
              <MText gone={T.isNullOrEmpty( _basedData?.data?.date )} tind color='#8f9bb3' txt={T.formatDDMMYYYY(new Date(_basedData?.data?.date))} />
            </MView>
          </MView>
  
  
          <MText bold tito maTop={22} fontScale={0.75} txt='GỐC:' />
          <ReportItemSummary maH={-11} data={_basedData.data} dataIdx={_basedData.idx} /> 
          <ReportItemAdjusted maH={-11} data={_basedData.data} dataIdx={_basedData.idx} /> 
  
  
  
          <MText bold tito maTop={22} fontScale={0.75} txt='THAY ĐỔI:' />
          <MView row maBot={20} maTop={11} >
            <MView grow={1} >
              <MText bold tind fontScale={0.75} txt='Giờ đến' color='#8f9bb3' />
              <MView borRad={5} maTop={11} style={ glob.isMobile ? null : { borderWidth: 1, borderColor: '#ccc' }} >
                <Time_Picker value={arrived} min={orgArrived} max={orgLeft} onChange={a => { onSetArrivedLeft(a, left); }} />
              </MView>
            </MView>
            <MView w={10} />
            <MView grow={1}>
              <MText bold tind fontScale={0.75} txt={'Giờ về'} color='#8f9bb3' />
              <MView borRad={5} maTop={11} style={ glob.isMobile ? null : { borderWidth: 1, borderColor: '#ccc' }} >
                <Time_Picker print value={left} min={orgArrived} max={orgLeft} onChange={l => onSetArrivedLeft(arrived, l)} />
              </MView>
            </MView>
          </MView>
  
  
          <MText w_100 ta_cen maTop={11} bold tind fontScale={0.75} txt='Tổng giờ ghi nhận' color='#8f9bb3' />  
  
          <MView row ai_cen maTop={15} borRad={5} padV={11}
              // style={{ borderWidth: 1, borderColor: '#ccc' }} 
            >
            {/* <MTouchable grow={1} all_cen onPress={() => onSetTotHours(Math.max(totHour - 1, 0))} >
              <MIcons feather name='minus-circle' accent size={25} />
            </MTouchable> */}
            <MText grow={1} ta_cen tito txt={`${totHour?.toFixed(1)} giờ`} />
            {/* <MTouchable grow={1} all_cen onPress={() => onSetTotHours(Math.min(totHour + 1, 16))} >
              <MIcons feather name='plus-circle' accent size={25} />
            </MTouchable> */}
          </MView>
  
  
          <MView row maTop={77} >
            <MTouchable w='49%' padH={20} h={40} bg='#ff902b' borRad={4} row all_cen
              text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'QUAY LẠI' }} 
              onPress={() => props.close()} >
              <MIcons feather white name="x" size={19} />
            </MTouchable>
            <MView w='2%' />
            <MTouchable w='49%' padH={20} h={40} bg_accent borRad={4} row all_cen
              text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'LƯU' }} 
              onPress={() => props.onSave(_basedData, arrived, left)} >
              <MIcons mat white name="done" size={19} />
            </MTouchable>
          </MView>
  
        </MView>
      
        <MView h={111} />
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e110', e); glob.toast('Mở không thành công');
      return null;
    }
}
export default ReportScreen_PersonView_Editor;
ReportScreen_PersonView_Editor.propTypes = {
  close: PropTypes.func,
  zx: PropTypes.number,
  onSave: PropTypes.func,
  basedData: PropTypes.any,
  curPerson: PropTypes.any,
};




const ReportItemAdjusted = (props) => {
  const pr = props;
  const data = pr.data;
  const aClo = data?.adjustedClosingDatas?.[pr.dataIdx];
  if (!aClo) {
    return null;
  }
  
  const cred = pr.cred;

  const pol = data?.policyDatas?.[pr.dataIdx];
  const clo = data?.closingDatas?.[pr.dataIdx];

  const isSTARTEND = pol.pType === STARTEND;
  const isDURATION = pol.pType === DURATION;

  const showRegHours = clo.arrived > 0 && clo.left > 0;

  return (
      <div onClick={() => { T.l(`${cred}|isAdmin${glob.isAdmin}`, data.org); }} >
        
        <MView pad={11} maH={pr.maH} bg_white >
          <MView>
            <MView >

              <MView row ai_cen  maBot={4} >
                <MText nd bold color='#ff902b' txt={`Chỉnh sửa bởi ${aClo.updater}`} />
                <MView grow={1} />
                <MText nd bold color='#ff902b' txt={`${T.formatDDMMYY(aClo.updatedAt)}`} />
              </MView>
              
              <MView row ai_cen >
                <MText cur c_999 txt={'Đến : '} reverse_children >
                  <MText nd color='#ff902b' txt={aClo.arrived?.toFixed(1)} />
                </MText>
                <MView w={22} h_100 ai_cen >
                  <MView w={1} h={22} bg_eee />
                </MView>
                <MText cur c_999 txt='Về : ' reverse_children >
                  <MText nd color='#ff902b' txt={aClo.left?.toFixed(1)} />
                </MText>

                <MView grow={1} />
                <MText nd c_999 txt='Ghi nhận: ' reverse_children >
                  <MText nd bold color='#ff902b' txt={(clo._regHours ?? 0).toFixed(1) + 'h'} />
                </MText>

              </MView>
              
            </MView>
            
          </MView>
        </MView>
      
      </div>

  );
}

const ReportItemSummary = (props) => {
  const pr = props;
  const data = pr.data;
  const cred = pr.cred;

  const pol = data?.policyDatas?.[pr.dataIdx];
  const clo = data?.closingDatas?.[pr.dataIdx];

  const isSLate = 0 < clo.startLate;
  const isLEarly = 0 < clo.leaveEarly;

  const isSTARTEND = pol.pType === STARTEND;
  const isDURATION = pol.pType === DURATION;

  const showRegHours = clo.arrived > 0 && clo.left > 0;

  return (
      <div onClick={() => { T.l(`${cred}|isAdmin${glob.isAdmin}`, data.org); }} >
        
        <MView pad={11} maH={pr.maH} bg_white >
          <MView>
            <MView >

              <MView row ai_cen  maBot={4} >

                <MText nd bold c_999 txt={pol?.name} />

                <MView grow={1} />
                <MText gone={!isSTARTEND} nd c_999 txt={pol.startTime} />
                <MIcons gone={!isSTARTEND} ent name='chevron-right' size={13} c_999 maTop={4} />
                <MText gone={!isSTARTEND} nd c_999 txt={pol.endTime} />
                <MText gone={!isSTARTEND} nd c_999 txt={` (${pol._totHours}h)`} />
                
                <MText gone={!isDURATION} nd c_999 txt={`phân công ${pol._totHours}h`} />
              </MView>
              
              <MView row ai_cen >
                <MText gone={!(clo.arrived > 0)} cur c_999 txt={'Đến : '} reverse_children >
                  <MText nd txt={clo._arrivedTime} />
                </MText>
                <MView w={22} h_100 ai_cen >
                  <MView w={1} h={22} bg_eee />
                </MView>
                <MText gone={!(clo.left > 0)} cur c_999 txt='Về : ' reverse_children >
                  <MText nd txt={clo._leftTime} />
                </MText>

                <MView grow={1} />
                <MText gone={!showRegHours} nd c_999 txt='Có mặt: ' reverse_children >
                  <MText nd bold accent={clo._attHoursExcess} txt={(clo._attHours ?? 0).toFixed(1) + 'h'} />
                </MText>

              </MView>

              <MView row ai_cen >
                <MView gone={!isSTARTEND} row ai_cen >
                  <MText opacity={isSLate ? 1 : 0.3} cur c_999 txt={'Muộn: '} reverse_children >
                    <MText nd orange={isSLate} txt={T.floatToTimeStr(clo.startLate, true)} />
                  </MText>
                  <MView w={22} ai_cen >
                    <MView w={1} h={22} bg_eee />
                  </MView>
                  <MText opacity={isLEarly ? 1 : 0.3} cur c_999 txt='Sớm: ' reverse_children >
                    <MText nd orange={isLEarly} txt={T.floatToTimeStr(clo.leaveEarly, true)} />
                  </MText>
                </MView>

                <MView grow={1} />
                <MText gone={!showRegHours} nd c_999 txt='Ghi nhận: ' reverse_children >
                  <MText nd bold txt={(clo._regHoursOrg ?? 0).toFixed(1) + 'h'} />
                </MText>
              </MView>
              
            </MView>
            
          </MView>
        </MView>
      
      </div>

  );
}