// import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useReducer } from 'react';
import glob from './glob';
import T from './T';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

const Actions = {
    SET_BUSY: 'SET_BUSY', SET_PARAMS: 'SET_PARAMS',
    SET_SHOP: 'SET_SHOP', SET_SHOPS: 'SET_SHOPS',
    SET_PERSONNELS: 'SET_PERSONNELS', SET_POLICIES: 'SET_POLICIES', SET_PERIODS: 'SET_PERIODS',
    SET_LEAVES: 'SET_LEAVES'
}

// eslint-disable-next-line react/prop-types
const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        let newState = state;

        // T.l('action', action)

        switch (action.type) {
            // { cred, shopID, userName, userId, path, params }
            case Actions.SET_PARAMS:     
                newState = { ...state, cred: action.cred, shopID: action.shopID
                        , userName: action.userName, userId: action.userId
                        , path: action.path, params: action.params 
                    }; 
                break;
            case Actions.SET_SHOP:       newState = { ...state, shop: action.shop }; break;
            case Actions.SET_BUSY:       newState = { ...state, busy: action.busy }; break;
            case Actions.SET_SHOPS:      newState = { ...state, shops: action.shops }; break;
            case Actions.SET_PERSONNELS: newState = { ...state, personnels: action.personnels }; break;
            case Actions.SET_POLICIES:   newState = { ...state, policies: action.policies }; break;
            case Actions.SET_PERIODS:    newState = { ...state, periods: action.periods }; break;
            case Actions.SET_LEAVES:     newState = { ...state, leaves: action.leaves }; break;
            default:
                newState = state;
                break;
        };
        // AsyncStorage.setItem('persist', JSON.stringify(newState));
        return newState;
    }
    , initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, Actions }