
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { store } from '../store';
import T, { localeDateService } from '../T';
import glob from '../glob';
import { Button, Card, Datepicker, Text } from '@ui-kitten/components';
import MIcons from './MIcons';
import MText from './MText';
import MTouchable from './MTouchable';


const parseProps = (props, setMode, setDate, selectMode) => {
  setMode(props.mode);
  setDate(props.mode === RANGE_MODE.SINGLEDAY ? props.date : null);
  if (__DEV__ && !glob.tested) {
    glob.tested = true;
    selectMode(RANGE_MODE.MONTH_THIS);
  }
}


const TITLE = 'Chọn khoảng thời gian';
const RangePicker = (props) => {
    const { state, dispatch } = useContext(store);

    const close = props.close;
    const confirm = props.onConfirm;
    const [mode, setMode] = useState(null);
    const [modeTit, setModeTit] = useState(null);
    const [date, setDate] = useState(null);

    useEffect(() => { parseProps(props, setMode, setDate, selectMode); }
    , []);

    const selectMode = (mode) => {
      setMode(mode); setModeTit(findTit(mode, date));
      if (mode !== RANGE_MODE.SINGLEDAY) {
        confirm(mode, mode !== RANGE_MODE.SINGLEDAY && mode !== RANGE_MODE.YESTERDAY ? null : date);
      }
    }
    const selectDate = (date) => {
      setDate(date);
    }
    const confirmAndClose = () => {
      confirm(mode, date);
    }

    const _showDatePicker = mode === RANGE_MODE.SINGLEDAY;
    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        <MTopBar zx={props.zx} pa={'RangePicker'} title={ props.title ?? 'Chọn khoảng thời gian'} 
          onGoBack={() => close()} />
        
        {/* CONTENT */}
        <MView grow={1} pad={10} all_cen >

          <MView gone={T.isNullOrEmpty(props.upperTitle)} all_cen w_100 pad={22} >
            <MText tito c_999 txt={props.upperTitle} />
          </MView>

          <MView w={350} /* h={250} */ /* h={390} */ pad={15} shadow={9} bg_white borRad={7} ai_cen >
            
            <MIcons fasolid name='calendar' c_999 size={14} maBot={15} />
            <MText note c_999 ta_cen txt={'Hãy chọn khoảng thời gian mà bạn muốn xem báo cáo chấm công'} />

            <MView h={22} />
            <MView row w_100 >
              <RangeButton curRM={mode} RM={RANGE_MODE.YESTERDAY} status='basic' size='small' style={st.butt} onPress={selectMode} />
              <MView grow={1} />
              <RangeButton curRM={mode} RM={RANGE_MODE.MONTH_THIS} status='basic' size='small' style={st.butt} onPress={selectMode} />
            </MView>
            <MView h={11} />
            <MView row w_100 >
              <RangeButton curRM={mode} RM={RANGE_MODE._7DAYS} status ='basic' size='small' style={st.butt} onPress={selectMode} />
              <MView grow={1} />
              <RangeButton curRM={mode} RM={RANGE_MODE.MONTH_PREV1} status ='basic' size='small' style={st.butt} onPress={selectMode} />
            </MView>
            <MView h={11} />
            <MView row w_100 >
              <RangeButton curRM={mode} RM={RANGE_MODE.SINGLEDAY} status ='basic' size='small' style={st.butt} onPress={selectMode} />
              <MView grow={1} />
              <RangeButton curRM={mode} RM={RANGE_MODE.MONTH_PREV2} status='basic' size='small' style={st.butt} onPress={selectMode} />
            </MView>


            <MView gone={!_showDatePicker} w_100 maTop={22} padV={11} style={{ borderTopWidth: 1, borderColor: '#eee' }} >
              <MText note c_999 maV={11} txt={'Chọn ngày:'} />
              <Datepicker
                ref={c => c?.show()} 
                dateService={localeDateService}
                date={date} style={{ width: '100%' }}
                onSelect={_date => selectDate(_date) } />
              <Button status={'success'} size='small' style={{ marginTop: 11, width: '100%' }} onPress={() => confirmAndClose()} >{'Tải dữ liệu'}</Button>
            </MView>


          </MView>

          <MTouchable gone={!props.closable} maTop={22} text={{ color: 'teal', nd: true, txt: 'Quay lại báo cáo' }} 
            onPress={() => close()} />

          <MView h='30%' />

        </MView>    
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e159', e); glob.toast('Mở không thành công');
      close();
      return null;
    }
}
export default RangePicker;

RangePicker.propTypes = {
  mode: PropTypes.any,
  zx: PropTypes.number,
  date: PropTypes.instanceOf(Date),
  close: PropTypes.func,
  onConfirm: PropTypes.func,
  closable: PropTypes.bool,
  title: PropTypes.string,
  upperTitle: PropTypes.string
};

const st = StyleSheet.create({
  butt: {
    height: 33, width: 155,
  }
})

export const RANGE_MODE = {
  NONE: 'NONE',
  YESTERDAY: 'YESTERDAY',
  _7DAYS: '7DAYS',
  SINGLEDAY: 'SINGLEDAY',
  MONTH_THIS: 'MONTH_THIS',
  MONTH_PREV1: 'MONTH_PREV1',
  MONTH_PREV2: 'MONTH_PREV2',
}

const RangeButton = (props) => {
  const pr = props; const RM = pr.RM; const curRM = pr.curRM; const onPress = pr.onPress;
  const tit = findTit(RM, null);
  return (
    <Button status={ RM === curRM ? 'success' : 'basic'} size='small' style={st.butt} onPress={() => onPress(RM)} >{tit}</Button>
  );
}

export const findTit = (RM, date) => {
  let tit = ''; const now = new Date();
  switch (RM) {
    case RANGE_MODE.YESTERDAY:          tit = 'Hôm qua';                                             break;
    case RANGE_MODE._7DAYS:             tit = '7 ngày gần nhất';                                     break;
    case RANGE_MODE.SINGLEDAY:          tit = `Theo ngày${!date ? '' : ` (${T.formatDDMMYYYY(date)})`}`;                                           break;
    case RANGE_MODE.MONTH_THIS:         tit = `Tháng ${now.getMonth() + 1}/${now.getFullYear()}`;                   break;
    case RANGE_MODE.MONTH_PREV1:        now.setMonth(now.getMonth() - 1);  tit = `Tháng ${now.getMonth() + 1}/${now.getFullYear()}`;                   break;
    case RANGE_MODE.MONTH_PREV2:        now.setMonth(now.getMonth() - 2);  tit = `Tháng ${now.getMonth() + 1}/${now.getFullYear()}`;                   break;
  }
  return tit;
}