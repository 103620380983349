import { Dimensions, Platform } from "react-native";
import T from "./T";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MTOAST_PENDING } from './MComponents/MToast';

class glob {

    static version = '1.1.1002';

    static toastGeneric(trace) {
        glob.toast('Kết nối không thành công ' + (trace ?? ''));
    }
    
    /** * @typedef {string[]} */
    static _toastQueue = [];
    static toast(msg) {
        if (!T.isNullOrEmpty(msg)) {
            this._toastQueue.push(msg);
        }
    }
    static _deQueueToast(txtGet) {
        for (var i = glob._toastQueue.length - 1; i >= 0; i--) {
            if (glob._toastQueue[i] === txtGet) {
                glob._toastQueue.splice(i, 1);
            }
        }
    }
    static toastAfterReload(msg) {
        AsyncStorage.setItem(MTOAST_PENDING, msg);
    }


    /**
     * @typedef PromptButt * @type {object}
     * @param {number} mood
     * @property {string=} tit - title
     * @property {string=} note - note
     * @property {() => void} onPress - on pressButt.
     */

    /**
     * @typedef PromptObj * @type {object} * @property {number} mood - mood. * @property {string} tit - title. * @property {string} msg - message. 
     * @property {PromptButt} okButt - on OK.
     * @property {PromptButt[]} extra - on OK.
     * @property {() => void} onCancel - on onCancel.
     */

    /** @type {PromptObj} */
    static curPrompt = null;
    
    /**
     * @param {number} mood * @param {string} tit * @param {string} msg 
     * @param {() => void} onOK 
     * @property {PromptButt=} onOKDetail - on OK title & note.
     * @property {PromptButt[]=} extra - on OK title & note.
     * @property {() => void} onCancel - on onCancel.
     */
    static prompt = (mood, tit, msg, onOK, onOKDetail, extra, onCancel) => {
        glob.curPrompt = { mood, tit, msg
            , okButt: { onPress: onOK, tit: onOKDetail?.tit, note: onOKDetail?.note, mood: onOKDetail?.mood } 
            , extra: extra
            , onCancel: onCancel
        };
    }

    
    /** * @typedef {boolean} */
    static mInitialized = false;
    /** * @typedef {boolean} */
    static isMobile = false;

    /** * @typedef {boolean} */
    static isIOS = false;
    /** @returns {boolean} */
    static getIsIOS = () => {
        return Platform.OS === 'ios';
    }

    /** * @typedef {boolean} */
    static oldIOS = false;
    static oldIOSH = () => { return glob.oldIOS ? 20 : 0 };



    static ww = Dimensions.get('window').width;
    static hh = Dimensions.get('window').height;
    static updateWH = () => {
        glob.ww = Dimensions.get('window').width;
        glob.hh = Dimensions.get('window').height;
        // T.l('updateWH', glob.ww, glob.hh);
    }

    /** * @typedef {SafeAreaView} */
    static SafeAreaView = null;
    /** * @typedef {number} */
    static SafeAreaViewSetAt = undefined;


    static hasNotch = undefined;

    static notiData = {};

    static homeNeedUpdate = false;

    static tested = false;
    static tested2 = false;

    static userName = null;
    static userId = null;
    static userCred = null;
    static urlParams = null;
    static shopID = null;

    static isAdmin = false;
}


export default glob;