import T from '../T';
import glob from '../glob';
import { Actions } from '../store';
import mfetch from '../mfetch';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getHISTORY } from '../consts/HISTORY';
import { openPage } from '../../App';


const kickOut = () => {
    if (!__DEV__) 
    {
        window.open(getHISTORY().ME_AHA_HOME, '_self');
    }
    else {
        glob.toast('kickout');
    }
}
export default class ahaAPICalls {

    static credChecking = false;
    static checkCred = () => {
        if (ahaAPICalls.credChecking) { return; }
        ahaAPICalls.checkUserCall(glob.userName).then(ok => {
            if (ok !== true) {
                if (!__DEV__) {
                    kickOut();
                }
                else {
                    kickOut();
                }
            }
            else {
                ahaAPICalls.checkCredCall(glob.userCred).then(ok => {
                    if (ok !== true) {
                        if (!__DEV__) {
                            kickOut();
                        }
                        else {
                            // setTimeout(() => openPage('ERROR', 'B'), 2000);
                            kickOut();
                        }
                    }
                });
            }
        });
    }

    static checkCredCall = (cred) => {
        return new Promise((resolve) => {
            ahaAPICalls.credChecking = true;
            let body = { CredentialID: cred, CredentialType: 3, Data: null }

            mfetch.do("https://api.ahacafe.vn/pom.live.shops", { "headers": { "content-type": "application/json", },
                "body": JSON.stringify(body), "method": "POST",
            })
            .then(e => e.json())
            .then(e => {
                ahaAPICalls.credChecking = false;
                resolve(4 === e?.Result);
            })
            .catch(e => {
                ahaAPICalls.credChecking = false;
                resolve(false);
            });

        });
    }
    static checkUserCall = (user) => {
        return new Promise((resolve) => {
            if (T.isNullOrEmpty(user)) { T.l('isNullOrEmpty', user); resolve(false); }

            ahaAPICalls.credChecking = true;
            let _user = user.toUpperCase();

            mfetch.do("https://api.ahacafe.vn/timekeeping/allowed_users.txt")
            .then(e => e.json())
            .then(e => {
                ahaAPICalls.credChecking = false;
                // T.l('checkUserCall', e, _user, e?.includes?.(_user));
                glob.isAdmin = e?.admin?.includes?.(_user)
                // T.l('isAdmin', glob.isAdmin, _user, e?.admin);
                resolve(e?.access?.includes?.(_user));
            })
            .catch(e => {
                ahaAPICalls.credChecking = false;
                resolve(false);
            });

        });
    }

    // static login = (userName, password, dispatch) => {
    //     return new Promise((resolve) => {
    //         userName = userName?.toLowerCase();
    //         ahaAPICalls.getAllowedUser(userName).then(ok => {
    //             if (ok) {
    //                 ahaAPICalls.loginCall(userName, password).then(cr => {
    //                     if (T.isNullOrEmpty(cr)) { glob.toast('Đăng nhập không thành công'); }
    //                     dispatch({ type: Actions.SET_USER, cred: cr, userName: userName });
    //                     resolve(!T.isNullOrEmpty(cr));
    //                 });
    //             }
    //             else { glob.toast('Không được phân quyền'); resolve(false); }
    //         })
    //     });
    // }

    // static getAllowedUser = (un) => {
    //     return new Promise((resolve) => {
    //         mfetch.do('http://api.ahacafe.vn/wallet/walletReport/allowed_users.txt')
    //         .then(res => res.json()).then(j => {
    //             let rs = (j?.regular?.includes(un) || j?.delayed?.includes(un));
    //             resolve(rs);
    //         })
    //         .catch(e => resolve(false))
    //     });
    // }

    // static loginCall = (userName, password) => {
    //     return new Promise((resolve) => {

    //         let body = { CredentialID: null, CredentialType: 3, Data: { Username: userName, Password: password, Type: 3 } }

    //         mfetch.do("https://api.ahacafe.vn/RMS:Login", { "headers": { "content-type": "application/json", },
    //             "body": JSON.stringify(body), "method": "POST",
    //         })
    //         .then(e => e.json())
    //         .then(e => {
    //             resolve(e?.Data?.CredentialID);
    //         })
    //         .catch(e => {
    //             glob.toastGeneric();
    //             resolve(null);
    //         });

    //     });
    // }

    // /** */ // janky af
    // static checkCred = (dispatch) => {
    //     return new Promise((resolve) => {
    //         AsyncStorage.getItem("persist", (e, persistStr) => {

    //             L.clipStrT('persistStr', persistStr);
                
    //             if (T.isNullOrEmpty(persistStr)) { resolve(false); }
    //             else {
    //                 let persist = JSON.parse(persistStr);
    //                 dispatch({ type: Actions.RESTORE_PERSIST, payload: persist });
    //                 console.log('ahaAPICalls.checkCredCall', persist?.user?.cred)
    //                 ahaAPICalls.checkCredCall(persist?.user?.cred).then(ok => {
    //                     console.log('ahaAPICalls.checkCredCall', ok)
    //                     resolve(ok);
    //                 })
    //             }
    //         });
    //     });
    // }
    // static checkCredCall = (cred) => {
    //     return new Promise((resolve) => {

    //         let body = { CredentialID: cred, CredentialType: 3, Data: null }

    //         mfetch.do("https://api.ahacafe.vn/pom.live.shops", { "headers": { "content-type": "application/json", },
    //             "body": JSON.stringify(body), "method": "POST",
    //         })
    //         .then(e => e.json())
    //         .then(e => {
    //             resolve(4 === e?.Result);
    //         })
    //         .catch(e => {
    //             resolve(false);
    //         });

    //     });
    // }
    
}


