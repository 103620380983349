import React, {  } from 'react';
import glob from './code/glob';
import { StateProvider } from './code/store';
import AppRoot, { BTOA_PARAMS, BTOA_PATH } from './code/AppRoot';
import * as Font from 'expo-font';
import * as eva from '@eva-design/eva';
import { ApplicationProvider, Layout, Text } from '@ui-kitten/components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MTOAST_PENDING } from './code/MComponents/MToast';
import T from './code/T';
import MView from './code/MComponents/MView';
import { Dimensions, Platform } from 'react-native';
import MText from './code/MComponents/MText';
import { TKURL } from './code/APICalls/tkAPICalls';



export default function App() {

  mInitialize();

  return (
    <ApplicationProvider {...eva} theme={eva.light}>
      <StateProvider>

        <AppRoot />        

        { 
          !__DEV__ ? null :
          <MView posFixed fill_100 jc_fx_end clickThrough>
              <MView w_100 pad={3} all_cen bg_black opacity={0.1} >
                <MText cur white txt={`${(__DEV__ ? 'DEV' : '')} ${TKURL()}`} />
              </MView>
          </MView>
        }

      </StateProvider>
    </ApplicationProvider>
  );
}

export const openPage = (page, surfix) => {
  let _np = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  //_np += '/tk';
  _np += '?' + BTOA_PATH + '=' + page;
  _np += '&' + BTOA_PARAMS + '=' + glob.urlParams;
  _np += surfix ?? '';
  window.open(_np, '_self')
}

const mInitialize = () => {
  if (!glob.mInitialized) {
    glob.mInitialized = true;
    console.clear();
    console.log('mInitialize', +new Date(), window.location.hostname, glob.version);
    // glob.prompt(-1, 'navigator.userAgent', navigator.userAgent, null);

    // T.l('asdf', T.numberToDigitGroupedString(123456789));

    glob.isMobile = navigator?.userAgent?.includes?.('Android');
    Font.loadAsync(customFonts);
    glob.updateWH();
    // eslint-disable-next-line node/handle-callback-err
    AsyncStorage.getItem(MTOAST_PENDING, (err, str) => {
      if (!T.isNullOrEmpty(str)) {
        glob.toast(str);
        AsyncStorage.removeItem(MTOAST_PENDING);
      }
    });
  }
}

const customFonts = {
  // @ts-ignore
  'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
  // @ts-ignore
  'Roboto-Bold': require('./assets/fonts/Roboto-Bold.ttf'),
  // @ts-ignore
  'Monofonto-Regular': require('./assets/fonts/Monofonto-Regular.ttf'),
  // @ts-ignore
  'SourceSansPro-Regular': require('./assets/fonts/SourceSansPro-Regular.ttf'),
};