import T from './T';

export default class mfetch {
    /**
     * @param {RequestInfo} input 
     * @param {RequestInit} [init] 
     * @param {boolean} [dontLog] 
     * @param {string} [trace] 
     * @param {number} [timeToTimeout] 
     * @returns {Promise<Response>}
     */
    static do(input, init, dontLog, trace, timeToTimeout) {
        // T.l('fetch', input);
        if (!__DEV__) {
            return fetch(input, init);
        }
        else {
            return 0 < timeToTimeout ? advFetch(timeToTimeout, input, init) : fetch(input, init);
        }
    }
}



const advFetch = (timeToTimeout, input, init) => {
    let didTimeOut = false;

    return new Promise(function (resolve, reject) {
        const timeout = setTimeout(() => {
            didTimeOut = true;
            reject(new Error('Request timed out'));
        }, timeToTimeout);

        fetch(input, init).then(function (response) {
            clearTimeout(timeout);
            if (!didTimeOut) {
                resolve(response);
            }
        })
            .catch(function (err) {
                if (didTimeOut) {
                    return;
                }
                reject(err);
            });
    })
}