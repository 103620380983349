import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import { PERSON_ICON } from '../consts/gst';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MIcons from '../MComponents/MIcons';
import MText from '../MComponents/MText';
import MTopBar from '../MComponents/MTopBar';
import MTouchable from '../MComponents/MTouchable';
import MView from '../MComponents/MView';
import RangePicker, { findTit, RANGE_MODE } from '../MComponents/RangePicker';
import { Actions, store } from '../store';
import T from '../T';
import ReportScreen_PersonView from './ReportScreen_PersonView';
import { getHISTORY } from '../consts/HISTORY';

export const ReportScreenItem_CC_Portrait = (props) => {
    const pr = props;
    const data = pr.data;
    const person = data?.person;
    const ov = data?.overview;
    const onSelect = pr.onSelect;

    const _img = tkBioPhotoUrl(person?.id);

    const much_absences = 5 <= ov?.absences;
    const much_untimelies = 5 <= ov?.untimelies;

    return (
      <MTouchable bg_white padV={11} 
        onPress={() => onSelect(data)}
        onLongPress={() => {
            T.l('data', data);
        }}
      >
        <MView padV={11} style={{ borderBottomWidth: 1, borderColor: '#eee' }}>
            <MView row>
                <ImageLoader source={_img} fallback={PERSON_ICON} style={{ width: 48, height: 48, borderRadius: 24, marginEnd: 10 }} />
                <MView grow={1} >


                    <MText nd bold txt={`${person?.name}`} />

                    <MView h={11} />
                    <MView row >
                        <MText /* brown={0 < ov?.untimelies} */ opacity={0 < ov?.untimelies ? 1 : 0.5} nd c_555 txt={'Thiếu giờ: '} reverse_children >
                            <MText nd bold orange={(0 < ov?.untimelies)} txt={'' + ov?.untimelies} />
                        </MText>
                        <MView grow={1} />
                        <MText nd red={(0 < ov?.absences)} opacity={0 < ov?.absences ? 1 : 0.5} c_555 txt={'Nghỉ: ' + ov?.absences} />
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Có mặt: '} c_555 reverse_children >
                            <MText nd bold accent={ov._attHoursExcess} txt={ov?._attHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Được phân công: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._totHours?.toFixed(1) + ' h'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Ghi nhận: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._regHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Đi làm: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._regDays + '/' + ov?._totDays + ' ngày'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        { !much_untimelies ? null : <Pill bg_orange txt='Đi muộn nhiều' /> }
                        { !much_absences ? null   : <Pill bg_red txt='Nghỉ nhiều' /> }
                    </MView>


                </MView>
            </MView>
        </MView>
      </MTouchable>
    );
}

const Pill = (props) => {
    const pr = props;
    return (
        <MView {...pr} padV={7} padH={22} all_cen borRad={22} maEnd={11} >
            <MText nd bold white txt={pr.txt} />
        </MView>
    );
}