
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T from '../T';
import { Input } from '@ui-kitten/components';
import MIcons from '../MComponents/MIcons';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import { PERSON_ICON } from '../consts/gst';


const parseProps = (props, dispatch, setName, setPhone, setGid, setAddress, setOrigin) => {
  if (props.curPerson) {
    const _curPerson = props.curPerson;
    document.title = _curPerson?.name ?? TITLE;
    setName(_curPerson?.name); setPhone(_curPerson?.phone); setGid(_curPerson?.gid); setAddress(_curPerson?.address); setOrigin(_curPerson?.origin);
  }
  else {
    document.title = TITLE;
  }
}

const savePersonnel = (state, dispatch, onCloseEditor, shopID, curPerson, name, phone, gid, address, origin) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  tkAPICalls.savePersonnel(
      shopID
    , { ...(curPerson ?? {}) , name, phone, gid, address, origin }
  )
  .then(ok => {
    dispatch({ type: Actions.SET_BUSY, busy: false });    
    if (ok) {
      glob.toast('Đã lưu');
      onCloseEditor({ saved: true });
    }
    else {
      glob.toast('Lưu không thành công');
    }
  });
}


const TITLE = 'Tạo nhân sự';
const PersonnelsScreen_Editor = (props) => {
    const { state, dispatch } = useContext(store);
    
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [gid, setGid] = useState('');
    const [address, setAddress] = useState('');
    const [origin, setOrigin] = useState('');

    useEffect(() => { parseProps(props, dispatch, setName, setPhone, setGid, setAddress, setOrigin); }
    , []);

    const onCloseEditor = props.onCloseEditor;
    const onSelectCreatePeriod = props.onSelectCreatePeriod;
    const curPerson = props.curPerson;
    const _img = tkBioPhotoUrl(curPerson?.id);

    const _render = () => (
      <MView absolute fill_100 bg="#f5f7fa">
        <MTopBar zx={props.zx} pa={'PersonnelsScreen_Editor'} title={0 < curPerson?.id ? (curPerson?.name ?? '') : 'Tạo nhân sự'} 
          onGoBack={() => onCloseEditor()} />
        
        {/* FORM */}
        <MView w_100 jc_cen row >
          <MView pad={10} 
            style={{ maxWidth: 777 }} grow={1} >
            <MView row w_100>
              { !(0 < curPerson?.id) ? null :
                <MView jc_cen >
                  <ImageLoader source={_img} fallback={PERSON_ICON} style={styles.img} />
                </MView>
              }
              <MView grow={1} >
                <Input label="Tên" value={name} onChangeText={(str) => setName(str)} keyboardType='name-phone-pad' style={styles.input} />
                <Input label="Số điện thoại" value={phone} onChangeText={(str) => setPhone(str)} keyboardType='number-pad' style={styles.input} />
                <Input label="Số chứng minh" value={gid} onChangeText={(str) => setGid(str)} keyboardType='number-pad' style={styles.input} />
              </MView>
            </MView>

            <Input label="Địa chỉ" value={address} onChangeText={(str) => setAddress(str)} keyboardType='name-phone-pad' style={styles.input} />
            <Input label="Nguyên quán" value={origin} onChangeText={(str) => setOrigin(str)} keyboardType='name-phone-pad' style={styles.input} />


            {/* BUTTONS */}
            <MTouchable padH={20} h={40} maV={11} bg='#2586D0' borRad={4} row all_cen
              text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'PHÂN CÔNG CÔNG VIỆC' }} 
              onPress={() => onSelectCreatePeriod()} >
              <MIcons matCom white name="table-clock" size={19} />
            </MTouchable>
            <MView row maTop={10} >
              <MTouchable w='49%' padH={20} h={40} bg='#ff902b' borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'QUAY LẠI' }} 
                onPress={() => onCloseEditor()} >
                <MIcons feather white name="x" size={19} />
              </MTouchable>
              <MView w='2%' />
              <MTouchable w='49%' padH={20} h={40} bg_accent borRad={4} row all_cen
                text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'LƯU' }} 
                onPress={() => savePersonnel(state, dispatch, onCloseEditor, state.shopID, curPerson, name, phone, gid, address, origin)} >
                <MIcons mat white name="done" size={19} />
              </MTouchable>
            </MView>

          </MView>
        </MView>
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e110', e); glob.toast('Mở không thành công');
      onCloseEditor();
      return null;
    }
}
export default PersonnelsScreen_Editor;
PersonnelsScreen_Editor.propTypes = {
  onCloseEditor: PropTypes.func,
  onSelectCreatePeriod: PropTypes.func,
  zx: PropTypes.number,  
  curPerson: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gid: PropTypes.string,
    address: PropTypes.string,
    origin: PropTypes.string,
    phone: PropTypes.string,
  }),
};



const styles = StyleSheet.create({
  img: { width: 128, height: 128, borderRadius: 64, marginEnd: 20 }
  , input: {
    marginBottom: 10
  }
})