
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import MView from '../MComponents/MView';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import T from '../T';
import { Button, Input } from '@ui-kitten/components';
import MIcons from '../MComponents/MIcons';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MTouchable from '../MComponents/MTouchable';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import { accent, PERSON_ICON } from '../consts/gst';
import MText from '../MComponents/MText';
import RangePicker, { findTit, RANGE_MODE } from '../MComponents/RangePicker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DURATION, STARTEND } from '../consts/POLICY_TYPES';
import ReportScreen_PersonView_Editor from './ReportScreen_PersonView_Editor';


const parseProps = (props, dispatch, setReport, set_cur_RANGE_MODE, set_cur_RANGE_tit, set_cur_RANGE_date, setPolicies, selectEdit) => {
  if (props.personReport) {
    
    const _curPerRep = props.personReport;

    setReport(_curPerRep);
    set_cur_RANGE_MODE(props.initMode ?? _curPerRep.rangeMode);
    set_cur_RANGE_tit(props.initModeTit ?? findTit(_curPerRep.rangeMode, _curPerRep.rangeDate));
    set_cur_RANGE_date(props.initDate ?? _curPerRep.rangeDate);
    setPolicies(props.policies);

    
    // /***/
    // if (__DEV__ && !glob.tested2) {
    //   glob.tested2 = true;
    //   T.l('/***/', { idx: 0, data: _curPerRep.reports[1] })
    //   selectEdit(0, _curPerRep.reports[1] )
    // }

  }
  else {
  }
}


const getReport = async (props, state, dispatch, policies, persons, range, date, setReport, setShowRangePicker) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });    

  const reports = await tkAPICalls.getReport(dispatch, state.shop, state.shop.openTime
          , props.personReport?.person?.id, range, date, policies, persons);
  
  setReport(reports[0]);
  // AsyncStorage.setItem(KEY_OPENING_PERSON_REP, JSON.stringify(reports[0]));

  dispatch({ type: Actions.SET_BUSY, busy: false });
  setShowRangePicker(false); window.scrollTo(0, 0);
}


const onSave = (props, editedClosing, arrived, left) => {
  if (T.isNullOrUndef(arrived) || T.isNullOrUndef(left)) {
    glob.toast('Dữ liệu không đúng');
  }
  else {
    const closing = editedClosing.data.org;
    closing.adjustedClosingDatas = closing.adjustedClosingDatas ?? [ '', '' ];    
    closing.adjustedClosingDatas[editedClosing.idx] = `${arrived}|${left}|${glob.userName}|${+new Date()}`;
    tkAPICalls.editClosing(closing).then(ok => {
      if (ok) {
        props.onClose();
        setTimeout(() => {
          props.onRefresh();
        }, 500);
      }
    });
  }
}


const TITLE = 'Báo cáo chấm công nhân sự';
const ReportScreen_PersonView = (props) => {
    const { state, dispatch } = useContext(store);
    
    const [report, setReport] = useState(null);
    const [policies, setPolicies] = useState([]);

    const [showRangePicker, setShowRangePicker] = useState(false);
    const [editingClosing, setEditingClosing] = useState(null);
    const [showingEditor, setShowingEditor] = useState(false);

    const [cur_RANGE_MODE, set_cur_RANGE_MODE] = useState(RANGE_MODE.NONE);
    const [cur_RANGE_tit, set_cur_RANGE_tit] = useState('');
    
    const [cur_RANGE_date, set_cur_RANGE_date] = useState(T.getYesterday());

    useEffect(() => { parseProps(props, dispatch
        , setReport, set_cur_RANGE_MODE, set_cur_RANGE_tit, set_cur_RANGE_date, setPolicies, selectEdit); }
    , []);

    const pullData = (mode, date) => {    
        set_cur_RANGE_MODE(mode); set_cur_RANGE_tit(findTit(mode, date)); set_cur_RANGE_date(date);
        getReport(props, state, dispatch, props.policies, props.persons, mode, date, setReport, setShowRangePicker);
    }
    
    const selectEdit = (idx, data) => {
      // tkAPICalls.createClosing(state.cred, data.org);
      setEditingClosing({ idx, data });
      setShowingEditor(true); window.scrollTo(0, 0);
    }

    const onClose = props.onClose;
    const curPerson = report?.person;
    const ov = report?.overview;
    const reps = report?.reports;

    const _img = curPerson ? tkBioPhotoUrl(curPerson?.id) : null;

    const _noData = !report;

    const _todayKey = T.toDateKey(new Date());

    const _title = showingEditor ? 'Thay đổi chốt công' 
                                : 0 < curPerson?.id ? (curPerson?.name ?? '') 
                                                    : props.personReport?.person?.name ?? TITLE;
    
    document.title = showingEditor ? 'Thay đổi chốt công' : props.personReport?.person?.name ?? TITLE;

    const _render = () => (
      <MView absolute fill_100 bg="white">

        { showingEditor || showRangePicker ? null : 
          <MTopBar zx={props.zx} pa={'ReportScreen_PersonView'} title={_title} 
            onGoBack={() => { if (!showingEditor) { onClose(); } else { setShowingEditor(false); window.scrollTo(0, 0); } }} />}

       { /* FILTER */
        <MTouchable maStart={5} padH={15} maV={22} row w_100 ai_cen 
          onPress={() => { setShowRangePicker(true); window.scrollTo(0, 0); }} >
          {/* <MView grow={1} /> */}
          <MIcons fasolid name='calendar' maEnd={11} bg_teal c_555 size={14} />
          <MText nd bold c_555 txt={cur_RANGE_tit} />
        </MTouchable> }


        <MView gone={!_noData} fill_100 all_cen padH='20%' >
            <MText ta_cen tito c_999 txt='Không có dữ liệu cho khoảng thời gian này' />
        </MView>


        <MView gone={_noData} >

          {/* OVERVIEW */}
          <MView row pad={10} >
                <ImageLoader gone={!_img} source={_img} fallback={PERSON_ICON} style={{ width: 72, height: 72, borderRadius: 36, marginEnd: 10 }} />
                <MView grow={1} >

                    <MText nd bold txt={curPerson?.name} />

                    <MView h={11} />
                    <MView row >
                        <MText gone={!(0 < ov?.untimelies)} nd c_555 txt={'Thiếu giờ: '} reverse_children >
                            <MText nd bold red={(0 < ov?.untimelies)} txt={'' + ov?.untimelies} />
                        </MText>
                        <MView grow={1} />
                        <MText nd red={(0 < ov?.absences)} c_555 txt={'Nghỉ: ' + ov?.absences} />
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Có mặt: '} c_555 reverse_children >
                            <MText nd bold accent txt={ov?._attHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Được phân công: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._totHours?.toFixed(1) + ' h'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Ghi nhận: '} c_555 reverse_children >
                            <MText nd bold accent txt={ov?._regHours?.toFixed(1) + ' h'} />
                        </MText>
                        <MView grow={1} />
                        <MText nd txt={'Đi làm: '} c_555 reverse_children >
                            <MText nd bold txt={ov?._regDays + '/' + ov?._totDays + ' ngày'} />
                        </MText>
                    </MView>

                    <MView h={11} />
                    <MView row >
                        <MText nd txt={'Thu nhập: '} c_555 reverse_children >
                            <MText nd bold accent txt={ Number(ov?._earning?.toFixed(0)).toLocaleString()  + 'đ'} />
                        </MText>
                        <MView grow={1} />
                    </MView>

                </MView>
            </MView>

            {/* REPORTS */
            showingEditor ? null :
            <FlatList style={{ width: '100%', height: '100%' }}
                scrollEnabled={true} data={reps} keyExtractor={(item, index) => { return item.dateKey + ''; }} 
                ListFooterComponent={() => <MView h={99} />}
                numColumns={1}
                renderItem={({ item, index }) => 
                  <ReportItem cred={state.cred} todayKey={_todayKey} data={item} 
                    onSelectEdit={(idx, data) => selectEdit(idx, data)}
                    /> 
                } />
            }
            

        </MView>


        { !showRangePicker ? null :
          <RangePicker
              zx={props.zx + 1} 
              title={curPerson?.name}
              closable={!_noData}
              mode={cur_RANGE_MODE}
              date={cur_RANGE_date}
              close={() => { setShowRangePicker(false); window.scrollTo(0, 0); }}
              onConfirm={pullData}
              />
        }

        { !showingEditor ? null :
          <MView absolute fill_100 clickThrough >
            <ReportScreen_PersonView_Editor
              zx={props.zx + 1} 
              curPerson={curPerson}
              close={() => { setShowingEditor(false); window.scrollTo(0, 0); }}
              basedData={editingClosing} 
              onSave={(editedClosing, arrived, left) => onSave(props, editedClosing, arrived, left)}
              />
          </MView>
        }
      </MView>
    );

    try {
      return _render();
    }
    catch (e) {
      T.l('e110', e); glob.toast('Mở không thành công');
      onClose();
      return null;
    }
}
export default ReportScreen_PersonView;
ReportScreen_PersonView.propTypes = {
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  zx: PropTypes.number,
  personReport: PropTypes.any,
  initMode: PropTypes.string,
  initModeTit: PropTypes.string,
  initDate: PropTypes.instanceOf(Date),
  policies: PropTypes.array,
  persons: PropTypes.array
};



const styles = StyleSheet.create({
  img: { width: 48, height: 48, borderRadius: 24, marginEnd: 20 }
  , input: {
    marginBottom: 10
  }
})



const ReportItem = (props) => {
  const pr = props;
  const data = pr.data;
  const cred = pr.cred;
  const onSelectEdit = pr.onSelectEdit;
  const todayKey = pr.todayKey;
  const _dateKey = T.toDateKey(data?.date);
  const isToday = todayKey === _dateKey;

  // let _fals = pr.freeze;
  // T.l('!_freeze && glob.isAdmin', `${!_fals} && ${glob.isAdmin}`)
  // if (!_fals && glob.isAdmin) {
  //   _fals = false;
  // }
  // if (!_fals && data.daysAgo >= 2) {
  //   _fals = true;
  // }
  const _editable = glob.isAdmin || data.editable;

  const _leaves = data?.leaves?.leaveType === 0 ? 'Xin phép đến muộn' : data?.leaves?.leaveType === 1 ? 'Xin phép nghỉ' : null;

  let i = 0;
  return (
        
        <MView pad={11} maH={pr.maH} bg_white >
          <MView padV={11} style={{ borderBottomWidth: 1, borderColor: '#eee' }}>
              
              <MView row w_100 jc_spc_betw ai_cen >
                <MText nd bold c_999 txt={T.formatDDMMYYYY(data?.date) + `${''}`} />
                <MText gone={!_leaves} nd bold blue txt={_leaves} />
              </MView>

              { data?.closingDatas?.map((p, k) => { 
                
                const idx = i++;
                const pol = data?.policyDatas?.[idx];
                const clo = data?.closingDatas?.[idx];
                const aClo = data?.adjustedClosingDatas?.[idx];
                const hasUpdater = !T.isNullOrEmpty(aClo?.updater);
  
                const isSLate = 0 < clo.startLate;
                const isLEarly = 0 < clo.leaveEarly;
  
                const isSTARTEND = pol.pType === STARTEND;
                const isDURATION = pol.pType === DURATION;
  
                let showRegHours = clo.arrived > 0 && clo.left > 0;
                showRegHours = true;

                const bg = clo.hasUntimely && !clo.hasAbsence ? 'orange'
                            : clo.hasAbsence || clo.isAbsence ? 'red'
                            : '#999';

                const _coMat = clo.isAbsence ? 'Nghỉ' : `${clo._attHours?.toFixed(2)}`;

                return (
                  <div key={idx}
                    onClick={() => { 
                      T.l(`entry isAdmin ${glob.isAdmin}`);
                      T.l('data', data);
                      T.l('p', p);
                      // tkAPICalls.xyzCall(data.shop_id, data.personnel_id, (data?.date?.getMonth?.() ?? -2) + 1, (data?.date?.getDate?.() ?? -2));
                    }} >
                  <MView key={idx}>
  
                    <MView row ai_cen maTop={11} maBot={4} >

                      { !_editable 
                      ? <MText nd bold c_999 txt={`${pol?.name}`} />
                      : <MTouchable
                          padV={5} padH={8} bg={bg} borRad={3} row ai_cen
                          onPress={() => { onSelectEdit(idx, data); }} >
                          <MText nd bold white txt={`${pol?.name}`} />
                          <MIcons mat name='edit' white size={13} maStart={8} />
                        </MTouchable>
                      }
  
                      <MView grow={1} />
                      <MText gone={!isSTARTEND} nd c_999 txt={pol.startTime} />
                      <MIcons gone={!isSTARTEND} ent name='chevron-right' size={13} c_999 maTop={4} />
                      <MText gone={!isSTARTEND} nd c_999 txt={pol.endTime} />
                      <MText gone={!isSTARTEND} nd c_999 txt={` (${pol._totHours}h)`} />
                      
                      <MText gone={!showRegHours} nd c_999 txt={ clo.isAbsence ? '' : 'Có mặt: '} reverse_children >
                        <MText nd bold red={clo._attHoursLacking} accent={clo._attHoursExcess} txt={_coMat} />
                        <MText gone={clo.isAbsence} nd c_999 txt={` / ${pol._totHours}h`} />
                      </MText>
                    </MView>
                    
                    <MView row ai_cen >
                      <MText gone={!(clo.arrived > 0)} cur c_999 txt={'Đến : '} reverse_children >
                        <MText nd txt={clo._arrivedTime} />
                      </MText>
                      <MView w={22} h_100 ai_cen >
                        <MView w={1} h={22} bg_eee />
                      </MView>
                      <MText gone={!(clo.left > 0)} cur c_999 txt='Về : ' reverse_children >
                        <MText nd txt={clo._leftTime} />
                      </MText>
  
                      <MView grow={1} />
                      <MText nd c_999 txt={ clo.isAbsence ? 'Phân công: ' : 'Ghi nhận: '} reverse_children >
                        <MText nd bold color={clo._regHoursLacking ? 'red' : null} 
                          txt={`${(clo.isAbsence ? pol._totHours : clo._regHours)?.toFixed(2)}h${hasUpdater ? (' (' + aClo?.updater + ')') : ''}`} />
                      </MText>
  
                    </MView>
  
                    <MView row ai_cen >
                      <MView gone={!isSTARTEND} row ai_cen >
                        <MText opacity={isSLate ? 1 : 0.3} cur c_999 txt={'Muộn: '} reverse_children >
                          <MText nd orange={isSLate} txt={T.floatToTimeStr(clo.startLate, true)} />
                        </MText>
                        <MView w={22} ai_cen >
                          <MView w={1} h={22} bg_eee />
                        </MView>
                        <MText opacity={isLEarly ? 1 : 0.3} cur c_999 txt='Sớm: ' reverse_children >
                          <MText nd orange={isLEarly} txt={T.floatToTimeStr(clo.leaveEarly, true)} />
                        </MText>
                      </MView>
  
                      <MView grow={1} />
                    </MView>
                    
                  </MView>  
                  </div>
                
                ); } )}
          </MView>
        </MView>
      
      // </div>
    // </TouchableWithoutFeedback>

  );
}


