
import React, { useContext, useEffect, useState } from 'react';
import { KeyboardAvoidingView, View, Text, TextInput, Button, Platform, Keyboard, StyleSheet, Image, ActivityIndicator, Linking, Dimensions } from 'react-native';
import PersonnelsScreen, { PERSONNELS_SCREEN } from './Screens/PersonnelsScreen';
import AttendancePoliciesScreen, { ATTENDANCE_POLICIES_SCREEN } from './Screens/AttendancePoliciesScreen';
import EmploymentPeriodsScreen, { EMPLOYMENT_PERIODS_SCREEN } from './Screens/EmploymentPeriodsScreen';
import ReportScreen, { REPORT_SCREEN_BANG_LUONG, REPORT_SCREEN_CHAM_CONG } from './Screens/ReportScreen';
import { Actions, store } from './store';
import MView from './MComponents/MView';
import T from './T';
import ahaAPICalls from './APICalls/ahaAPICalls';
import MToast from './MComponents/MToast';
import MPrompt from './MComponents/MPrompt';
import tkAPICalls, { TKURL } from './APICalls/tkAPICalls';
import MText from './MComponents/MText';
import MTopBar from './MComponents/MTopBar';
import MTouchable from './MComponents/MTouchable';
import glob from './glob';
import LeavesScreen, { LEAVES_SCREEN } from './Screens/LeavesScreen';

const getInitialURL = (dispatch) => {
    Linking.getInitialURL().then(async (url) => {
        dispatch({ type: Actions.SET_BUSY, busy: true });
        const { cred, shopID, userName, userId, path, params } = extractUrl(window);
        const shops = await tkAPICalls.getShops(dispatch);
        const find = shops.find(p => p.id === shopID);
        // T.l('SET_SHOP', find, shops?.length, shopID);
        dispatch({ type: Actions.SET_SHOP, shop: find });
        dispatch({ type: Actions.SET_PARAMS, cred: cred, shopID: shopID, userName: userName, userId: userId
            , path: path, params: params });
        dispatch({ type: Actions.SET_BUSY, busy: false });
        ahaAPICalls.checkCred();
    });
}

const AppRoot = () => {
    const { state, dispatch } = useContext(store);

    useEffect(() => { getInitialURL(dispatch); }
    , []);
  
    return (
        <MView fill_100 bg='#f5f7fa' /* style={{ overflow: 'scroll' }} */  >
            {   
                  state.path === PERSONNELS_SCREEN              ? <PersonnelsScreen zx={1} />
                : state.path === ATTENDANCE_POLICIES_SCREEN     ? <AttendancePoliciesScreen zx={1} />
                : state.path === EMPLOYMENT_PERIODS_SCREEN      ? <EmploymentPeriodsScreen zx={1} />
                : state.path === LEAVES_SCREEN                  ? <LeavesScreen zx={1} />
                : state.path === REPORT_SCREEN_CHAM_CONG        ? <ReportScreen zx={1} />
                : state.path === REPORT_SCREEN_BANG_LUONG       ? <ReportScreen zx={1}  />
                
                : <CannotFindPage zx={1} />
            }
            <MView gone={!state.busy} absolute fill_100 all_cen bg='#00000088' >
                <ActivityIndicator size='large' color='white' />
            </MView>

          <MPrompt />
          <MToast />

        </MView>
    );
}
export default AppRoot;


export const BTOA_PATH = 'dGFyZ2V0';
export const BTOA_PARAMS = 'cGFyYW1z';
const extractUrl = (window) => {
//   let str = 'ba7b369c7fa266d76c8630eb7447bfda' + '|' + btoa('3') + '|' + btoa('vova');
//   str = btoa(str);
//   T.l('str', str)
  // YmE3YjM2OWM3ZmEyNjZkNzZjODYzMGViNzQ0N2JmZGF8T1RrMHxkbTkyWVE9PQ==
  // current: http://localhost:19006/?dGFyZ2V0=periods&cGFyYW1z=YmE3YjM2OWM3ZmEyNjZkNzZjODYzMGViNzQ0N2JmZGF8T1RrMHxkbTkyWVE9PQ==
  // current: http://localhost:19006/?dGFyZ2V0=policies&cGFyYW1z=YmE3YjM2OWM3ZmEyNjZkNzZjODYzMGViNzQ0N2JmZGF8T1RrMHxkbTkyWVE9PQ==
  // current: http://localhost:19006/?dGFyZ2V0=personnels&cGFyYW1z=YmE3YjM2OWM3ZmEyNjZkNzZjODYzMGViNzQ0N2JmZGF8T1RrMHxkbTkyWVE9PQ==
  //    http://localhost:19006/
  //                           ?dGFyZ2V0=personnels
  //                           &cGFyYW1z=YmE3YjM2OWM3ZmEyNjZkNzZjODYzMGViNzQ0N2JmZGF8T1RrMHxkbTkyWVE9PQ==
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get(BTOA_PARAMS);
  const path = urlParams.get(BTOA_PATH);

  if (!T.isNullOrEmpty(params) && !T.isNullOrEmpty(path)) {

      const pass1 = atob(params);
      const spl = pass1.split('|');
      const cred = spl[0];
      const shopID = Number.parseInt(atob(spl[1]));
      const userName = atob(spl[2]);
      const userId = atob(spl[3]);

      glob.userName = userName;
      glob.userId = userId;
      glob.userCred = cred;
      glob.urlParams = params;
      glob.shopID = shopID;


    
      return { cred, shopID, userName, userId, path, params };
    }

    else if (__DEV__) {        
        let _np = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
        _np += '/' + '?' + BTOA_PATH + '=' + 'periods' + '&' + BTOA_PARAMS + '=' 
        + btoa('ba7b369c7fa266d76c8630eb7447bfda' + '|' + btoa('994') + '|' + btoa('vova') + '|' + btoa('1'));
        window.open(_np, '_self');
    }

    return { cred: null, shopID: null, userName: null, userId: null, path: null };

};




const CannotFindPage = (props) => {  
    return (
        <MView fill_100 bg='#f5f7fa' >
            <MTopBar zx={props.zx} pa={'CannotFindPage'} title='AHA Chấm công' />
            <MView fill_100 all_cen bg='white' >
                <MText tito c_999 txt='Không tìm thấy trang' />                
                <MTouchable maTop={22} text={{ color: 'teal', nd: true, txt: 'Quay lại' }} 
                    onPress={() => window.history.back()} />
            </MView>
        </MView>
    );
}