
import React from 'react';
import { View, ViewPropTypes } from 'react-native';
import PropTypes from 'prop-types';
import gst, { accent, accent2 } from './../consts/gst';
import T from '../T';






export const propTypesColors = {
    color: PropTypes.string   , bg: PropTypes.string        

  , white: PropTypes.bool     , bg_white: PropTypes.bool     
  , pink: PropTypes.bool      , bg_pink: PropTypes.bool     
  , red: PropTypes.bool       , bg_red: PropTypes.bool     
  , teal: PropTypes.bool      , bg_teal: PropTypes.bool
  
  , aqua: PropTypes.bool      , bg_aqua: PropTypes.bool    
  , blue: PropTypes.bool      , bg_blue: PropTypes.bool    
  , brown: PropTypes.bool      , bg_brown: PropTypes.bool    
  , coral: PropTypes.bool      , bg_coral: PropTypes.bool    
  , fuchsia: PropTypes.bool      , bg_fuchsia: PropTypes.bool    
  , gold: PropTypes.bool      , bg_gold: PropTypes.bool    
  , indigo: PropTypes.bool      , bg_indigo: PropTypes.bool    
  , khaki: PropTypes.bool      , bg_khaki: PropTypes.bool    
  , orange: PropTypes.bool      , bg_orange: PropTypes.bool    
  , yellow: PropTypes.bool      , bg_yellow: PropTypes.bool    
  , black: PropTypes.bool      , bg_black: PropTypes.bool    
   
  , accent: PropTypes.bool    , bg_accent: PropTypes.bool     
  , accent2: PropTypes.bool   , bg_accent2: PropTypes.bool     
  , positive: PropTypes.bool   , bg_positive: PropTypes.bool
  , negative: PropTypes.bool   , bg_negative: PropTypes.bool

  , c_ddd: PropTypes.bool   , bg_ddd: PropTypes.bool
  , c_eee: PropTypes.bool   , bg_eee: PropTypes.bool
  , c_555: PropTypes.bool   , bg_555: PropTypes.bool
  , c_999: PropTypes.bool   , bg_999: PropTypes.bool
}

export const propTypesFlex = {
    row: PropTypes.bool
  , reverse: PropTypes.bool

  , jc_fx_start: PropTypes.bool
  , jc_fx_end: PropTypes.bool
  , jc_cen: PropTypes.bool
  , jc_spc_betw: PropTypes.bool
  , jc_spc_around: PropTypes.bool
  , jc_spc_even: PropTypes.bool

  , all_cen: PropTypes.bool

  , ai_fx_start: PropTypes.bool
  , ai_fx_end: PropTypes.bool
  , ai_cen: PropTypes.bool
  , ai_stretch: PropTypes.bool
  , ai_baseline: PropTypes.bool

}
export const propTypesShared = {
    children: PropTypes.any
  // , style: ViewPropTypes.style    
  , style: PropTypes.any    
  , gone: PropTypes.bool, absolute: PropTypes.bool
  , fill_100: PropTypes.bool
  , w: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), w_100: PropTypes.bool
  , h: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), h_100: PropTypes.bool
  , wh: PropTypes.any
  , opacity: PropTypes.number, zIndex: PropTypes.number
  , ma: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maTop: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maEnd: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maBot: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maV: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), maH: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
  , pad: PropTypes.number, padTop: PropTypes.number, padStart: PropTypes.number, padEnd: PropTypes.number, padBot: PropTypes.number
  , padV: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), padH: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])   
  , grow: PropTypes.number  
  , flex: PropTypes.number  

  , as_fx_start: PropTypes.bool
  , as_fx_end: PropTypes.bool
  , as_cen: PropTypes.bool
  , as_stretch: PropTypes.bool
  , as_baseline: PropTypes.bool

  , viewRef: PropTypes.any
  , viewOnLayOut: PropTypes.any

  , posFixed: PropTypes.bool
}

const MView = (props) => {

    const pr = props;
    if (pr.gone) return null;

  

    const _render = () => {
  
        const cc = findColor(pr);
        const bg = findColorBG(pr);
        const jc = findJustifyContent(pr);
        const ai = findAlignItems(pr);
        const as = findAlignSelf(pr);
        const shadow = findShadow(pr);
  
    
        return (
          <View { ...pr } 
          
          ref={c => { pr.viewRef?.(c) }}
          onLayout={e => { pr.viewOnLayOut?.(e) }}
  
          
            pointerEvents={pr.clickThrough === true ? 'box-none' : undefined}
            style={[ (pr.style ?? {})
                    
                  , { 
                        color: cc, backgroundColor: bg
                      , flexDirection: pr.row ? ('row' + (pr.reverse ? '-reverse' : '')) : pr.col ? ('column' + (pr.reverse ? '-reverse' : '')) : undefined
                      , justifyContent: jc
                      , alignItems: ai, alignSelf: as
                      , paddingHorizontal: pr.padX, borderRadius: pr.borRad
                      //, marginTop: pr.maTop, marginStart: pr.maStart, marginEnd: pr.maEnd, marginBottom: pr.maBot, marginVertical: pr.maV, marginHorizontal: pr.maH
                      //, paddingTop: pr.padTop, paddingStart: pr.padStart, paddingEnd: pr.padEnd, paddingBottom: pr.padBot, paddingVertical: pr.padV
                      , width: pr.fill_100 || pr.w_100 ? '100%' : pr.wh ? pr.wh : pr.w
                      , height: pr.fill_100 || pr.h_100 ? '100%' : pr.wh ? pr.wh : pr.h
                      , opacity: pr.opacity ?? pr.style?.opacity
                      , flexGrow: pr.grow ?? pr.style?.flexGrow, flex: pr.flex ?? pr.style?.flex
                      , position: pr.posFixed ? 'fixed' : pr.absolute ? 'absolute' : pr.style?.position
                      , zIndex: pr.zIndex ?? pr.style?.zIndex
                    }
                  , setMargin(pr)
                  , setPadding(pr)
                  , shadow
              ]}
            >
            {pr.children}
          </View>
        );
      };


      if (pr.shadowBot) {
        return (
        <MView row >
          {_render()}
          {/** *//* <MShadowBot zIndex={pr.zIndex ?? pr.style?.zIndex} /> */}
        </MView>
        );
      }
  
      return _render();
}
export default MView;

MView.propTypes = {
    clickThrough: PropTypes.bool
  , shadowBot: PropTypes.bool
  , borRad: PropTypes.number    
  , shadow: PropTypes.number
  , ...propTypesShared
  , ...propTypesFlex
  , ...propTypesColors
}






export const setMargin = (pr) => {
    return {
        marginStart: pr.ma ?? pr.maStart ?? pr.style?.marginStart
      , marginTop: pr.ma ?? pr.maTop ?? pr.style?.marginTop
      , marginEnd: pr.ma ?? pr.maEnd ?? pr.style?.marginEnd
      , marginBottom: pr.ma ?? pr.maBot ?? pr.style?.marginBottom
      , marginVertical: pr.ma ?? pr.maV ?? pr.style?.marginVertical
      , marginHorizontal: pr.ma ?? pr.maH ?? pr.style?.marginHorizontal
    }
  }
  export const setPadding = (pr) => {
    return {
        paddingStart: pr.pad ?? pr.padStart ?? pr.style?.paddingStart
      , paddingTop: pr.pad ?? pr.padTop ?? pr.style?.paddingTop
      , paddingEnd: pr.pad ?? pr.padEnd ?? pr.style?.paddingEnd
      , paddingBottom: pr.pad ?? pr.padBot ?? pr.style?.paddingBottom
      , paddingVertical: pr.pad ?? pr.padV ?? pr.style?.paddingVertical
      , paddingHorizontal: pr.pad ?? pr.padH ?? pr.style?.paddingHorizontal
    }
  }
  
  export const findShadow = (pr) => {
    // const shadow = !(0 < pr.shadow) ? {} : gst.shadow(pr.shadow).gen;
    // return shadow;

    const level = (pr.shadow ?? 0);

    return { 
      shadowColor: '#000', 
      shadowOffset: { width: 0, height: level * 0.4 }, 
      shadowRadius: level * 2,
      shadowOpacity: 0.1,
      elevation: level };

    // return {};
  }
  export const findAlignItems = (pr) => {
    const ai = undefined;
         if (pr.all_cen) return 'center';
    else if (pr.ai_fx_start) return 'flex-start';
    else if (pr.ai_fx_end) return 'flex-end';
    else if (pr.ai_cen) return 'center';
    else if (pr.ai_stretch) return 'stretch';
    else if (pr.ai_baseline) return 'baseline';
    return ai;
  }
  export const findAlignSelf = (pr) => {
    const as = undefined;
         if (pr.as_fx_start) return 'flex-start';
    else if (pr.as_fx_end) return 'flex-end';
    else if (pr.as_cen) return 'center';
    else if (pr.as_stretch) return 'stretch';
    else if (pr.as_baseline) return 'baseline';
    else if (pr.as_auto) return 'auto';
    return as;
  }
  
  export const findJustifyContent = (pr) => {
    const jc = undefined;
         if (pr.all_cen) return 'center';
    else if (pr.jc_fx_start) return 'flex-start';
    else if (pr.jc_fx_end) return 'flex-end';
    else if (pr.jc_cen) return 'center';
    else if (pr.jc_spc_betw) return 'space-between';
    else if (pr.jc_spc_around) return 'space-around';
    else if (pr.jc_spc_even) return 'space-evenly';
    return jc;
  }
  
  export const findColor = (pr) => {
    let cc = pr.color;
         if (pr.white) { cc = 'white'; }
    else if (pr.pink) { cc = 'pink'; }
    else if (pr.red) { cc = 'red'; }
    else if (pr.teal) { cc = 'teal'; }
    
    else if (pr.aqua) { cc = 'aqua'; }
    else if (pr.blue) { cc = 'blue'; }
    else if (pr.brown) { cc = 'brown'; }
    else if (pr.coral) { cc = 'coral'; }
    else if (pr.fuchsia) { cc = 'fuchsia'; }
    else if (pr.gold) { cc = 'gold'; }
    else if (pr.indigo) { cc = 'indigo'; }
    else if (pr.khaki) { cc = 'khaki'; }
    else if (pr.orange) { cc = 'orange'; }
    else if (pr.yellow) { cc = 'yellow'; }
    else if (pr.black) { cc = 'black'; }
  
    
    else if (pr.accent) { cc = accent; }
    else if (pr.accent2) { cc = accent2; }
    else if (pr.positive) { cc = gst.POSITIVE_COLOR; }
    else if (pr.negative) { cc = gst.NEGATIVE_COLOR; }
  
    else if (pr.c_ddd) { cc = '#ddd'; }
    else if (pr.c_eee) { cc = '#eee'; }
    else if (pr.c_555) { cc = '#555'; }
    else if (pr.c_999) { cc = '#999'; }
  
    return cc;
  }
  export const findColorBG = (pr) => {
    let cc = pr.bg;
         if (pr.bg_white) { cc = 'white'; }
    else if (pr.bg_pink) { cc = 'pink'; }
    else if (pr.bg_red) { cc = 'red'; }
    else if (pr.bg_teal) { cc = 'teal'; }
    
    else if (pr.bg_aqua) { cc = 'aqua'; }
    else if (pr.bg_blue) { cc = 'blue'; }
    else if (pr.bg_brown) { cc = 'brown'; }
    else if (pr.bg_coral) { cc = 'coral'; }
    else if (pr.bg_fuchsia) { cc = 'fuchsia'; }
    else if (pr.bg_gold) { cc = 'gold'; }
    else if (pr.bg_indigo) { cc = 'indigo'; }
    else if (pr.bg_khaki) { cc = 'khaki'; }
    else if (pr.bg_orange) { cc = 'orange'; }
    else if (pr.bg_yellow) { cc = 'yellow'; }
    else if (pr.bg_black) { cc = 'black'; }
  
    else if (pr.bg_accent) { cc = accent; }
    else if (pr.bg_accent2) { cc = accent2; }
    else if (pr.bg_positive) { cc = gst.POSITIVE_COLOR; }
    else if (pr.bg_negative) { cc = gst.NEGATIVE_COLOR; }
  
    else if (pr.bg_ddd) { cc = '#ddd'; }
    else if (pr.bg_eee) { cc = '#eee'; }
    else if (pr.bg_555) { cc = '#555'; }
    else if (pr.bg_999) { cc = '#999'; }
  
    return cc;
  }
