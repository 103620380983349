import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import { PERSON_ICON } from '../consts/gst';
import glob from '../glob';
import ImageLoader from '../MComponents/ImageLoader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MIcons from '../MComponents/MIcons';
import MText from '../MComponents/MText';
import MTopBar from '../MComponents/MTopBar';
import MTouchable from '../MComponents/MTouchable';
import MView from '../MComponents/MView';
import RangePicker, { findTit, RANGE_MODE } from '../MComponents/RangePicker';
import { Actions, store } from '../store';
import T from '../T';
import ReportScreen_PersonView from './ReportScreen_PersonView';
import { getHISTORY } from '../consts/HISTORY';
import { ReportScreenItem_CC_Portrait } from './ReportScreenItem_CC_Portrait';
import { HorRadio } from '../MComponents/HorRadio';
import { ReportScreenItem_BL_Portrait } from './ReportScreenItem_BL_Portrait';
import { ReportScreenItem_BL_Landscape } from './ReportScreenItem_BL_Landscape';

const init = async (state, dispatch, setPolicies, setPersons, setShowSinglePersonReportObj, setTabIdx) => {
    
    let policies = state?.policies;
    if (!(0 < state?.policies?.length)) {
        policies = await tkAPICalls.getPolicies(state.shop, dispatch);
    }
    setPolicies(policies);
    let persons = state?.personnels;
    if (!(0 < state?.personnels?.length)) {
        persons = await tkAPICalls.getPersonnels(state.shop, dispatch);
    }
    setPersons(persons);

    const _pars = window.location.search;
    setTabIdx(_pars.includes(REPORT_SCREEN_CHAM_CONG) ? 0 : _pars.includes(REPORT_SCREEN_BANG_LUONG) ? 1 : -1);

    try {
        /***/
        // const editingStr = await T.loadObjectString(KEY_OPENING_PERSON_REP);
        // const curSinglePersonReport = editingStr ? JSON.parse(editingStr) : null;
        // if (curSinglePersonReport) {
        //     setShowSinglePersonReportObj(curSinglePersonReport); 
        //     window.scrollTo(0, 0);
        //     if (!window.location.search.includes('&show=SINGLE')) {
        //         history.pushState('', '', window.location.search + '&show=SINGLE');                
        //     }
        // }
    }
    catch (e) {}
}

const getReport = async (state, dispatch, policies, persons, range, date, setOverviews, setShowRangePicker) => {
    dispatch({ type: Actions.SET_BUSY, busy: true });    

    const reports = await tkAPICalls.getReport(dispatch, state.shop, state.shop.openTime
            , -1, range, date, policies, persons);
    setOverviews(reports);

    dispatch({ type: Actions.SET_BUSY, busy: false });
    setShowRangePicker(false); window.scrollTo(0, 0);
}


const showPerson = (setShowSinglePersonReportObj, personReport, rangeMode, rangeDate) => {
    personReport.rangeMode = rangeMode;
    personReport.rangeDate = rangeDate;
    AsyncStorage.setItem(KEY_OPENING_PERSON_REP, JSON.stringify(personReport));
    setShowSinglePersonReportObj(personReport); 
    window.scrollTo(0, 0);
    history.pushState('', '', window.location.search + '&show=SINGLE');
}
const closePerson = (state, dispatch, setShowSinglePersonReportObj) => {
    AsyncStorage.removeItem(KEY_OPENING_PERSON_REP);
    setShowSinglePersonReportObj(null); 
    window.scrollTo(0, 0);
    history.replaceState('', '', T.replaceAll(window.location.search, '&show=SINGLE', ''));
}

const onConfirmPickedRange = (setShowRangePicker) => {
    setShowRangePicker(false); window.scrollTo(0, 0);
}

export const KEY_OPENING_PERSON_REP = 'KEY_OPENING_PERSON_REP';
export const REPORT_SCREEN_CHAM_CONG = 'report-cham-cong';
export const REPORT_SCREEN_BANG_LUONG = 'report-bang-luong';
const REPORT_SCREEN_CHAM_CONG_TIT = 'Báo cáo chấm công';
const REPORT_SCREEN_BANG_LUONG_TIT = 'Bảng lương';
const ReportScreen = (props) => { 

    const { state, dispatch } = useContext(store);

    const [tabIdx, setTabIdx] = useState(0);
    const [policies, setPolicies] = useState([]);
    const [persons, setPersons] = useState([]);

    const [overviews, setOverviews] = useState([]);
    const _noData = !(0 < overviews?.length)
    
    const [showSinglePersonReportObj, setShowSinglePersonReportObj] = useState(false);

    const [showRangePicker, setShowRangePicker] = useState(_noData /* || !__DEV__ */ );
    const [cur_RANGE_MODE, set_cur_RANGE_MODE] = useState(RANGE_MODE.NONE);
    const [cur_RANGE_tit, set_cur_RANGE_tit] = useState('');
    const [cur_RANGE_date, set_cur_RANGE_date] = useState(T.getYesterday());


    useEffect(() => { init(state, dispatch, setPolicies, setPersons, setShowSinglePersonReportObj, setTabIdx); }
    , []);

    const popstateHandler = () => {
        const _pars = window.location.search;
        if (!_pars.includes('&show=SINGLE')) {
            closePerson(state, dispatch, setShowSinglePersonReportObj);
        }
        setTabIdx(_pars.includes(REPORT_SCREEN_CHAM_CONG) ? 0 : _pars.includes(REPORT_SCREEN_CHAM_CONG) ? 1 : -1);
    };
    useEffect(() => {
        window.addEventListener('popstate', popstateHandler);
        return () => { window.removeEventListener('popstate', popstateHandler); };
    }, []);

    const pullData = (mode, date) => {
        set_cur_RANGE_MODE(mode); set_cur_RANGE_tit(findTit(mode, date)); set_cur_RANGE_date(date);
        getReport(state, dispatch, policies, persons, mode, date, setOverviews, setShowRangePicker);
    }
    const refreshData = () => {
        getReport(state, dispatch, policies, persons, cur_RANGE_MODE, cur_RANGE_date, setOverviews, setShowRangePicker);
    }

    const totalSalary = Number(overviews?.map(p => p.overview?._earning ?? 0)
        ?.reduce((a, b) => a + b, 0)
        ?.toFixed(0)
    ).toLocaleString()  + 'đ';

    const switchTab = (tidx) => {
        setTabIdx(tidx);
        history.replaceState('', '', T.replaceAll(window.location.search
            , tidx === 0 ? REPORT_SCREEN_BANG_LUONG : tidx === 1 ? REPORT_SCREEN_CHAM_CONG : 'error'
            , tidx === 0 ? REPORT_SCREEN_CHAM_CONG : tidx === 1 ? REPORT_SCREEN_BANG_LUONG : 'error'
            ));
    }

    const _pars = window.location.search;
    const showCC = _pars.includes(REPORT_SCREEN_CHAM_CONG);
    const showBL = _pars.includes(REPORT_SCREEN_BANG_LUONG);
    document.title = showCC ? REPORT_SCREEN_CHAM_CONG_TIT : showBL ? REPORT_SCREEN_BANG_LUONG_TIT : '?';
    const _upperTitle = document.title;

    let keyi = 0;
    return (
        <MView fill_100 bg='white' >

          { showRangePicker || showSinglePersonReportObj ? null : 
            <MTopBar zx={props.zx} pa={'ReportScreen'} title={state.shop?.shortAddress} backTo={getHISTORY().ME_AHA_REPORT} />}
          
          {/* CONTENT */}
          <MView grow={1} ai_cen padH={15} >

            <HorRadio maV={22} tint='#2EBB9C'
                curIdx={tabIdx} setCurIdx={switchTab} >
                {[  { tit: REPORT_SCREEN_CHAM_CONG_TIT }, { tit: REPORT_SCREEN_BANG_LUONG_TIT } ]}
            </HorRadio>

            <MView row w_100 ai_cen jc_spc_betw maTop={15} >
                <MText tind c_999 txt={_upperTitle} />
                { /* FILTER */
                <MTouchable maStart={5} row ai_cen
                    onPress={() => { setShowRangePicker(true); window.scrollTo(0, 0); }} >
                    <MIcons fasolid name='calendar' maEnd={11} bg_teal c_555 size={14} />
                    <MText nd bold c_555 txt={cur_RANGE_tit} />
                </MTouchable> }
            </MView>

            <MView gone={!_noData} fill_100 all_cen padH='20%' >
                <MText ta_cen tito c_999 txt='Không có dữ liệu cho khoảng thời gian này' />
            </MView>

            { /* OVERVIEWS */ }
            <MView gone={!showBL || _noData || !totalSalary} maTop={11} padV={11} row w_100 jc_spc_betw >
                <MText vert_cen tind ta_cen c_999 txt={'Tổng chi phí: '} />
                <MText vert_cen tind ta_cen accent txt={'' + (totalSalary)} />
            </MView> 

            { !showCC || _noData || showSinglePersonReportObj || showRangePicker ? null :
            <FlatList style={{ width: '100%', height: '100%' }}
                scrollEnabled={true} data={overviews} keyExtractor={(item, index) => { return keyi++ + ''; }} 
                ListFooterComponent={() => <MView h={99} />}
                numColumns={1}
                renderItem={({ item, index }) => 
                    <ReportScreenItem_CC_Portrait data={item} 
                        onSelect={perRep => showPerson(setShowSinglePersonReportObj, perRep, cur_RANGE_MODE, cur_RANGE_date)} 
                    /> } 
            />
            }
            { !showBL || _noData || showSinglePersonReportObj || showRangePicker ? null :
            <FlatList style={{ width: '100%', height: '100%' }}
                data={[ { isTitle: true }, ...overviews]} scrollEnabled={true} keyExtractor={(item, index) => { return keyi++ + ''; }} 
                ListFooterComponent={() => <MView h={99} />}
                numColumns={1}
                renderItem={({ item, index }) => 
                    <ReportScreenItem_BL_Landscape data={item} 
                        onSelect={perRep => showPerson(setShowSinglePersonReportObj, perRep, cur_RANGE_MODE, cur_RANGE_date)} 
                    /> } 
            />
            }

          </MView>

          { !showRangePicker ? null :
            <RangePicker
                zx={props.zx + 1} 
                closable={!_noData} upperTitle={_upperTitle}
                mode={cur_RANGE_MODE} date={cur_RANGE_date}
                close={() => { setShowRangePicker(false); window.scrollTo(0, 0); }}
                onConfirm={pullData}
                />
          }


          { !showSinglePersonReportObj || (showRangePicker) ? null :
            <ReportScreen_PersonView 
                zx={props.zx + 1} 
                initMode={cur_RANGE_MODE} initModeTit={cur_RANGE_tit} initDate={cur_RANGE_date}
                policies={policies} persons={persons}
                personReport={showSinglePersonReportObj} 
                onClose={() => closePerson(state, dispatch, setShowSinglePersonReportObj)} 
                onRefresh={refreshData}
                />
          }


        </MView>
    );
};
export default ReportScreen;
ReportScreen.propTypes = {
// zx: PropTypes.number,
};


