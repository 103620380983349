
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, ViewPropTypes, TouchableWithoutFeedback } from 'react-native';
import PropTypes from 'prop-types';
import MView, { findAlignItems, findAlignSelf, findColor, findColorBG, findJustifyContent, findShadow, setMargin, setPadding } from './MView';
import MText from './MText';
import T from '../T';
import { SafeAreaView } from 'react-native-safe-area-context';
import glob from '../glob';
import MTouchable from './MTouchable';
import { NEGATIVE_COLOR, NEUTRAL_COLOR, POSITIVE_COLOR } from '../consts/gst';



const MPrompt = (props) => {

  const [shw, setShw] = useState(false);
  const [prompt, setPrompt] = useState(null);

  const loopWorkload = async () => {
    //console.log('loopWorkload', new Date());
    if (!!glob.curPrompt && glob.curPrompt != null) {
      const _prompt = { ...glob.curPrompt }; glob.curPrompt = null;
      setPrompt(_prompt); setShw(true);
    }
    else {
      await T.delay(1000);
    }
  }

  const [doLoopTime, setDoLoopTime] = useState(0);
  useEffect(() => {     
    const loop = async () => { await loopWorkload(); setDoLoopTime(+new Date()); };
    loop(); }
  , [doLoopTime]);

  const _render = () => {

    const _pro = prompt;

    const _show = shw;
    const titCol = !_show ? null : _pro.mood > 0 ? POSITIVE_COLOR : _pro.mood < 0 ? NEGATIVE_COLOR : NEUTRAL_COLOR;
    const okCol = !_show || !_pro?.okButt ? null : _pro?.okButt?.mood > 0 ? POSITIVE_COLOR : _pro?.okButt?.mood < 0 ? NEGATIVE_COLOR : titCol;
    
    if (!_show) { return null; }
    
    const ww = Math.min(glob.ww, glob.hh); const padX = ww * 0.04;
    
    return (
      <TouchableWithoutFeedback onPress={() => setShw(false)} >
        <MView fill_100 bg='#00000055' absolute all_cen  >
          <MView bg_white borRad={5} all_cen padV={ww * 0.03} padH={ww * 0.03}
              style={{ minHeight: ww * 0.1, minWidth: 256 }} >
            <MText tind color={titCol} txt={_pro.tit} />
            <MView h={ww * 0.02} />
            <MText nd c_555 txt={_pro.msg} />
            <MView h={ww * 0.06} />
            
            <MTouchable all_cen w={256 - padX * 2} h={44} bg={okCol} borRad={5} textRevertOrder
              text={{ tind: true, white: true, txt: _pro?.okButt?.tit ?? 'OK' }}
              onPress={() => { _pro?.okButt?.onPress?.(); setShw(false); }} >
                <MText gone={!_pro?.okButt?.note} note white txt={_pro?.okButt?.note} />
            </MTouchable>

            { _pro?.extra?.map((p, k) => {
                const col = p?.mood > 0 ? POSITIVE_COLOR : p?.mood < 0 ? NEGATIVE_COLOR : NEUTRAL_COLOR;
                return (
                  <MTouchable key={k} all_cen w={256 - padX * 2} h={44} bg={col} borRad={5} textRevertOrder
                    maTop={padX * 0.5}
                    text={{ tind: true, white: true, txt: p?.tit ?? 'OK' }}
                    onPress={() => { p?.onPress?.(); setShw(false); }} >
                      <MText gone={!p?.note} note white txt={p?.note} />
                  </MTouchable>
                );
            })}

          </MView>
        </MView>
      </TouchableWithoutFeedback>
    );
  };

  return _render();
}
export default MPrompt;