
import React from 'react';
import { TextPropTypes, TouchableOpacity, View, ViewPropTypes, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import MView, { findAlignItems, findAlignSelf, findColor, findColorBG, findJustifyContent, findShadow, propTypesColors, propTypesShared, setMargin, setPadding } from './MView';
import T from '../T';
import { FontAwesome, MaterialIcons, AntDesign, Feather, FontAwesome5
    , Ionicons, Entypo, MaterialCommunityIcons, Octicons } from '@expo/vector-icons';
import { accent2 } from '../consts/gst';



const MIcons = (props) => {

    const pr = props;

    if (pr.gone === true) {
      return null;
    }

    const _color = pr.color ?? accent2;
    const _name = pr.name;
    const _size = pr.size ?? 24;
    const fasolid = pr.fasolid;
    const cc = findColor(pr);

    const _style = [ (pr.style ?? {}) , (pr.txtStyle ?? {})
      , setMargin(pr) 
      , setPadding(pr) 
      , { opacity: pr.opacity ?? pr.style?.opacity }
    ];

    const _render = () => {
        // https://icons.expo.fyi/
        return (
              pr.ant 
            ? <AntDesign name={_name} size={_size} color={cc} style={_style} />
            : pr.feather 
            ? <Feather name={_name} size={_size} color={cc} style={_style} />
            : pr.octi 
            ? <Octicons name={_name} size={_size} color={cc} style={_style} />
            : pr.mat 
            ? <MaterialIcons name={_name} size={_size} color={cc} style={_style} />
            : pr.matCom
            ? <MaterialCommunityIcons name={_name} size={_size} color={cc} style={_style} />
            : pr.ent
            ? <Entypo name={_name} size={_size} color={cc} solid={fasolid} style={_style} />
            : pr.ion
            ? <Ionicons name={_name} size={_size} color={cc} solid={fasolid} style={_style} />
            : pr.fa1
            ? <FontAwesome name={_name} size={_size} color={cc} solid={fasolid} style={_style} />
            // default
            : <FontAwesome5 name={_name} size={_size} color={cc} solid={fasolid} style={_style} />
        );
      };
  
      return _render();
}
export default MIcons;

MIcons.propTypes = {
    ...propTypesColors
  , ent: PropTypes.bool
  , ant: PropTypes.bool
  , fa1: PropTypes.bool
  , ion: PropTypes.bool
  , feather: PropTypes.bool
  , octi: PropTypes.bool
  , mat: PropTypes.bool
  , matCom: PropTypes.bool
  , color: PropTypes.string
  , name: PropTypes.string
  , size: PropTypes.number
  , fasolid: PropTypes.bool
//   , txtStyle: { ...TextPropTypes?.style ?? {} }
  , ...propTypesShared
}


