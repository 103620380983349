
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import MView from '../MComponents/MView';
import MText from '../MComponents/MText';
// import { store } from 'code/store';
import MTopBar from '../MComponents/MTopBar';
import { Actions, store } from '../store';
import { FlatGrid } from 'react-native-super-grid';
import tkAPICalls, { tkBioPhotoUrl } from '../APICalls/tkAPICalls';
import MIcons from '../MComponents/MIcons';
import MTouchable from '../MComponents/MTouchable';
// import Menu, { MenuDivider, MenuItem } from 'react-native-material-menu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EMPLOYMENT_PERIODS_SCREEN } from './EmploymentPeriodsScreen';
import { openPage } from '../../App';
import ImageLoader from '../MComponents/ImageLoader';
import { getHISTORY } from '../consts/HISTORY';
import T from '../T';
import LeavesScreen_Editor from './LeavesScreen_Editor';
import { PERSON_ICON } from '../consts/gst';


const getInitData = async (state, dispatch, setCurLeave, setShowEditor) => {
  dispatch({ type: Actions.SET_BUSY, busy: true });
  const leaves = await tkAPICalls.getLeaves(state.shop, dispatch);
  try {
    const editingStr = await T.loadObjectString(KEY_DEV_OPEN_LEAVE);
    const curLeave = editingStr ? JSON.parse(editingStr) : null;
    if (curLeave) {
      setCurLeave(curLeave);
      setShowEditor(true); window.scrollTo(0, 0);
    }
  }
  catch (e) {}
  dispatch({ type: Actions.SET_BUSY, busy: false });
}


const selectCreatePeriod = async (state, curLeave) => {
  await AsyncStorage.setItem(KEY_PASSING_LEAVE, JSON.stringify(curLeave));
  openPage(EMPLOYMENT_PERIODS_SCREEN);
}


const selectEdit = (setShowEditor, /* hideMenu, */ curLeave, setCurLeave) => {
  // hideMenu();
  setShowEditor(true); window.scrollTo(0, 0);
  setCurLeave(curLeave);
  if (curLeave) {
    AsyncStorage.setItem(KEY_DEV_OPEN_LEAVE, JSON.stringify(curLeave));
  }
  else {
    AsyncStorage.removeItem(KEY_DEV_OPEN_LEAVE);
  }
}


const closeEditor = (state, dispatch, saved, setCurLeave, setShowEditor) => {
  setCurLeave(null);  setShowEditor(false); window.scrollTo(0, 0);
  AsyncStorage.removeItem(KEY_DEV_OPEN_LEAVE);
  if (saved) {
    getInitData(state, dispatch, setCurLeave, setShowEditor);
  }
}

const KEY_DEV_OPEN_LEAVE = 'KEY_DEV_OPEN_LEAVE';
export const KEY_PASSING_LEAVE = 'KEY_PASSING_LEAVE';
const TITLE = 'Các xin phép';
export const LEAVES_SCREEN = 'leaves';
const LeavesScreen = (props) => {
    document.title = TITLE;
    const { state, dispatch } = useContext(store);
    // const [doShowMenu, setDoShowMenu] = useState(null);
    // const [coord, setCoord] = useState(null);
    const [curLeave, setCurLeave] = useState(null);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => { getInitData(state, dispatch, setCurLeave, setShowEditor); }
    , []);

    // let _menu;
    // const hideMenu = () => { _menu?.hide(); };
    const showMenu = (e, p) => { 
      // setDoShowMenu(true);
      // setCoord({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
      setCurLeave(p);
      selectEdit(setShowEditor, /* hideMenu, */ p, setCurLeave)
    };

    return (
      <MView fill_100 bg='#f5f7fa' >
        { showEditor ? null : <MTopBar zx={props.zx} pa={'LeavesScreen'} title={state.shop?.shortAddress + ''} backTo={getHISTORY().ME_AHA_OPERATION} />}

        <MView all_cen w_100 pad={22} >
          <MText tito c_999 txt='Các xin phép' />
        </MView>

        <MTouchable w={170} h={40} ma={10} bg='#ff902b' borRad={4} row all_cen 
          text={{ nd: true, bold: true, maStart: 10, white: true, txt: 'Tạo xin phép mới' }} 
          onPress={() => selectEdit(setShowEditor, /* hideMenu, */ { address: '' }, setCurLeave)} >
          <MIcons ent white name="plus" size={19} />
        </MTouchable>

        {/* GRID */
          showEditor ? null :
          <FlatGrid
            itemDimension={240}
            data={state.leaves ?? []}
            renderItem={({ item }) => <LeaveItem data={item} 
                                        onSelect={(e, p) => { showMenu(e, p); }} 
                                        onEditItem={p => selectEdit(setShowEditor, /* hideMenu, */ p, setCurLeave)}
                                        /> }
            />
        }
        
        { /* EDITOR */
          !showEditor ? null :
          <LeavesScreen_Editor  curLeave={curLeave} 
            zx={props.zx + 1} 
            onCloseEditor={saved => closeEditor(state, dispatch, saved, setCurLeave, setShowEditor)} 
            onSelectCreatePeriod={() => selectCreatePeriod(state, curLeave)} 
            />
        }

        {/* <Time_Picker value={null} onChange={a => { }} /> */}

      </MView>
    );
}
export default LeavesScreen;
LeavesScreen.propTypes = {
  zx: PropTypes.number,
};





export const LeaveItem = (props) => {
  const pr = props;
  const data = pr.data;
  const locked = pr?.locked;

  const _img = 0 < data?.id ? tkBioPhotoUrl(data?.personnel_id) : null;

  const _leaveType = data?.leaveType === 0 ? 'Xin đến muộn' : data?.leaveType === 1 ? 'Xin nghỉ' : '?';

  return (
    <MTouchable bg_white={!locked} borRad={5} row pad={10} w={pr.width} style={locked ? { borderWidth: 1, borderColor: '#e4e9f2' } : null}
      disabled={locked}
      onPress={(e) => { pr.onSelect?.(e, data) }} >
      {/* <Image source={{ uri: _img }} style={{ width: 64, height: 64, borderRadius: 32, marginEnd: 10 }} /> */}

      { !_img ? null :
        <ImageLoader source={_img} fallback={PERSON_ICON}
          style={{ width: 64, height: 64, borderRadius: 32, marginEnd: 10 }} />
      }
      
      <MView>
        <MText c_555 bold nd txt={data?.personnel?.name} />
        <MText nd teal txt={T.formatDDMMYY(data?.date)} maV={7} />
        <MText nd c_555 txt={_leaveType} />
      </MView>

      <MView gone={locked} absolute w_100 row reverse padEnd={20} >
        <MTouchable onPress={() => pr.onEditItem?.(data)} >
          { !pr.customCornerIcon
            ? <MIcons mat name='edit' c_999 size={16} />
            : pr.customCornerIcon()
          }
        </MTouchable>
      </MView>
    </MTouchable>
  );
}

LeaveItem.propTypes = {
  data: PropTypes.any,
  width: PropTypes.number,
  onSelect: PropTypes.func,
  onEditItem: PropTypes.func,
  customCornerIcon: PropTypes.func,
  locked: PropTypes.bool,
};



